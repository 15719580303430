import "../../App.css";
import { Accordion, Button } from "react-bootstrap";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import StarRateIcon from "@mui/icons-material/StarRate";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import LockIcon from "@mui/icons-material/Lock";
import UndoIcon from "@mui/icons-material/Undo";
import DownloadIcon from "@mui/icons-material/Download";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import CallIcon from "@mui/icons-material/Call";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Link, useNavigate } from "react-router-dom";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import StoreIcon from "@mui/icons-material/Store";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Box, Modal, Rating } from "@mui/material";
import { useFormik } from "formik";
import axios from "axios";
import aaralogo from '../../assets/aaralogo.webp'
import heroImage from '../../assets/Group-12686.webp'
import bubble from '../../assets/headerbubble.webp'
import gtag_report_conversion from "../../GoogleConversionFn";
import { CleaningServices } from "@mui/icons-material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Loader } from "../../Loader";
import { toast } from "react-toastify";
function Home(props) {
  const [feedbacks, setFeedbacks] = useState([]);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const fetchFeedback = () => axios.get("https://m1blog.aaragroups.com/client-feedback-api/", {
    headers: {
      "Authorization": "Token 3fed23c54f613d0f37284d33bbfb2958960f1063",
    }
  }).then(({ data }) => setFeedbacks(data?.data))

  useEffect(() => {
    fetchFeedback()
  }, [])

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "0.5px solid #fff",
    rounded: 5,
    boxShadow: 24,
    p: 4,
    backgroundColor: "rgb(255, 255, 255, 0.5)",
  };

  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [data, setData] = useState(false);
  const [faq, setfaq] = useState();
  const [isPending, setIsPending] = useState(false);

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      mobile_no: "",
      customer_message: "",
      customer_type: "",
    },

    onSubmit: (values, action) => {
      const reqBody = new FormData();
      reqBody.append("first_name", values.first_name);
      reqBody.append("last_name", values.last_name);
      reqBody.append("email", values.email);
      reqBody.append("mobile_no", values.mobile_no);
      reqBody.append("customer_message", values.customer_message);
      reqBody.append("customer_type", "ONLINE/AARATECH");
      setIsPending(true);
      axios
        .post("https://erpstaging.aaragroups.com/Api_data/add-contact-us/", reqBody, {
          headers: {
            authorization: "81d3d4a52e08936ad57d4c0fe1aa800543c04022",
          },
        })
        .then((res) => {
          if (res.data.response_code === 200) {
            setData(res.data.message);
            alert(res.data.message);
            gtag_report_conversion("https://aaratechnologies.com/");
            window.location.href = `https://crmapp.bhaaraterp.com/whatsapp?template=contact_form&name=${values.first_name + " " + values.last_name
              }&mobile=${values.mobile_no}&email=${values.email
              }&callback_url=https://aaratechnologies.com/&access_token=b8bb5f83ea8a4ab4886394dbd00dbd97`;
            action.resetForm();
          }
        })
        .catch((err) => {
          console.log("ERROR: ====", err);
          alert(err.response.data.message);
        })
        .finally(() => {
          setIsPending(false);
        })

      action.resetForm();
    },
  });



  const getCreer = () => {
    axios
      .get(`https://m1blog.aaragroups.com/blog/faq-api/`, {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        setfaq(res.data?.data || []);

      })
      .catch((err) => {
        toast.error("Error fetching FAQs", err?.response?.data?.message || "An error occurred");
      });
  };

  useEffect(() => {
    getCreer();
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>{props.title}</title>
        <meta name="description" content={props.description} />
        <meta name="keywords" content={props.keywords} />
      </Helmet>

      <div className="relative home1">
        <header className="style-4" data-scroll-index="0">
          <div className="content mb-[50px]">
            <div className="container">
              <div className="row gx-0 mb-70">
                <div className="col-lg-6">
                  <div className="info">
                    <small className="mb-50 title_small">
                      Aara technologies
                    </small>
                    <h1 className="mb-30">
                      <span>Aara Innovative Solutions</span>
                    </h1>
                    <p className="text">
                      <mark>
                        <strong>
                          Build B2B, B2C, D2C online marketplace stores,
                          Software, CRM, ERP, AI, Digital Transformation, Cloud
                          Operation & mobile apps
                        </strong>
                      </mark>
                    </p>
                    <p>
                      <strong>
                        Trusted IT consulting and Software Development Company
                      </strong>
                      .
                    </p>
                    <div className="d-flex align-items-center mt-50">
                      <Link
                        to="https://api.whatsapp.com/send?phone=+919266667999"
                        // onClick={opensec}
                        // rel="noreferrer"
                        className="text-white btn rounded-pill bg-blue4 fw-bold me-4"
                      >
                        <small>
                          <WhatsAppIcon /> | WhatsApp Now
                        </small>
                      </Link>
                      <span className="play-btn">
                        <span onClick={handleOpen} className="icon me-2 colorv !cursor-pointer">
                          <small>
                            <CallIcon /> | Book a call
                          </small>
                        </span>
                      </span>
                      <Modal
                        open={open}
                        className="popup"
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box
                          id="popup"
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            p: 3,
                            backdropFilter: 'blur(8px)', // Subtle blur
                            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Darker backdrop
                            height: '100vh',
                            width: '100vw'
                          }}
                          onClick={(e) => {
                            if (e.target.id === 'popup') {
                              handleClose();
                            }
                          }}
                        >
                          {isPending && <Loader className={"!z-50"} />}
                          <div className="relative w-full max-w-sm p-6 overflow-hidden bg-white rounded-lg shadow-lg">
                            <div className="absolute top-4 right-4">
                              <LazyLoadImage
                                // src="https://aaratech.s3.ap-south-1.amazonaws.com/aaralogo.png"
                                src={aaralogo}
                                alt="AaraTech Logo"
                                className="h-12"
                              />
                            </div>
                            <form
                              className="space-y-4"
                              onSubmit={formik.handleSubmit}
                              method="post"
                            >
                              <h2 className="mb-4 text-lg font-extrabold text-gray-900">
                                Please Fill Out the Form
                              </h2>
                              <div>
                                <input
                                  className="w-full px-4 py-2 leading-tight text-gray-900 border rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-purple-400"
                                  type="text"
                                  name="first_name"
                                  id="first_name"
                                  placeholder="First Name"
                                  value={formik.values["first_name"]}
                                  onChange={formik.handleChange}
                                />
                              </div>
                              <div>
                                <input
                                  className="w-full px-4 py-2 leading-tight text-gray-900 border rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-purple-400"
                                  type="text"
                                  name="last_name"
                                  id="last_name"
                                  placeholder="Last Name"
                                  value={formik.values["last_name"]}
                                  onChange={formik.handleChange}
                                />
                              </div>
                              <div>
                                <input
                                  className="w-full px-4 py-2 leading-tight text-gray-900 border rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-purple-400"
                                  type="email"
                                  name="email"
                                  id="email"
                                  placeholder="Email"
                                  value={formik.values["email"]}
                                  onChange={formik.handleChange}
                                />
                              </div>
                              <div>
                                <input
                                  className="w-full px-4 py-2 leading-tight text-gray-900 border rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-purple-400"
                                  type="text"
                                  name="mobile_no"
                                  id="mobile_no"
                                  placeholder="Mobile Number"
                                  value={formik.values["mobile_no"]}
                                  onChange={formik.handleChange}
                                />
                              </div>
                              <div>
                                <textarea
                                  className="w-full px-4 py-2 leading-tight text-gray-900 border rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-purple-400"
                                  name="customer_message"
                                  id="customer_message"
                                  placeholder="Message"
                                  value={formik.values["customer_message"]}
                                  onChange={formik.handleChange}
                                  rows="3"
                                />
                              </div>
                              <div className="flex items-center justify-end">
                                <Button
                                  className="!bg-purple-600 text-white font-semibold py-2 px-4 rounded-lg shadow-md hover:bg-purple-700 focus:outline-none focus:ring-2"
                                  type="submit"
                                >
                                  Request Demo
                                </Button>
                              </div>
                            </form>
                          </div>
                        </Box>
                      </Modal>


                    </div>
                    <span className="me-5">
                      <small className="text-uppercase">
                        <ArrowRightIcon />
                        <Link as={Link} to="/mobile-app-development">
                          {" "}
                          Mobile App Development
                        </Link>
                      </small>
                    </span>
                    <span className="mt-8">
                      <ArrowRightIcon />
                      <small className="text-uppercase">
                        <Link as={Link} to="/website-design-and-development">
                          Web development
                        </Link>
                      </small>
                    </span>
                  </div>
                </div>
                <div className="col-lg-6 ">
                  <div className="img">
                    <LazyLoadImage
                      // src="https://aaratech.s3.ap-south-1.amazonaws.com/Group-12686.png"
                      src={heroImage}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              // src="https://aaratech.s3.ap-south-1.amazonaws.com/headerbubble.png"
              src={bubble}
              alt=""
              className="bubble"
              width={"100%"}
              height={"100%"}
              placeholderSrc=""
            />
          </div>
          <LazyLoadImage
            src="https://aaratech.s3.ap-south-1.amazonaws.com/header4wave.png"
            className="wave"
          />
        </header>
        <section className="clients style-4">
          <div className="container">
            <div className="text-center">
              <h5 className="py-4 fw-bold">
                <span className="color-blue4">
                  <mark>25K+ Clients</mark>
                </span>
                <mark>and Featured on</mark>
              </h5>
            </div>
            <div className="client-logos pb-50">
              <div className="row align-items-center">
                <div className="col-6 col-lg-2">
                  <a href="#" className="img d-block">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/logo/rajsoundwave_studio.webp"
                      alt=""
                    />
                  </a>
                </div>
                <div className="col-6 col-lg-2">
                  <a href="#" className="img d-block">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/logo/easybuk.webp"
                      alt=""
                    />
                  </a>
                </div>
                <div className="col-6 col-lg-2">
                  <a href="#" className="img d-block">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/logo/nabeel.webp"
                      alt=""
                    />
                  </a>
                </div>
                <div className="col-6 col-lg-2">
                  <a href="#" className="img d-block">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/logo/taru.webp"
                      alt=""
                    />
                  </a>
                </div>
                <div className="col-6 col-lg-2">
                  <a href="#" className="img d-block">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/logo/momentous.webp"
                      alt=""
                    />
                  </a>
                </div>
                <div className="col-6 col-lg-2">
                  <a href="#" className="img d-block">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/logo/ashirwadum.webp"
                      alt=""
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="features pt-50 pb-50 style-4" data-scroll-index="1">
          <div className="container">
            <div className="text-center section-head style-4">
              <small className="bg-white title_small">Services</small>
              <h2 className="mb-50">
                <span> Service We Provide</span>{" "}
              </h2>
            </div>
            <div className="content">
              <div className="features-card">
                <Link
                  as={Link}
                  to="/software-development"
                  className="icon img-contain"
                >
                  <LazyLoadImage
                    src="https://aaratech.s3.ap-south-1.amazonaws.com/Group+289472.png"
                    alt=""
                  />
                </Link>
                <h6>
                  <Link
                    as={Link}
                    to="/software-development"
                    style={{ fontSize: "19px" }}
                  >
                    Software Development
                  </Link>
                </h6>
              </div>
              <div className="features-card">
                <Link as={Link}
                  to="/mobile-app-development"
                  className="icon img-contain"
                >
                  <LazyLoadImage
                    src="https://aaratech.s3.ap-south-1.amazonaws.com/Group+289473.png"
                    alt=""
                  />
                </Link>
                <h6>
                  <Link
                    as={Link}
                    to="/mobile-app-development"
                    style={{ fontSize: "19px" }}
                  >
                    Mobile Apps Development
                  </Link>
                </h6>
              </div>
              <div className="features-card">
                <Link
                  className="icon img-contain"
                  as={Link}
                  to="/mobile-app-development"
                >
                  <LazyLoadImage
                    src="https://aaratech.s3.ap-south-1.amazonaws.com/Group+289473+(1).png"
                    alt=""
                  />
                  <span className="label icon-40 alert-success text-success rounded-circle small text-uppercase fw-bold">
                    new
                  </span>
                </Link>
                <h6>
                  <Link
                    as={Link}
                    to="/website-design-and-development"
                    style={{ fontSize: "19px" }}
                  >
                    Website Development
                  </Link>
                </h6>
              </div>
              <div className="features-card">
                <Link as={Link}
                  to="/angular-development" className="icon img-contain">
                  <LazyLoadImage
                    src="https://aaratech.s3.ap-south-1.amazonaws.com/Group+289474.png"
                    alt=""
                  />
                </Link>
                <h6>
                  <Link
                    as={Link}
                    to="/angular-development"
                    style={{ fontSize: "19px" }}
                  >
                    PWA Development
                  </Link>
                </h6>
              </div>
              <div className="features-card">
                <Link
                  as={Link}
                  to="/ai-ar-product"
                  className="icon img-contain"
                >
                  <LazyLoadImage
                    src="https://aaratech.s3.ap-south-1.amazonaws.com/Group+289475.png"
                    alt=""
                  />
                </Link>
                <h6>
                  <Link
                    as={Link}
                    to="/ai-ar-product"
                    style={{ fontSize: "19px" }}
                  >
                    AI Development
                  </Link>
                </h6>
              </div>
            </div>
          </div>
          <LazyLoadImage
            src="https://aaratech.s3.ap-south-1.amazonaws.com/feat1.png"
            alt=""
            className="img-circle"
          />
        </section>
        <section className="about section-padding style-4">
          <div className="content frs-content" id="about" data-scroll-index="2">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-6">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/VNU_M562_08.png"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">
                        Our Impressive Portfolio
                      </small>
                      <h2 className="mb-30">
                        Introducing <span> Our Works</span>
                      </h2>
                    </div>
                    <p className="mb-40 text">
                      We are better decisive with creativity because we are
                      backed by data. Our researchers focus with magnifying
                      glasses on insights to position your brand on a successful
                      path. We enable you to initiate conversations in the
                      real-time connected world. Give us a chance to together
                      discover, build and grow your business, digitally. We fuel
                      growth for businesses through innovative digital and
                      technological solutions. Below given Our Technologies
                      where I can work.
                    </p>
                    <ul>
                      <li className="mb-3 d-flex align-items-center">
                        {/* <small className="icon-30 bg-gray rounded-circle color-blue4 d-inline-flex align-items-center justify-content-center me-3">
                        <i className="fas fa-tag"></i>
                      </small> */}
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          <Link
                            as={Link}
                            to="/python-development"
                            className="color-blue4"
                          >
                            Python development
                          </Link>
                        </h6>
                      </li>
                      <li className="mb-3 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          <Link
                            as={Link}
                            to="/woocommerce-development"
                            className="color-blue4"
                          >
                            WooCommerce Devlopment
                          </Link>
                        </h6>
                      </li>
                      <li className="mb-3 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          <Link
                            as={Link}
                            to="opencart-development"
                            className="color-blue4"
                          >
                            Opencart Development
                            and other technologies
                          </Link>
                        </h6>
                      </li>
                    </ul>
                    <a
                      href="#"
                      className="text-white btn rounded-pill bg-blue4 fw-bold mt-50"
                    >
                      <Link as={Link} to="/ourproject" className="text-white">
                        {" "}
                        <small> View Our Work </small>
                      </Link>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_lines.png"
              alt=""
              className="lines"
            />
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt=""
              className="bubble"
            />
          </div>
          <div
            className="content frs-content style-4 testimonials"
            id="about"
            data-scroll-index="2"
          >
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-4">
                  <div className="info">
                    <div
                      className="section-head style-4"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <LazyLoadImage
                        src="https://aaratech.s3.ap-south-1.amazonaws.com/mobile-development-1.svg"
                        style={{ width: "80px" }}
                      />

                      <h3
                        className="text-center mb-30"
                        style={{ color: "#5842bc" }}
                      >
                        App development
                      </h3>
                    </div>
                    <p className="mb-40 text">
                      Our team of software developers can build exceptional
                      applications across a breadth of devices including
                      desktop, mobile and tablet, With the help of our industry
                      expertise, we are able to give the end user the best
                      application experience across multiple channels.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="info">
                    <div
                      className="section-head style-4"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <LazyLoadImage
                        src="https://aaratech.s3.ap-south-1.amazonaws.com/team-work-1.svg"
                        style={{ width: "80px" }}
                      />
                      <h3
                        className="text-center mb-30"
                        style={{ color: "#5842bc" }}
                      >
                        On-Demand Developers
                      </h3>
                    </div>
                    <p className="mb-40 text">
                      We are proud to employ over 2800 in-house developers with
                      a variety of software development experience in an array
                      of industries. This enables us to be a one-stop shop,
                      removing the hassles of managing multiple vendors
                      logistics and technological bottlenecks.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="info">
                    <div
                      className="section-head style-4"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <LazyLoadImage
                        src="https://aaratech.s3.ap-south-1.amazonaws.com/customer-service-1.svg"
                        style={{ width: "80px" }}
                      />
                      <h3
                        className="text-center mb-30"
                        style={{ color: "#5842bc" }}
                      >
                        Product <span> Support</span>
                      </h3>
                    </div>
                    <p className="mb-40 text">
                      We partner with other industry leaders to deploy superior
                      software solutions for our clients. Our certified
                      developers are capable of implementing new software ,
                      seamlessly interating it to your organization as well as
                      providing support for any future upgrades.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="content sec-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="order-2 col-lg-5 order-lg-0">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">
                        We Are Leading Software Company
                      </small>
                      <h2 className="mb-30">
                        We Provide <span> Innovative Apps</span>
                      </h2>
                    </div>
                    <p className="mb-40 text">
                      Our web design & development company leverages large
                      knowledge base to deliver best-in-domain solutions that
                      meet needs, business and budget expectations of our
                      customers. Our experts offer advanced technology solutions
                      that add real-value to their business. It is simple— we
                      understand that our success is measured by the success of
                      our esteemed clients.
                    </p>
                    <div className="mb-20 faq style-3 style-4">
                      <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>
                            Custom API & Design Development
                          </Accordion.Header>
                          <Accordion.Body>
                            Enhance your software with our bespoke API and design solutions tailored to fit your specific needs. Our adaptable design options empower you to manage and streamline tasks with ease and efficiency, ensuring a perfect fit for your unique requirements.
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                          <Accordion.Header>
                            Best in UI/UX
                          </Accordion.Header>
                          <Accordion.Body>
                            Elevate your applications with our exceptional UI/UX designs. We offer custom API integrations that perfectly align with your business goals, extending functionality and delivering a seamless experience tailored to your precise needs.
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                          <Accordion.Header>
                            Expertise in Web & App
                          </Accordion.Header>
                          <Accordion.Body>
                            Discover unparalleled design excellence with our UI/UX expertise. We craft visually stunning and highly intuitive applications, supported by our comprehensive domain and hosting services, including SSL certificates, to ensure your online presence is both secure and robust.
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                    <a
                      href="https://google.com/webstore/category/extensions"
                      rel="noreferrer"
                      className="mt-40 btn btn-img rounded-pill"
                      target="_blank"
                    >
                      <div className="icon img-contain">
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/chrome_icon.png"
                          alt=""
                        />
                      </div>
                      <div className="inf">
                        <small>Available in the</small>
                        <h6> Web Browser</h6>
                      </div>
                    </a>
                  </div>
                </div>
                <div className="col-lg-6 order-0 order-lg-2">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/Group-12682.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png"
              alt=""
              className="bubble2"
            />
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/amico.png"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">Software company</small>
                      <h2 className="mb-30">
                        We Are Leading <span> Software Company</span>{" "}
                      </h2>
                    </div>
                    <p className="mb-4 text">
                      We are best in software development. We help enterprises,
                      agencies, SMEs and startups to design, develop and evolve
                      advance software that supplies their ad-hoc software needs
                      and brings ideas to life Founded in 2016, AARA
                      Technologies is proud to be led by extremely focused and
                      dedicated professionals, who are known for their
                      entrepreneurial skills, experience, and expertise in a
                      wide spectrum of industries.
                    </p>
                    <ul class="space-y-1">
                      <li
                        class="flex items-center cursor-pointer text-black hover:text-purple-600 transition-colors duration-300"
                        onClick={() => navigate("/software-development")}
                      >
                        <div class="w-2 h-2 bg-black rounded-full mr-3"></div>
                        <h6 class="font-semibold mb-0">Best-in-domain IT consulting</h6>
                      </li>
                      <li
                        class="flex items-center cursor-pointer text-black hover:text-purple-600 transition-colors duration-300"
                        onClick={() => navigate("/website-design-and-development")}
                      >
                        <div class="w-2 h-2 bg-black rounded-full mr-3"></div>
                        <h6 class="font-semibold mb-0">Best in web designing.</h6>
                      </li>
                      <li
                        class="flex items-center cursor-pointer text-black hover:text-purple-600 transition-colors duration-300"
                        onClick={() => navigate("/amc-for-website-and-mobile-app")}
                      >
                        <div class="w-2 h-2 bg-black rounded-full mr-3"></div>
                        <h6 class="font-semibold mb-0">Best in domain development</h6>
                      </li>
                      <li
                        class="flex items-center cursor-pointer text-black hover:text-purple-600 transition-colors duration-300"
                        onClick={() => navigate("/e-commerce-web-development")}
                      >
                        <div class="w-2 h-2 bg-black rounded-full mr-3"></div>
                        <h6 class="font-semibold mb-0">Best-in-domain digital marketing solutions</h6>
                      </li>
                      <li
                        class="flex items-center cursor-pointer text-black hover:text-purple-600 transition-colors duration-300"
                        onClick={() => navigate("/mobile-app-development")}
                      >
                        <div class="w-2 h-2 bg-black rounded-full mr-3"></div>
                        <h6 class="font-semibold mb-0">Innovative in mobile app development</h6>
                      </li>
                      <li
                        class="flex items-center cursor-pointer text-black hover:text-purple-600 transition-colors duration-300"
                        onClick={() => navigate("/e-commerce-app-with-cms")}
                      >
                        <div class="w-2 h-2 bg-black rounded-full mr-3"></div>
                        <h6 class="font-semibold mb-0">Best in CMS Development.</h6>
                      </li>
                      <li
                        class="flex items-center cursor-pointer text-black hover:text-purple-600 transition-colors duration-300"
                        onClick={() => navigate("/cloud-based-open-source-software-development")}
                      >
                        <div class="w-2 h-2 bg-black rounded-full mr-3"></div>
                        <h6 class="font-semibold mb-0">Expert in CRM, ERP, HRM, and cloud-based development.</h6>
                      </li>
                      <li
                        class="flex items-center cursor-pointer text-black hover:text-purple-600 transition-colors duration-300"
                        onClick={() => navigate("/customized-software-development")}
                      >
                        <div class="w-2 h-2 bg-black rounded-full mr-3"></div>
                        <h6 class="font-semibold mb-0">Experienced in customized software development.</h6>
                      </li>
                    </ul>


                    <a
                      className="text-white btn rounded-pill bg-blue4 fw-bold mt-50"
                      href="/page-services-5/"
                    >
                      <small>
                        <Link as={Link} to="/ourproject" className="text-white">
                          Discover Now
                        </Link>
                      </small>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt=""
              className="bubble"
            />
          </div>
          <div className="integration pt-60" data-scroll-index="3">
            <div className="text-center section-head style-4">
              <small className="title_small">App development</small>
              <h2 className="mb-20">
                Integration With <span>Our Popular Mobile App</span>{" "}
              </h2>
              <p>
                You can connect the applications on your mobile device to the
                existing mobile app systems within your ecosystem to exchange
                data and optimize workflows.
              </p>
            </div>
            <div className="container">
              <div className="content">
                <div className="img">
                  <LazyLoadImage
                    src="https://aaratech.s3.ap-south-1.amazonaws.com/intg1.png"
                    alt=""
                    className="mt-30"
                  />
                </div>
                <div className="img">
                  <LazyLoadImage
                    src="https://aaratech.s3.ap-south-1.amazonaws.com/intg5.png"
                    alt=""
                    className="mt-30"
                  />
                </div>
                <div className="img">
                  <LazyLoadImage
                    src="https://aaratech.s3.ap-south-1.amazonaws.com/intg4.png"
                    alt=""
                    className="mt-30"
                  />
                </div>
                <div className="img">
                  <LazyLoadImage
                    src="https://aaratech.s3.ap-south-1.amazonaws.com/intg3.png"
                    alt=""
                    className="mt-30"
                  />
                </div>
                <div className="img">
                  <LazyLoadImage
                    src="https://aaratech.s3.ap-south-1.amazonaws.com/intg2.png"
                    alt=""
                    className="mt-30"
                  />
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/intg_back.png"
              alt=""
              className="intg-back"
            />
          </div>
          <LazyLoadImage
            src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_wave.png"
            alt=""
            className="top-wave"
          />
          <LazyLoadImage
            src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_wave.png"
            alt=""
            className="bottom-wave"
          />
        </section>

        <section className="testimonials style-4 pt-70" data-scroll-index="5">
          <div className="container">
            <div className="content">
              <div className="flex flex-col gap-10 md:flex-row">
                <div className="col-lg-5">
                  <div className="section-head style-4">
                    <small className="title_small">Testimonials</small>
                    <h2 className="mb-30">
                      Testimonial From  <span>Our Customers</span>
                    </h2>
                  </div>
                  <p className="mb-40 text">
                    Aara technologies loved from many customer worldwide and get
                    trusted from many big company.
                  </p>
                  <div className="numbs">
                    <div className="num-card">
                      <Link to="/ourproject" className="icon img-contain">
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/testi_s4_ic1.png"
                          alt=""
                        />
                      </Link>
                      <h2>4.5k+</h2>
                      <p>
                        Clients and <br /> Projects
                      </p>
                    </div>
                    <div className="num-card">
                      <div className="icon img-contain">
                        <StoreIcon fontSize="large" color="primary" />
                      </div>
                      <h2>4.8/5</h2>
                      <div className="stars">
                        <StarRateIcon />
                        <StarRateIcon />
                        <StarRateIcon />
                        <StarRateIcon />
                        <StarRateIcon />
                      </div>
                      <p>Based on 50 GMB reviews</p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center mt-70">
                    <a
                      href="https://www.google.com/search?q=aara+technologies+private+limited&sca_esv=6c0dc1354ced8928&sca_upv=1&sxsrf=ADLYWII5K_ffiG9mxk_V38mLCR782YKzLg%3A1726128256898&source=hp&ei=gKDiZpDNNIysseMPlaTR0A0&iflsig=AL9hbdgAAAAAZuKukD5fRkAjZuEBgclRiLfHGgoTgFIb&gs_ssp=eJwFwUsKgCAQAFDaBt3BFq0blXL0CN1itMmEfphE3b736qaPvZTfiAhReahcB6-21i8zI7IGT2QcvCwtaDNiMABmUGpqiTKJwmE9zu2MiW9x5fRQYbGlPRWef12uG1Q&oq=aara+technologies+pr&gs_lp=Egdnd3Mtd2l6GgIYAyIUYWFyYSB0ZWNobm9sb2dpZXMgcHIqAggAMg4QLhiABBjHARjJAxivATIFEAAYgAQyCBAAGIAEGMsBMgIQJjILEAAYgAQYogQYiwMyCxAAGIAEGKIEGIsDSM0rUABY5yBwAHgAkAEAmAHWAaABsA-qAQYwLjEwLjK4AQPIAQD4AQGYAgygAuMPwgIKECMYgAQYJxiKBcICERAuGIAEGLEDGNEDGIMBGMcBwgILEAAYgAQYsQMYgwHCAg4QLhiABBixAxiDARiKBcICCBAAGIAEGLEDwgIUEC4YgAQYsQMY0QMYgwEYxwEYigXCAg4QABiABBixAxiDARiKBcICCBAuGIAEGLEDwgILEC4YgAQYsQMYgwHCAgsQLhiABBjHARivAcICBxAuGIAEGArCAhcQLhiABBixAxiDARjHARiYBRiaBRivAcICBxAAGIAEGArCAgoQABiABBgCGMsBwgIGEAAYFhgemAMAkgcGMC4xMC4yoAfzgwE&sclient=gws-wiz"
                      rel="noreferrer"
                      className="text-white btn rounded-pill bg-blue4 fw-bold me-4"
                      target="_blank"
                    >
                      <small>See Reviews On GMB</small>
                    </a>
                    <a href="https://youtu.be/6Y4s4IKA6oc" className="play-btn">
                      <span className="icon me-3">
                        <PlayArrowIcon style={{ marginLeft: "6px" }} />
                      </span>
                      <strong className="small">View Promotion</strong>
                    </a>
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="testi-cards">
                    {/*  <div className="client_card">
                      <div className="user_img">
                        <LazyLoadImage
                          src={feedbacks[0]?.client_logo}
                          alt=""
                        />
                      </div>
                      <div className="relative inf_content">
                        <div className="mb-2 stars">
                          {new Array(feedbacks[0]?.feedback).fill(0).map((_, i) => <StarRateIcon key={i} />)}
                        </div>
                        <h6 className="truncate-multiline w-[230px]">
                          {feedbacks[0]?.comment}
                        </h6>
                        <p>
                          {feedbacks[0]?.client}
                          <span className="text-muted">
                            {" "}
                            {feedbacks[0]?.designation}
                           
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="client_card">
                      <div className="user_img">
                        <LazyLoadImage
                          src={feedbacks[1]?.client_logo}
                          alt=""
                        />
                      </div>
                      <div className="inf_content">
                        <div className="mb-2 stars">
                          {new Array(feedbacks[1]?.feedback).fill(0).map((_, i) => <StarRateIcon key={i} />)}
                        </div>
                        <h6 className="truncate-multiline w-[230px]">
                          {feedbacks[1]?.comment}
                        </h6>
                        <p>
                          {feedbacks[1]?.client}
                          <span className="text-muted">
                            {" "}
                            / {feedbacks[1]?.designation}
                           
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="client_card">
                      <div className="user_img">
                        <LazyLoadImage
                          src={feedbacks[2]?.client_logo}
                          alt=""
                        />
                      </div>
                      <div className="inf_content">
                        <div className="mb-2 stars">
                          {new Array(feedbacks[2]?.feedback).fill(0).map((_, i) => <StarRateIcon key={i} />)}
                        </div>
                        <h6 className="truncate-multiline w-[230px]">
                          {feedbacks[2]?.comment}
                        </h6>
                        <p>
                          {feedbacks[2]?.client}
                          <span className="text-muted">
                            {" "}
                            / {feedbacks[2]?.designation}

                          </span>
                        </p>
                      </div>
                    </div> */}
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/contact_globe.svg"
                      alt=""
                      className="testi-globe"
                    />
                  </div>
                  <div className="flex flex-col gap-3 lg:gap-5 ">
                    {feedbacks.map((i) => (
                      <div className="flex flex-col items-center justify-center gap-5 p-2  w-[100%] rounded bg-white md:flex-row">
                        <img src={i.client_logo} alt="" className="object-cover rounded-full w-28 h-28" />
                        <div className="flex flex-col justify-center lg:items-start ">
                          <Rating name="read-only" value={i.feedback} readOnly className="text-center" />
                          <p className="text-xs font-bold text-gray-800 hover:text-blue-500">{i.comment.slice(0, 95)}..</p>
                          <div className="flex whitespace-nowrap md:whitespace-normal">
                            <p className="flex lg:flex-row md:flex-col" >{i.client}/ <p className="text-gray-500">{i.designation}</p></p>
                          </div>

                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="pricing section-padding style-4 pb-50"
          data-scroll-index="6"
        >
          <div className="container">
            <div className="text-center section-head style-4">
              <small className="title_small mt-30">Our main project</small>
              <h2 className="mb-30">
                Try our <span> cloud Based Products </span>{" "}
              </h2>
            </div>
            <div className="content">
              <div className="mb-40 toggle_switch d-flex align-items-center justify-content-center"></div>
              <div className="row gx-0">
                <div className="col-lg-6">
                  <div className="price-card">
                    <div className="pb-4 price-header">
                      <h6>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/price_s4_1.png"
                          alt=""
                          className="icon"
                        />{" "}
                        ERP Software{" "}
                      </h6>
                      <h2>
                        Cloud based ERP Software <small> / Features</small>
                      </h2>
                      <p>
                        In this software, you can use over many options features
                      </p>
                      <p>Available on web, play store & App store</p>
                    </div>
                    <div className="py-1 price-body">
                      <ul>
                        <li className="mb-2 d-flex align-items-center ">
                          <small className="flex-shrink-0 text-white icon-30 bg-blue4 rounded-circle d-inline-flex align-items-center justify-content-center me-3">
                            <StickyNote2Icon style={{ marginLeft: "7px" }} />
                          </small>
                          <p className="fw-bold" style={{ marginTop: "12px" }}>
                            Custom ERP Development
                          </p>
                        </li>
                        <li className="mb-2 d-flex align-items-center ">
                          <small className="flex-shrink-0 text-white icon-30 bg-blue4 rounded-circle d-inline-flex align-items-center justify-content-center me-3">
                            <AttachFileIcon style={{ marginLeft: "7px" }} />
                          </small>
                          <p className="fw-bold" style={{ marginTop: "12px" }}>
                            Expert ERP Implementation
                          </p>
                        </li>
                        <li className="mb-2 d-flex align-items-center ">
                          <small className="flex-shrink-0 text-white icon-30 bg-blue4 rounded-circle d-inline-flex align-items-center justify-content-center me-3">
                            <LockIcon style={{ marginLeft: "7px" }} />
                          </small>
                          <p className="fw-bold" style={{ marginTop: "12px" }}>
                            Easy Data Migrations
                          </p>
                        </li>
                        <li className="mb-2 d-flex align-items-center ">
                          <small className="flex-shrink-0 text-white icon-30 bg-blue4 rounded-circle d-inline-flex align-items-center justify-content-center me-3">
                            <UndoIcon style={{ marginLeft: "7px" }} />
                          </small>
                          <p className="fw-bold" style={{ marginTop: "12px" }}>
                            ERP Integration Services
                          </p>
                        </li>
                        <li className="mb-2 d-flex align-items-center">
                          <small className="flex-shrink-0 text-white icon-30 bg-blue4 rounded-circle d-inline-flex align-items-center justify-content-center me-3">
                            <DownloadIcon style={{ marginLeft: "7px" }} />
                          </small>
                          <p className="fw-bold" style={{ marginTop: "12px" }}>
                            ERP System Configurations
                          </p>
                        </li>
                      </ul>
                      <div
                        // as={Link}
                        // to="/contact"
                        onClick={handleOpen}
                        className="px-5 btn rounded-pill border-blue4 hover-blue4 fw-bold mt-50"
                      >
                        <small>Register Now</small>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="price-card">
                    <div className="pb-4 price-header">
                      <h2>
                        Enquire about your business integrations with below
                        modules
                      </h2>
                      <p>Here also Experience cloud based software features.</p>
                      <p>Available on web, play store & App store</p>
                    </div>
                    <div className="py-1 price-body">
                      <ul>
                        <li className="mb-2 d-flex align-items-center ">
                          <small className="flex-shrink-0 text-white icon-30 bg-blue4 rounded-circle d-inline-flex align-items-center justify-content-center me-3">
                            <StickyNote2Icon style={{ marginLeft: "7px" }} />
                          </small>
                          <p className="fw-bold" style={{ marginTop: "12px" }}>
                            Inventory Management Apps
                          </p>
                        </li>
                        <li className="mb-2 d-flex align-items-center ">
                          <small className="flex-shrink-0 text-white icon-30 bg-blue4 rounded-circle d-inline-flex align-items-center justify-content-center me-3">
                            <AttachFileIcon style={{ marginLeft: "7px" }} />
                          </small>
                          <p className="fw-bold" style={{ marginTop: "12px" }}>
                            Retail Management Apps
                          </p>
                        </li>
                        <li className="mb-2 d-flex align-items-center ">
                          <small className="flex-shrink-0 text-white icon-30 bg-blue4 rounded-circle d-inline-flex align-items-center justify-content-center me-3">
                            <LockIcon style={{ marginLeft: "7px" }} />
                          </small>
                          <p className="fw-bold" style={{ marginTop: "12px" }}>
                            Native ECommerce App
                          </p>
                        </li>
                        <li className="mb-2 d-flex align-items-center ">
                          <small className="flex-shrink-0 text-white icon-30 bg-blue4 rounded-circle d-inline-flex align-items-center justify-content-center me-3">
                            <UndoIcon style={{ marginLeft: "7px" }} />
                          </small>
                          <p className="fw-bold" style={{ marginTop: "12px" }}>
                            Progressive Web App
                          </p>
                        </li>
                        <li className="mb-2 d-flex align-items-center ">
                          <small className="flex-shrink-0 text-white icon-30 bg-blue4 rounded-circle d-inline-flex align-items-center justify-content-center me-3">
                            <DownloadIcon style={{ marginLeft: "7px" }} />
                          </small>
                          <p className="fw-bold" style={{ marginTop: "12px" }}>
                            ERP POS APP
                          </p>
                        </li>
                      </ul>
                      <div
                        // as={Link}
                        // to="/contact"
                        onClick={handleOpen}
                        className="px-5 text-white btn rounded-pill bg-blue4 hover-blue4 fw-bold mt-50"
                      >
                        <small>Register Now</small>
                      </div>
                    </div>
                    <div className="off">
                      <span>
                        30% <br /> off
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="faq section-padding style-4 pt-50"
          data-scroll-index="7"
        >
          <div className="container">
            <div className="text-center section-head style-4">
              <small className="title_small">Frequently Asked Question</small>
              <h2 className="mb-30">
                Need A <span>Support?</span>{" "}
              </h2>
            </div>
            <div className="content">
              <div className="faq style-3 style-4">
                <div className="accordion" id="accordionSt4">
                  <div className="row gx-5">
                    <div className="grid grid-cols-2 gap-10">
                      <Accordion>
                        {faq?.slice(0, 4)?.map((faq, index) => (
                          <Accordion.Item eventKey={String(index)} key={index}>
                            <Accordion.Header>{faq.question}</Accordion.Header>
                            <Accordion.Body>
                              {faq.answer}
                            </Accordion.Body>
                          </Accordion.Item>
                        ))}
                      </Accordion>
                      <Accordion>
                        {faq?.slice(4)?.map((faq, index) => (
                          <Accordion.Item eventKey={String(index + 4)} key={index}>
                            <Accordion.Header>{faq.question}</Accordion.Header>
                            <Accordion.Body>
                              {faq.answer}
                            </Accordion.Body>
                          </Accordion.Item>
                        ))}
                      </Accordion>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pt-4 community section-padding style-4 mb-30 mt-30">
          <div className="container">
            <div className="text-center section-head style-4">
              <small className="title_small">Aara Technologies Community</small>
              <h2 className="mb-30">
                Join US on <span>social media platform</span>{" "}
              </h2>
            </div>
            <div class="max-w-screen-lg mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-6">
              <a
                href="https://www.facebook.com/aaratechnologies/"
                class="flex items-center space-x-4 p-4 bg-white rounded-lg shadow-md border-2 border-purple-200 hover:border-purple-400 transition-all duration-300 hover:bg-purple-50"
              >
                <div class="text-blue-600">
                  <FacebookIcon class="w-8 h-8" />
                </div>
                <div>
                  <h5 class="text-lg font-semibold">Facebook</h5>
                  <p class="text-sm text-gray-500">Open Source & Social Media</p>
                </div>
              </a>

              <a
                href="https://twitter.com/aaratechnology"
                class="flex items-center space-x-4 p-4 bg-white rounded-lg shadow-md border-2 border-purple-200 hover:border-purple-400 transition-all duration-300 hover:bg-purple-50"
              >
                <div class="text-blue-400">
                  <TwitterIcon class="w-8 h-8" />
                </div>
                <div>
                  <h5 class="text-lg font-semibold">Twitter</h5>
                  <p class="text-sm text-gray-500">Latest News & Updates</p>
                </div>
              </a>

              <a
                href="https://www.linkedin.com/company/aaratechnologies"
                class="flex items-center space-x-4 p-4 bg-white rounded-lg shadow-md border-2 border-purple-200 hover:border-purple-400 transition-all duration-300 hover:bg-purple-50"
              >
                <div class="text-blue-700">
                  <LinkedInIcon class="w-8 h-8" />
                </div>
                <div>
                  <h5 class="text-lg font-semibold">LinkedIn</h5>
                  <p class="text-sm text-gray-500">Channel for Community</p>
                </div>
              </a>
            </div>

          </div>
        </section>
      </div>
    </>
  );
}

export default Home;
