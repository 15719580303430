import React, { useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Flutter = (props) => {
  const cards = [
    {
      name: "bus App",
      type1: "App development",
      image: 'https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/buss.png',
      tags: "IOT App",
    },
    {
      name: "NRP App",
      type1: "App development",
      image: 'https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/nrp-app.png',
      tags: "IOT App",
    },
    {
      name: "RYR App",
      type1: "App development",
      image: 'https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/ryr-app.png',
      tags: "IOT App",
    },
  ];

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <Helmet>
        <title>{props.title}</title>
        <meta name="description" content={props.description} />
        <meta name="keywords" content={props.keywords} />
        <link rel="canonical" href="https://aaratechnologies.com/iot-development" />
      </Helmet>
      <main className="portfolio-page style-1">
        <section
          className="bg-white portfolio-projects section-padding style-1"
        >
          <div className="container">
            <div className="mb-40 text-center section-head style-4">
              <h2 className="mb-20">
                <span>IOT Development</span> Company
              </h2>
              <p>
                Aara technologies is the top IOT Development Company which is
                knowledge and adept IOT Developer to having hands on experience.
              </p>
            </div>
          </div>
        </section>

        <section className="team section-padding style-6">
          <div className="mb-40 content1">
            <div className="container">
              <div className="mb-20 text-center section-head style-4">
                <small className="title_small">IOT Development</small>
                <h2 className="mb-20">
                  <span>IOT Development</span> Company
                </h2>
                <p>
                  Helping businesses across the globe to unlock the infinite
                  possibilities of internet-of-things. We provide complete IoT
                  development services from strategy and consulting to
                  end-to-end implementation and integration of
                  internet-of-things into the client’s infrastructure. Our
                  experts integrate gateways, edge devices, and cloud platforms
                  to provide you with a perfectly-architected IoT ecosystem.
                </p>
                <LazyLoadImage
                  src="https://aaratech.s3.ap-south-1.amazonaws.com/iot-2.webp"
                  className="imgmad"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="about section-padding style-4">
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/google-trends.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>
                          Custom IoT Solutions For Startups And Enterprises
                        </span>
                      </h2>
                    </div>
                    <p className="mb-40 text">
                      Our IoT app developers provide services from product
                      conceptualization to ongoing IoT development services. We
                      help you build IoT applications that integrate multiple
                      data streams to create potential software products and new
                      possibilities. As a reliable IoT app development partner,
                      we consider security as an integral part of IoT hardware
                      design and app development.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>

          <div className="mb-40 conten">
            <div className="container">
              <div className="mb-20 text-center section-head style-4">
                <small className="title_small">Services</small>
                <h2 className="mb-20">
                  Our <span> IoT Application Development Services</span>
                </h2>
                <Row className="mb-20">
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/iot/icons/IoT-Development-Consultancy.svg"
                          className="mb-20"
                        />
                        <Card.Subtitle>
                          IoT Development Consultancy
                        </Card.Subtitle>
                        <Card.Text>
                          We offer technology consulting services through our
                          experienced IoT app consultants. Want to get your free
                          consultation today? Contact us now.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage src="https://aaratech.s3.ap-south-1.amazonaws.com/iot/icons/Application-Development-for-IoT-Devices.svg" alt="" />
                        <br />
                        <Card.Subtitle>
                          Application Development for IoT Devices
                        </Card.Subtitle>
                        <Card.Text>
                          Our IoT app development team helps you to develop a
                          specific apps for different devices, helping you to
                          reach your business goal and
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage src="https://aaratech.s3.ap-south-1.amazonaws.com/iot/icons/wearable-devices.svg" alt="" />
                        <Card.Subtitle>
                          Connectivity with wearable devices
                        </Card.Subtitle>
                        <Card.Text>
                          Our custom IoT development company in India has rich
                          domain expertise in developing apps for all types of
                          wearable devices & connect with it.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row className="mb-20">
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage src="https://aaratech.s3.ap-south-1.amazonaws.com/iot/icons/IoT-Application-Development-svg.svg" alt="" />
                        <Card.Subtitle>
                          IoT Application Development
                        </Card.Subtitle>
                        <Card.Text>
                          IoT mobile app programmers & developers will help you
                          to get the right IoT development solution. We deliver
                          flexible strategies blended with high security to
                          manage diverse IoT devices.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage src="https://aaratech.s3.ap-south-1.amazonaws.com/iot/icons/IoT-Gateway-Development.svg" alt="" />
                        <Card.Subtitle>IoT Gateway Development</Card.Subtitle>
                        <Card.Text>
                          We are one-stop internet of things development company
                          in India for all your IoT development-related
                          requirements therefore we develop IoT
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage src="https://aaratech.s3.ap-south-1.amazonaws.com/iot/icons/Voice-Enabled-Technology.svg" alt="" />
                        <Card.Subtitle>
                          Voice Enabled Technology Solutions
                        </Card.Subtitle>
                        <Card.Text>
                          Our internet of things application development company
                          always strives to provide advanced IoT mobile app
                          development solutions & provide
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row className="mb-20">
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage src="https://aaratech.s3.ap-south-1.amazonaws.com/iot/icons/Backend%26API.svg" alt="" />
                        <Card.Subtitle>Backend & API Development</Card.Subtitle>
                        <Card.Text>
                          Our team of expert internet of things programmers and
                          coders provides complete backend and API development
                          solutions to our
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage src="https://aaratech.s3.ap-south-1.amazonaws.com/iot/icons/Implementation%26Support.svg" alt="" />
                        <Card.Subtitle>
                          IoT Implementation & Support
                        </Card.Subtitle>
                        <Card.Text>
                          We not only provide internet of things app development
                          services but also provide complete support &
                          maintenance services to our
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage src="https://aaratech.s3.ap-south-1.amazonaws.com/iot/icons/Data-Analytics.svg" alt="" />
                        <Card.Subtitle>Data Analytics</Card.Subtitle>
                        <Card.Text>
                          Our developed IoT mobile apps can perform advanced
                          levels of data analysis that suits best to your
                          business requirements.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </div>
            </div>
          </div>

          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">
                        Industry Specific IoT Use-Cases
                      </small>
                      <h2 className="mb-30">
                        <span>Logistics And Transportation Management</span>
                      </h2>
                    </div>
                    <p className="text">
                      We provide custom logistics app development for logistics
                      on web and mobile platforms for better user engagement,
                      custom reporting and real-time data interaction.
                    </p>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/iot/Logistics-And-Transportation.webp"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
          <div className="content trd-content bgmad">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/iot/Warehouse-Management.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>Warehouse Management</span>
                      </h2>
                    </div>
                    <p className="mb-40 text">
                      We build AI-enabled warehouse management applications that
                      help logistics tracking software in better inventory
                      management and improve transparency.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
          <div className="content sec-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="order-2 col-lg-6 order-lg-0">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>Logistics Data Exchange</span>
                      </h2>
                    </div>
                    <p className="mb-40 text">
                      Our logistics app development provides scalable blockchain
                      apps that will bring transparency across your financial
                      ecosystem while ensuring security.
                    </p>
                  </div>
                </div>
                <div className="col-lg-5 order-0 order-lg-2">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/anular-plugin.webp"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png' alt="" className="bubble2" />
          </div>
          <div className="content trd-content bgmad">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/e-commerce-app.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>Order Management</span>
                      </h2>
                    </div>
                    <p className="mb-40 text">
                      Our team has in-depth knowledge of AIS software, including
                      QuickBooks, Sage and SAP. We have integrated AIS software
                      with ERP and smart business intelligence applications for
                      startup and enterprises with our logistics software
                      development.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
          <div className="content sec-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="order-2 col-lg-6 order-lg-0">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>Smart Supply Chain Solutions</span>
                      </h2>
                    </div>
                    <p className="text">
                      To make effective, efficient, and agile logistics
                      operations, we add autonomy and intelligence with the
                      integration of IoT and AI to the applications.
                    </p>
                  </div>
                </div>
                <div className="col-lg-5 order-0 order-lg-2">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/laravel-api.webp"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png' alt="" className="bubble2" />
          </div>
          <div className="content trd-content bgmad">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/iot/Reporting%26BI.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>Reporting & BI</span>
                      </h2>
                    </div>
                    <p className="text">
                      We develop RPA bots for financial companies to take over
                      the labor-intensive financial processes and enhance the
                      productivity of employees.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
          <div className="content sec-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="order-2 col-lg-6 order-lg-0">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        Why Should You Go For{" "}
                        <span>IoT Application Development?</span>
                      </h2>
                    </div>
                    <p className="text">
                      The Internet-of-Things (IoT) is taking over everything.
                      Increase audience size & decrease spend by deploying IoT
                      features into your business. Our IoT development firm in
                      India is a trusted name providing full-cycle development
                      at affordable prices.
                    </p>
                    <ol className="list-style-two">
                      <li>
                        <p>Improved marketing automation</p>
                      </li>
                      <li>
                        <p>Better revenue generation</p>
                      </li>
                      <li>
                        <p>Automated &amp; optimized processes</p>
                      </li>
                      <li>
                        <p>Smart and enhanced supply chain</p>
                      </li>
                      <li>
                        <p>Personalized offering</p>
                      </li>
                      <li>
                        <p>Enhanced processes in complex scenarios</p>
                      </li>
                      <li>
                        <p>Optimized use of resources</p>
                      </li>
                      <li>
                        <p>Increased sales</p>
                      </li>
                    </ol>
                  </div>
                </div>
                <div className="col-lg-5 order-0 order-lg-2">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/iot/IoT-application.webp"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png' alt="" className="bubble2" />
          </div>
        </section>
        <section
          className="bg-white portfolio-projects section-padding style-1"
        >
          <div className="container">
            <div className="mb-40 text-center section-head style-4">
              <h2 className="mb-20">
                Our  <span>Projects</span>
              </h2>
              <p>Here are given our some projects to know about our work.</p>
            </div>

            <section className="portfolio style-1">
              <div className="content">
                <div className="row mix-container">
                  {cards.map((card) => (
                    <div className="col-lg-4 mix security consultation">
                      <div className="text-center portfolio-card mb-30 scalmad">
                        <div className="img">
                          <LazyLoadImage src={card.image} alt="" style={{ objectFit: 'contain' }} />
                        </div>
                        <div className="info">
                          <h5>
                            <a href={card.link}>{card.name}</a>
                          </h5>
                          <small className="d-block color-main text-uppercase">
                            {card.type1}
                          </small>
                          <div className="tags">
                            <a href="#" className="me-1">
                              {card.tags}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Flutter;
