import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

export function FloatingBanner({ denyText, onAccept, onDeny }) {
  return (
    <div className="fixed-bottom pb-2" style={{ zIndex: 2000 }}>
      <div className="container">
        <div className="p-3 rounded bg-white shadow-lg">
          <div className="d-flex  justify-content-between align-items-center">
            <div className="d-flex w-[80%] flex-column">
              <div className="h5 font-weight-bold text-black">
                Cookie Settings
              </div>
              <div className="text-muted">
                <span className="d-md-none">We use our own cookies to </span>
                <span className="d-none d-lg-block">
                  We use our own cookies so that we can show you this website
                  and understand how you see them to improve the services we
                  offer.
                </span>
              </div>
            </div>
            <div className="d-flex w-40 mt-3 mt-sm-0 gap-2">
              {onAccept !== undefined && (
                <button
                  className="btn btn-primary flex-grow-1 flex-sm-grow-0"
                  onClick={onAccept}
                >
                  Accept All
                </button>
              )}
              {denyText !== undefined && (
                <button
                  className="btn btn-secondary flex-grow-1 flex-sm-grow-0"
                  onClick={onDeny}
                >
                  Deny
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
