import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { pageViewEvent } from "../Analytics";

const usePageView = () => {
  const location = useLocation();

  useEffect(() => {
    pageViewEvent({ path: location.pathname, title: document.title });
  }, [location.pathname]);
};

export default usePageView;
