import React from "react";
import { Accordion, Col, Row } from "react-bootstrap";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Project = (props) => {
  const cards = [
    {
      name: "Share Grapes App",
      type1: "App Mobility",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/sg-app.png",
      link: "/page-single-project-5/",
      description:
        "Share Grapes App is the service app to provide facilites to seller to sell their products.",
      tags: "App Mobility",
    },
    {
      name: "Clop Solution site",
      type1: "WEBSITE DEVELOPMENT",
      description:
        "Clop Solution site is service provider website to provide IT services.",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/clop-solution.png",
      link: "/page-single-project-5/",
      tags: "Web design",
    },
    {
      name: "Rootless Auto Site",
      type1: "WEB DEVELOPEMNT",
      description:
        "Rootless Auto Site is service provider to connect the service center of automobile.",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/rootless-auto.png",
      link: "/page-single-project-5/",
      tags: "WEB DEVELOPEMENT",
    },
  ];
  return (
    <div>
      <Helmet>
        <title>{props.title}</title>
        <h1 name="h1" content={props.h1} h1 />
        <meta name="description" content={props.description} />
        <meta name="keywords" content={props.keywords} />
        <link rel="canonical" href="https://aaratechnologies.com/amc-for-website-and-mobile-app" />

      </Helmet>
      <main className="portfolio-page style-1">
        <section
          className="bg-white portfolio-projects section-padding style-1"
          id="MixItUpFCF1BF"
        >
          <div className="container">
            <div className="mb-40 text-center section-head style-4">
              <small className="title_small">AMC for development</small>
              <h2 className="mb-20">
                <span> AMC for website & Mobile app </span>
              </h2>
              <p>
                We have an experienced team of AMC Software Development in
                Entire India and worldwide.
              </p>
            </div>
          </div>
        </section>

        <section className="team section-padding style-6">
          <div className="content1">
            <div className="container">
              <div className="section-head style-4 mb-60">
                <Row>
                  <Col className="mb-20">
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          <h2>AMC Service</h2>
                        </Accordion.Header>
                        <Accordion.Body>
                          <ul>
                            <li className="mb-20 d-flex align-items-center">
                              <ArrowRightIcon />
                              <h6 className="fw-bold">
                                Latest announcement or information update on
                                home page as per request
                              </h6>
                            </li>
                            <li className="mb-20 d-flex align-items-center">
                              <ArrowRightIcon />
                              <h6 className="fw-bold">
                                Any tender, information form, notice and report
                                upload in relevant section.
                              </h6>
                            </li>
                            <li className="mb-20 d-flex align-items-center">
                              <ArrowRightIcon />
                              <h6 className="fw-bold">
                                Product upload and management
                              </h6>
                            </li>
                            <li className="mb-20 d-flex align-items-center">
                              <ArrowRightIcon />
                              <h6 className="fw-bold">
                                Editing of text content in existing web pages
                              </h6>
                            </li>
                            <li className="mb-20 d-flex align-items-center">
                              <ArrowRightIcon />
                              <h6 className="fw-bold">
                                Photo gallery update as per request
                              </h6>
                            </li>
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Col>
                  <Col className="mb-20">
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          <h2>Benefit Of AMC</h2>
                        </Accordion.Header>
                        <Accordion.Body>
                          <ul>
                            <li className="mb-20 d-flex align-items-center">
                              <ArrowRightIcon />
                              <h6 className="fw-bold">
                                You do not need to hire a dedicated developer to
                                maintain your firm website.
                              </h6>
                            </li>
                            <li className="mb-20 d-flex align-items-center">
                              <ArrowRightIcon />
                              <h6 className="fw-bold">
                                We provide better quality and speedy work.
                              </h6>
                            </li>
                            <li className="mb-20 d-flex align-items-center">
                              <ArrowRightIcon />
                              <h6 className="fw-bold">
                                New technologies and tools can be integrated
                              </h6>
                            </li>
                            <li className="mb-20 d-flex align-items-center">
                              <ArrowRightIcon />
                              <h6 className="fw-bold">Frequent update</h6>
                            </li>
                            <li className="mb-20 d-flex align-items-center">
                              <ArrowRightIcon />
                              <h6 className="fw-bold">
                                Frequent updates improve your search engine
                                ranking.
                              </h6>
                            </li>
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Col>
                  <Col>
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          <h2>Up To Date</h2>
                        </Accordion.Header>
                        <Accordion.Body>
                          <ul>
                            <li className="mb-20 d-flex align-items-center">
                              <ArrowRightIcon />
                              <h6 className="fw-bold">
                                Disaster Recovery Services
                              </h6>
                            </li>
                            <li className="mb-20 d-flex align-items-center">
                              <ArrowRightIcon />
                              <h6 className="fw-bold">
                                Service Satisfaction Follow-up
                              </h6>
                            </li>
                            <li className="mb-20 d-flex align-items-center">
                              <ArrowRightIcon />
                              <h6 className="fw-bold">
                                Customer Follow-up Services
                              </h6>
                            </li>
                            <li className="mb-20 d-flex align-items-center">
                              <ArrowRightIcon />
                              <h6 className="fw-bold">
                                Data Validation Services
                              </h6>
                            </li>
                            <li className="mb-20 d-flex align-items-center">
                              <ArrowRightIcon />
                              <h6 className="fw-bold">
                                Research & Information Gathering
                              </h6>
                            </li>
                            <li className="mb-20 d-flex align-items-center">
                              <ArrowRightIcon />
                              <h6 className="fw-bold">
                                Tele Surveys & Customer Acquisition
                              </h6>
                            </li>
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </section>

        <section className="about section-padding style-4">
          <div className="content frs-content" id="about" data-scroll-index="2">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-6">
                  <div className="mb-10 img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/cs-ratina-responsive.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">
                        Software Development
                      </small>
                      <h2 className="mb-40">
                        Full Range Of Software Engineering Services
                      </h2>
                    </div>
                    <h5>Best Custom Software Development Company in noida</h5>
                    <br />
                    <p>
                      We provide a complete website maintenance service from a
                      small content update, bug fixing, troubleshooting,
                      critical security updates, SSL installation, module
                      configurations, installation to version upgrades and much
                      more. Our years of expertise, well-defined process, and
                      innovative work approach has helped clients to increase
                      their customer base and trust. Our key to success is
                      building a long-term business relationship with our valued
                      customers that go beyond one-time project. We have a young
                      and expert team of professionals available to take up any
                      challenges and responsibilities to keep your website
                      running efficiently and smoothly so you can save time on
                      hiring professional in-house and concentrate on what’s
                      important to you. Whether you're a start-up, small
                      business, enterprise or large-scale corporation, we offer
                      a complete website support and maintenance solution that
                      will cover all of your bases.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_lines.png"
              alt=""
              className="lines1"
            />
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt=""
              className="bubble"
            />
          </div>
          <div className="content sec-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="order-2 col-lg-6 order-lg-0">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">AMC</small>
                      <h2 className="mb-40">
                        <span> Mobile Application Maintenance </span>
                      </h2>
                    </div>
                    <p className="mb-20 text">
                      We provide a complete Mobile App maintenance service for
                      any kind of iOS and Android Application from app store
                      maintenance, app crash monitoring, app feedback
                      monitoring, UI/UX enhancement, performance monitoring to
                      Migrating applications to different platforms and more.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 order-0 order-lg-2">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/mobile-app-development.webp"
                      className="w-75"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png"
              alt=""
              className="bubble2"
            />
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-6">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/logo.png"
                      className="w-75"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">AMC</small>
                      <h2 className="mb-30">
                        Why Choose <span> Aara Technologies? </span>
                      </h2>
                    </div>
                    <p className="mb-40 text">
                      We provide a complete solution for your business. You may
                      not dedicate a lot of time to updating your website or
                      mobile app. We’ll give you updates to provide us with
                      unique content for your website daily, and our team will
                      make sure it looks brand new every time you visit!
                    </p>
                    <h4>
                      We are now a better outcome for our clients as a result of
                      specific facts:
                    </h4>
                    <br />
                    <ul>
                      <li className="mb-20 d-flex">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          Ensures that your website attracts, engages, and helps
                          in the development of your market.
                        </h6>
                      </li>
                      <li className="mb-20 d-flex">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          Provides appropriate and valuable content to help your
                          customers do business with you.
                        </h6>
                      </li>
                      <li className="mb-20 d-flex">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          Enhances the search engine optimization of a website
                        </h6>
                      </li>
                      <li className="mb-20 d-flex">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          Enhances the image of your business
                        </h6>
                      </li>
                      <li className="mb-20 d-flex">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          Ensures that the material on the website is proper and
                          up to date.
                        </h6>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt=""
              className="bubble"
            />
          </div>
        </section>
        <section className="mb-40 team section-padding style-6">
          <div className="content1">
            <div className="container">
              <div className="section-head style-4">
                <small className="title_small">FAQ</small>
              </div>
              <div className="section-head style-4 mb-60">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <h5>What is Website Maintenance?</h5>
                    </Accordion.Header>
                    <Accordion.Body>
                      Well, a website is just like your health. If you don’t go
                      for regular check-up; your health may fall apart. Website
                      maintenance is the act of ongoing checking or taking care
                      of your website from minor security updates, errors, bug
                      issues, mistakes, content updates to platform upgrades or
                      migration.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      <h5>
                        Why does my website need regular maintenance or retainer
                        service?
                      </h5>
                    </Accordion.Header>
                    <Accordion.Body>
                      Every website need regular maintenance or retainer
                      services to ensure all the components of website including
                      forms, content, images, payment methods, new locations,
                      etc. A well-maintained and regularly updated website
                      signifies a dynamic, professional and versatile
                      organization and a trusted brand reputation, which
                      visitors love to explore, time to time. Regular website
                      maintenance keep your visitors updated and engaged about
                      your brand.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      <h5>
                        What does it mean when a website is down for
                        maintenance?
                      </h5>
                    </Accordion.Header>
                    <Accordion.Body>
                      When you see a notice that a website is down for
                      maintenance; it usually means that the website is
                      temporarily unavailable due to fixing some issues, working
                      mode for some of updates or it might be migrating the
                      server or platform.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      <h5>
                        How do I request additional support and/or website
                        customization?
                      </h5>
                    </Accordion.Header>
                    <Accordion.Body>
                      For any type of additional support and/or website
                      customization, Feel free to mail us anytime on
                      info@aaratechnologies.com. We will get back to you with a
                      precise quote or information within 24 hours.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      <h5>
                        Would website maintenance help in improving search
                        engine rankings?
                      </h5>
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, Regular website maintenance is the key element to
                      your website in SERPs. With updated information over a
                      website, the search engine crawlers find it best to index.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      <h5>
                        Are you providing regular maintenance for online store
                        or ecommerce website?
                      </h5>
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes. We are providing online store development,
                      maintenance and digital marketing services from past 19
                      years.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      <h5>
                        For which Platform or CMS are you providing website
                        maintenance services?
                      </h5>
                    </Accordion.Header>
                    <Accordion.Body>
                      We are providing website maintenance services for Drupal,
                      WordPress, Magento, PHP, Laravel, CodeIgniter, RoR,
                      Salesforce, Python and more.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </section>
        <section className="portfolio style-1 ">
          <div className="content">
            <div className="row mix-container">
              {cards.map((card, index) => (
                <div className="col-lg-4 mix security consultation">
                  <div className="text-center portfolio-card mb-30 scalmad">
                    <div className="img">
                      <LazyLoadImage
                        src={card.image}
                        alt=""
                        style={{ objectFit: "contain" }}
                      />
                    </div>
                    <div className="info">
                      <h5>
                        <a href={card.link}>{card.name}</a>
                      </h5>
                      <small className="d-block color-main text-uppercase">
                        {card.type1}
                      </small>
                      <div className="text">{card.description}</div>
                      <div className="tags">
                        <a href="#" className="me-1">
                          {card.tags}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Project;
