import { CardGiftcardTwoTone } from "@mui/icons-material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { useEffect } from "react";
import { Card } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import "swiper/css";
import "swiper/css/virtual";
function About(props) {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div>
      <Helmet>
        <title>{props.title}</title>
        <meta name="description" content={props.description} />
        <meta name="keywords" content={props.keywords} />
      </Helmet>
      <main className="about-page style-5 about">
        <section className="about section-padding style-4">
          <div className="integration">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="text-center section-head style-4">
                  <small className="title_small">
                    We Are Leading Software Company
                  </small>

                  <h2 className="mb-20">
                    <span> Agency Profile </span>{" "}
                  </h2>

                  <p>
                    Based in India, we are engaged in best-in-domain IT
                    consulting, web designing, development & digital marketing
                    solutions.
                  </p>
                  <h1 className="mb-30 text-[#5842bc]">
                    <span>Top Software Companies In India</span>
                  </h1>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="content mb-100 pb-100 border-1 border-bottom brd-gray">
                <div className="img">
                  <LazyLoadImage
                    src="https://aaratech.s3.ap-south-1.amazonaws.com/Group+289472.png"
                    alt="Top Software Companies In India"
                    className="mt-30 w-36"
                  />
                </div>
                <div className="img">
                  <LazyLoadImage
                    src="https://aaratech.s3.ap-south-1.amazonaws.com/Group+289473.png"
                    alt="Top Software Companies In India"
                    className="mt-30 w-36"
                  />
                </div>
                <div className="img">
                  <LazyLoadImage
                    src="https://aaratech.s3.ap-south-1.amazonaws.com/Group+289474.png"
                    alt="Top Software Companies In India"
                    className="mt-30 w-36"
                  />
                </div>
                <div className="img">
                  <LazyLoadImage
                    src="https://aaratech.s3.ap-south-1.amazonaws.com/Group+289475.png"
                    alt="Top Software Companies In India"
                    className="mt-30 w-36"
                  />
                </div>
                <div className="img">
                  <LazyLoadImage
                    src="https://aaratech.s3.ap-south-1.amazonaws.com/Group+289474.png"
                    alt="Top Software Companies In India"
                    className="mt-30 w-36"
                  />
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://pub-8c4be935579646acb14e868abf1bcb56.r2.dev/assets/homeimage/assets_homeimage_intg_back (1).png"
              alt="Top Software Companies In India"
              className="intg-back"
            />
          </div>
          <div className="content frs-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://pub-8c4be935579646acb14e868abf1bcb56.r2.dev/assets/homeimage/assets_homeimage_amico%20(1).png"
                      alt="Top Software Companies In India"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">About Our Company</small>

                      <h2 className="mb-30">
                        The Agency For <span>Innovation</span>{" "}
                      </h2>
                    </div>
                    <p className="mb-40 text">
                      In addition, we also provide cloud solutions and hosting
                      services to our global clients. We help enterprises,
                      agencies, SMEs and startups to design, develop and evolve
                      advance software that supplies their ad-hoc software needs
                      and brings ideas to life Founded in 2016, AARA
                      Technologies is proud to be led by extremely focused and
                      dedicated professionals, who are known for their
                      entrepreneurial skills, experience, and expertise in a
                      wide spectrum of industries.
                      <h3 className="my-4 text-[#5842bc]">Top Software Companies In India</h3>
                    </p>
                    <ul>
                      <li className="mb-3 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          Best-in-domain IT consulting
                        </h6>
                      </li>
                      <li className="mb-3 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">Best-in-Web designing</h6>
                      </li>
                      <li className="mb-3 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          Best-in-Digital marketing solutions
                        </h6>
                      </li>
                      <li className="mb-3 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">Contract Based development</h6>
                      </li>
                      <li className="mb-3 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">Project based development</h6>
                      </li>
                      <li className="d-flex align-items-center ">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">Production support</h6>
                      </li>
                    </ul>
                    <Link
                      as={Link}
                      to="/contact"
                      target="_blank"
                      className="text-white btn rounded-pill bg-blue4 fw-bold mt-50"
                    >
                      <small>Enquiry Now</small>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://pub-8c4be935579646acb14e868abf1bcb56.r2.dev/assets/homeimage/about_s4_lines.png"
              alt="Top Software Companies In India"
              className="lines"
            />
            <LazyLoadImage
              src="https://pub-8c4be935579646acb14e868abf1bcb56.r2.dev/assets/homeimage/about_s4_bubble.png"
              alt="Top Software Companies In India"
              className="bubble"
            />
          </div>
          <div className="content frs-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-7">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">About Our Company</small>
                      <h2 className="mb-30">
                        Our <span> Vision</span>{" "}
                      </h2>
                    </div>
                    <p className="mb-40 text">
                      Our vision is to be the most valuable global mobile/web
                      development company. We focus on constant innovation as
                      our key for achieving our aim of success and emerge as
                      global leader by providing the superior quality solutions.
                      If you already have some ideas in mind, then why not give
                      us a call or email us with your requirements for a
                      no-obligation quotation.

                    </p>
                    <div className="section-head style-4">
                      <h3 className="mb-30 text-[#5842bc]">
                        Mission <span> And Values</span>{" "}
                      </h3>
                    </div>
                    <p>
                      Being professional web & mobile app development and
                      digital marketing company. Our mission is to provide
                      result-oriented, customer-centric, cost-competitive
                      innovative & functional IT solutions to our global
                      clients.. Quality in a product or service is not what the
                      supplier puts in. It is what the customer gets out and is
                      willing to pay for. A product is not quality because it is
                      hard to make and costs a lot of money, as manufacturers
                      typically believe. This is incompetence. Customers pay
                      only for what is of use to them and gives them value.
                      Nothing else constitutes quality. Our values are: people,
                      customers and quality.
                    </p>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://pub-8c4be935579646acb14e868abf1bcb56.r2.dev/assets/tech1%20(1).png"
                      alt="Top Software Companies In India"
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://pub-8c4be935579646acb14e868abf1bcb56.r2.dev/assets/homeimage/about_s4_bubble.png"
              alt="Top Software Companies In India"
              className="bubble"
            />
          </div>
          <div className="content frs-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/tech2.gif"
                      alt="Top Software Companies In India"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">About Our solutions</small>
                      <h2 className="mb-30">
                        We Provide <span> Best Solutions</span>{" "}
                      </h2>
                    </div>
                    <p className="mb-40 text">
                      Our web design & development company leverages large
                      knowledge base to deliver best-in-domain solutions that
                      meet needs, business and budget expectations of our
                      customers. Our experts offer advanced technology solutions
                      that add real-value to their business. It is simple— we
                      understand that our success is measured by the success of
                      our esteemed clients.
                      <h3 className="my-4 text-[#5842bc]">Top Software Companies In India</h3>
                    </p>
                    <p>
                      Customer satisfaction, long-term support and maintenance.
                      A long term relationship through close alignment
                      throughout & after the execution cycle.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://pub-8c4be935579646acb14e868abf1bcb56.r2.dev/assets/homeimage/about_s4_bubble.png"
              alt="Top Software Companies In India"
              className="bubble"
            />
          </div>
        </section>
        <section className="team section-padding style-6 ">
          <div className="content">
            <div className="container">
              <div className="text-center section-head style-4 mb-60">
                <small className="title_small">The Team Work</small>
                <h2 className="mb-20">
                  Our <span>Leaders</span>{" "}
                </h2>
                <h3 className="mb-20 text-[#5842bc]">Ready to make software with us</h3>
                <p>
                  "We are thrilled that you have chosen to partner with us for
                  your software development needs! Our team of expert developers
                  is excited to collaborate with you and bring your vision to
                  life.

                </p>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-4 lg:px-[10%]">
                <Card>
                  <Card.Img
                    variant="top"
                    src="https://aaratech.s3.ap-south-1.amazonaws.com/team3.png"
                    alt="Top Software Companies In India"
                  />
                  <Card.Body>
                    <Card.Title>
                      <Link to="">

                        <h6>Periklis Gonis</h6>
                      </Link>
                    </Card.Title>
                    <Card.Text>COO APAC Region Aara Groups</Card.Text>
                  </Card.Body>
                </Card>

                <Card>
                  <Card.Img variant="top" src={"https://aaratech.s3.ap-south-1.amazonaws.com/meenakshi.webp"} alt="Top Software Companies In India" />
                  <Card.Body>
                    <Card.Title>
                      <Link to="">
                        <h6>Meenakshi Tiwari</h6>
                      </Link>
                    </Card.Title>
                    <Card.Text>Founder Managing Director</Card.Text>
                  </Card.Body>
                </Card>

                <Card>
                  <Card.Img
                    variant="top"
                    src="https://aaratech.s3.ap-south-1.amazonaws.com/team1.png"
                    alt="Top Software Companies In India"
                  />
                  <Card.Body>
                    <Card.Title>
                      <Link to="">
                        <h6>Shankar Naidu</h6>
                      </Link>
                    </Card.Title>
                    <Card.Text>Co-Founder, Chief Technical Officer</Card.Text>
                  </Card.Body>
                </Card>

                <Card>
                  <Card.Img variant="top" src={"https://aaratech.s3.ap-south-1.amazonaws.com/p1.webp"} alt="Top Software Companies In India" />
                  <Card.Body>
                    <Card.Title>
                      <Link to="">
                        <h6>William Fernandes</h6>
                      </Link>
                    </Card.Title>
                    <Card.Text>Country Head UK Aara Groups</Card.Text>
                  </Card.Body>
                </Card>

                <Card>
                  <Card.Img variant="top" src={"https://aaratech.s3.ap-south-1.amazonaws.com/p2.webp"} alt="Top Software Companies In India" />
                  <Card.Body>
                    <Card.Title>
                      <Link to="">
                        <h6>Daniel Mpumwire</h6>
                      </Link>
                    </Card.Title>
                    <Card.Text>Country Head Aara Groups India</Card.Text>
                  </Card.Body>
                </Card>

                {/* <Card>
                  <Card.Img variant="top" src={"https://aaratech.s3.ap-south-1.amazonaws.com/nandan.jpeg"} alt="Top Software Companies In India" />
                  <Card.Body>
                    <Card.Title>
                      <Link to="">
                        <h6>Nandan Kumar</h6>
                      </Link>
                    </Card.Title>
                    <Card.Text>Ops Manager India</Card.Text>
                  </Card.Body>
                </Card> */}

                <Card>
                  <Card.Img variant="top" src={"https://aaratech.s3.ap-south-1.amazonaws.com/nisha.jpeg"} alt="Top Software Companies In India" />
                  <Card.Body>
                    <Card.Title>
                      <Link to="">
                        <h6>Nisha Bhalla</h6>
                      </Link>
                    </Card.Title>
                    <Card.Text>HR Operation Lucknow India</Card.Text>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        </section>
        <section className="team section-padding style-4 mt-30">
          <div className="content">
            <div className="container">
              <div className="text-center section-head style-4 mb-60">
                <small className="title_small">Our Philosophy</small>
                <h2 className="mb-20">
                  Discover our <span>Culture</span>{" "}
                </h2>
                <h3 className="text-[#5842bc]"> Top Software Companies In India</h3>
                <p>
                  More 15,000 Companies &amp; partners trusted &amp; choice Aara
                  Technologies
                </p>
              </div>
              <div className="team4 ">
                <Card className="scalmad">
                  <Card.Img
                    variant="top"
                    src="https://pub-8c4be935579646acb14e868abf1bcb56.r2.dev/assets/culture1%20(1).jfif"
                    alt="Top Software Companies In India"
                  />
                </Card>
                <Card className="scalmad">
                  <Card.Img
                    variant="top"
                    src="https://pub-8c4be935579646acb14e868abf1bcb56.r2.dev/assets/culture5%20(1).jfif"
                    alt="Top Software Companies In India"
                  />
                </Card>
                <Card className="scalmad">
                  <Card.Img
                    variant="top"
                    src="https://pub-8c4be935579646acb14e868abf1bcb56.r2.dev/assets/culture4%20(1).jfif"
                    alt="Top Software Companies In India"
                  />
                </Card>
                <Card className="scalmad">
                  <Card.Img
                    variant="top"
                    src="https://pub-8c4be935579646acb14e868abf1bcb56.r2.dev/assets/culture3%20(1).jfif"
                    alt="Top Software Companies In India"
                  />
                </Card>
                <CardGiftcardTwoTone className="scalmad">
                  <Card.Img
                    variant="top"
                    src="https://pub-8c4be935579646acb14e868abf1bcb56.r2.dev/assets/aboutimage/culture2.jfif"
                    alt="Top Software Companies In India"
                  />
                </CardGiftcardTwoTone>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default About;
