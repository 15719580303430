import CallIcon from "@mui/icons-material/Call";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Whatsapp from "./whatsapp";
import { Link } from "react-router-dom";
import "./App.css";
import img53 from "./assets/imoj_heart.png";
import { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import logo from "./assets/aaralogo.webp"

function Header() {
  const [show, setShow] = useState(false);
  const showDropdown = (e) => {
    setShow(!show);
  };
  const hideDropdown = (e) => {
    setShow(false);
  };

  const [show1, setShow1] = useState(false);
  const showDropdown1 = (e) => {
    setShow1(!show1);
  };
  const hideDropdown1 = (e) => {
    setShow1(false);
  };

  const [expanded, setExpanded] = useState(false);
  const handleNavClose = () => {
    setExpanded(false);
  };
  return (
    <div className="fixedhead">
      {/* <div id="preloader" className="isdone"></div> */}
      <div className="top-navbar style-4">
        <div className="container">
          <div className="text-white content">
            <span className="px-2 py-0 bg-white btn sm-butn me-2 fs-10px">
              <small className="fs-10px" style={{ color: "black" }}>
                Special
              </small>
            </span>
            <LazyLoadImage src={img53} alt="" class="icon-15 me-1" />
            <span className="fs-10px op-6 me-1">Get </span>
            <small className="op-10 fs-10px">New Software</small>
            <span className="mx-1 fs-10px op-6">for Your Business</span>
            <a
              className="fs-10px text-decoration-underline ms-2"
              href="tel:+919266667999"
            >
              Call Now
            </a>
          </div>
        </div>
      </div>
      <nav className="navbar navbar-expand-lg navbar-light style-4 ">
        <Navbar style={{ width: "100%" }} expand="lg" expanded={expanded}>
          <Container>
            <Link to="/">
              <LazyLoadImage
                // src="https://aaratech.s3.ap-south-1.amazonaws.com/aaralogo.png"
                src={logo}
                alt=""
                // style={{ width: "78px", height: "80px" }}
                width={"78px"}
                height={"80px"}
              />
            </Link>
            <Navbar.Toggle
              aria-controls="navbarScroll"
              onClick={() => setExpanded(!expanded)}
            />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <ul className="mb-2 navbar-nav mb-lg-0 text-uppercase">
                  <li class="nav-item">
                    <Nav.Link as={Link} to="/" onClick={handleNavClose}>
                      Home
                    </Nav.Link>
                  </li>
                  <li className="nav-item">
                    <Nav.Link as={Link} to="/about" onClick={handleNavClose}>
                      About
                    </Nav.Link>
                  </li>
                  {/*   <li className="nav-item">
                    <Nav.Link as={Link} to="/about1" onClick={handleNavClose}>
                      About1
                    </Nav.Link>
                  </li> */}
                  <NavDropdown
                    title="Service"
                    id="collasible-nav-dropdown"
                    show={show}
                    onMouseEnter={showDropdown}
                    onMouseLeave={hideDropdown}
                  >
                    <div className="service2">
                      <div>
                        <NavDropdown.Item
                          as={Link}
                          to="/mobile-app-development"
                          onClick={handleNavClose}
                        >
                          Mobile App Development
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={Link}
                          to="/e-commerce-app-with-cms"
                          onClick={handleNavClose}
                        >
                          Ecommerce App with CMS
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={Link}
                          to="/website-design-and-development"
                          onClick={handleNavClose}
                        >
                          Website Design and Development
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={Link}
                          to="/cloud-based-open-source-software-development"
                          onClick={handleNavClose}
                        >
                          Cloud Based Software Development
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={Link}
                          to="/api-development"
                          onClick={handleNavClose}
                        >
                          API Development
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={Link}
                          to="/crm-development"
                          onClick={handleNavClose}
                        >
                          CRM Development
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={Link}
                          to="/hrm-development"
                          onClick={handleNavClose}
                        >
                          HRM Development
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={Link}
                          to="/automated-reminder-system"
                          onClick={handleNavClose}
                        >
                          Automated Reminder System
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={Link}
                          to="/mlm"
                          onClick={handleNavClose}
                        >
                          MLM Development
                        </NavDropdown.Item>
                      </div>
                      <div className="service1">
                        <NavDropdown.Item
                          as={Link}
                          to="/ecommerce-app-development"
                          onClick={handleNavClose}
                        >
                          Ecommerce App Development
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={Link}
                          to="/software-development"
                          onClick={handleNavClose}
                        >
                          Software Development
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={Link}
                          to="/enterprise-Software-solutions"
                          onClick={handleNavClose}
                        >
                          enterprise Software Solutions
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={Link}
                          to="/amc-for-website-and-mobile-app"
                          onClick={handleNavClose}
                        >
                          AMC for Website & Mobile Apps
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={Link}
                          to="/e-commerce-web-development"
                          onClick={handleNavClose}
                        >
                          Ecommerce marketplace Development
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={Link}
                          to="/erp-development"
                          onClick={handleNavClose}
                        >
                          Custom ERP Development
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={Link}
                          to="/customized-software-development"
                          onClick={handleNavClose}
                        >
                          Custom Software Development
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={Link}
                          to="/lms"
                          onClick={handleNavClose}
                        >
                          Learning Management System
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={Link}
                          to="/ui-ux-design"
                          onClick={handleNavClose}
                        >
                          UI UX Design
                        </NavDropdown.Item>
                      </div>
                    </div>
                  </NavDropdown>
                  <NavDropdown
                    title="Technologies"
                    className="technologies1"
                    show={show1}
                    onMouseEnter={showDropdown1}
                    onMouseLeave={hideDropdown1}
                  >
                    <div className="service2">
                      <div>
                        <NavDropdown.Item
                          as={Link}
                          to="/magento-development"
                          onClick={handleNavClose}
                        >
                          Magento Development
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={Link}
                          to="/woocommerce-development"
                          onClick={handleNavClose}
                        >
                          WooCommerce Development
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={Link}
                          to="/prestashop-development"
                          onClick={handleNavClose}
                        >
                          Prestashop Development
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={Link}
                          to="/odoo-development"
                          onClick={handleNavClose}
                        >
                          Odoo Development
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={Link}
                          to="/laravel-development"
                          onClick={handleNavClose}
                        >
                          Laravel Development
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={Link}
                          to="/ios-app-development"
                          onClick={handleNavClose}
                        >
                          IOS Development
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={Link}
                          to="/flutter-development"
                          onClick={handleNavClose}
                        >
                          Flutter Development
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={Link}
                          to="/iot-development"
                          onClick={handleNavClose}
                        >
                          IOT Development
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={Link}
                          to="/react-native-development"
                          onClick={handleNavClose}
                        >
                          React Native
                        </NavDropdown.Item>
                      </div>
                      <div className="service1">
                        <NavDropdown.Item
                          as={Link}
                          to="/opencart-development"
                          onClick={handleNavClose}
                        >
                          Opencart Development
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={Link}
                          to="/shopify-development"
                          onClick={handleNavClose}
                        >
                          Shopify Development
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={Link}
                          to="/cs-cart-development"
                          onClick={handleNavClose}
                        >
                          CS Cart Development
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={Link}
                          to="/codeigniter-development"
                          onClick={handleNavClose}
                        >
                          CodeIgniter Development
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={Link}
                          to="/python-development"
                          onClick={handleNavClose}
                        >
                          Python Development
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={Link}
                          to="/android-app-development"
                          onClick={handleNavClose}
                        >
                          Android Development
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={Link}
                          to="/ai-ar-product"
                          onClick={handleNavClose}
                        >
                          AI & AR
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={Link}
                          to="/angular-development"
                          onClick={handleNavClose}
                        >
                          Angular Development
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={Link}
                          to="/nodejs-development"
                          onClick={handleNavClose}
                        >
                          Node Js Development
                        </NavDropdown.Item>
                      </div>
                    </div>
                  </NavDropdown>

                  <li className="nav-item">
                    <Nav.Link
                      as={Link}
                      to="/ourproject"
                      onClick={handleNavClose}
                    >
                      Project
                    </Nav.Link>
                  </li>
                  <li className="nav-item">
                    <Nav.Link as={Link} to="/contact" onClick={handleNavClose}>
                      Contact
                    </Nav.Link>
                  </li>
                </ul>
                <div className="nav-side" style={{ marginTop: "-4px" }}>
                  <div className="d-flex align-items-center">
                    <a href="tel:+919266667999" className="search-icon me-4">
                      &nbsp;
                      <CallIcon />
                    </a>
                    <Nav.Link
                      className="btn rounded-pill brd-gray hover-blue4 sm-butn fw-bold text-nowrap"
                      as={Link}
                      to="/contact"
                    >
                      <span>Schedule a Demo</span>
                    </Nav.Link>
                  </div>
                </div>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </nav>
      <Whatsapp />
    </div>
  );
}

export default Header;
