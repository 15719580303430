import React, { useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Python = (props) => {
  const cards = [
    {
      name: "Amaka Industries",
      type1: "Python development",
      image: 'https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/amakaindustries.png',
      link: "",
      description:
        "Amaka website is to sell different types of species and grocery items.",
      tags: "development",
    },
    {
      name: "Pickle Man website",
      type1: "Python DEVELOPMENT",
      description:
        "Pickle man website is price comparison shopping site",
      image: 'https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/PickleMan.png',
      link: "",
      tags: "Development",
    },
    {
      name: "OEMSPA Website",
      type1: "Python DEVELOPEMNT",
      description:
        "OEMSPA Website is to sell the different types of harware.",
      image: 'https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/oemspa-web.png',
      link: "",
      tags: "Development",
    },
  ];

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <Helmet>
        <title>{props.title}</title>
        <meta name="description" content={props.description} />
        <meta name="keywords" content={props.keywords} />
        <link rel="canonical" href="https://aaratechnologies.com/python-development" />
      </Helmet>
      <main className="portfolio-page style-1">
        <section
          className="bg-white portfolio-projects section-padding style-1"
        >
          <div className="container">
            <div className="mb-40 text-center section-head style-4">
              <h2 className="mb-20">
                <span>Python Development</span> Company
              </h2>
            </div>
          </div>
        </section>

        <section className="team section-padding style-6">
          <div className="mb-40 content1">
            <div className="container">
              <div className="mb-20 text-center section-head style-4">
                <small className="title_small">Web & App Development</small>
                <h2 className="mb-20">
                  <span>Python </span> Development
                </h2>
                <p>
                  Aara technologies are one of the best leading and most
                  experienced python development company not only in India it's
                  worldwide, providing the best python development solution.
                  Python is interpreted high- level programming languages.
                </p>
                <LazyLoadImage
                  src="https://aaratech.s3.ap-south-1.amazonaws.com/technologies-based-banner.webp"
                  className="imgmad"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="about section-padding style-4">
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/google-trends.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>
                          Create Your Business Site With Our Python Development
                        </span>
                      </h2>
                    </div>
                    <p className="text">
                      As per the current situation Python development is taking
                      75% market, All businessmen want to design and develop
                      Mobile Applications or websites with the help of Python
                      programming for growing their Business, By developing
                      Mobile Applications or websites they make their work easy
                      or save lots of time also and also decrease complexity.
                      Apart from that, all Mobile Applications or website
                      Development have a great impact on the e-commerce market
                      because E-commerce Mobile Applications gave a chance to
                      make their business in the top ones.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
          <div className="mb-40 conten">
            <div className="container">
              <div className="mb-20 text-center section-head style-4">
                <h2 className="mb-30">
                  <span>Advantages Of Python Development Service</span>
                </h2>
                <Row className="mb-20">
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage src="https://aaratech.s3.ap-south-1.amazonaws.com/icon1.webp" alt="" />
                        <Card.Subtitle>OpenSource Platform</Card.Subtitle>
                        <Card.Text>
                          An end-to-end open-source machine learning platform
                          for everyone. Discover TensorFlow's flexible ecosystem
                          of tools, libraries, and
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage src="https://aaratech.s3.ap-south-1.amazonaws.com/icon1.webp" alt="" />
                        <Card.Subtitle>Routing Management</Card.Subtitle>
                        <Card.Text>
                          Routing is the mechanism of mapping the URL directly
                          to the code that creates the webpage. It helps in
                          better management of the structure of
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage src="https://aaratech.s3.ap-south-1.amazonaws.com/icon1.webp" alt="" />
                        <Card.Subtitle>GUI Programming Support</Card.Subtitle>
                        <Card.Text>
                          Graphical User interfaces can be made using a module
                          such as PyQt5, PyQt4, wxPython, or Tk in python
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row className="mb-20">
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage src="https://aaratech.s3.ap-south-1.amazonaws.com/icon1.webp" alt="" />
                        <Card.Subtitle>Composer based MVC</Card.Subtitle>
                        <Card.Text>
                          A model that deals with the business logic, a view for
                          the user interface, and a controller to handle the
                          user input, manipulate data, and update the view.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage src="https://aaratech.s3.ap-south-1.amazonaws.com/icon1.webp" alt="" />
                        <Card.Subtitle>Highly Secure</Card.Subtitle>
                        <Card.Text>
                          Python is one of the most popular programming
                          languages in cybersecurity due to its simple syntax
                          and readability. In this post, we'll look at
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage src="https://aaratech.s3.ap-south-1.amazonaws.com/icon1.webp" alt="" />
                        <Card.Subtitle>High-Level Language</Card.Subtitle>
                        <Card.Text>
                          Python is a high-level language. When we write
                          programs in python language, we do not need to
                          remember the system architecture, nor do we
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row className="mb-20">
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage src="https://aaratech.s3.ap-south-1.amazonaws.com/icon1.webp" alt="" />
                        <Card.Subtitle>Eloquent ORM</Card.Subtitle>
                        <Card.Text>
                          An object-relational mapper (ORM) is a code library
                          that automates the transfer of data stored in
                          relational database tables into objects that are
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage src="https://aaratech.s3.ap-south-1.amazonaws.com/icon1.webp" alt="" />
                        <Card.Subtitle>Supports Cache Handlers</Card.Subtitle>
                        <Card.Text>
                          It is supporting auto expiring the cache data. It is
                          used to store all cache data <br />
                          from memory.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage src="https://aaratech.s3.ap-south-1.amazonaws.com/icon1.webp" alt="" />
                        <Card.Subtitle>Community Supported</Card.Subtitle>
                        <Card.Text>
                          Python language program is performing dynamic
                          functionality. Python language is a supporting
                          framework.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">
                        Here Are The Services Includes For Python Development
                      </small>
                      <h2 className="mb-30">
                        <span>Complex Business Application Development</span>
                      </h2>
                    </div>
                    <p className="text">
                      As we know the current market situation, We all know every
                      business requires a Mobile Application Either that will be
                      simple or complex, More people want a simple and
                      attractive Mobile Application. Python Programming language
                      is an open-source programming language that will help us
                      design and develop complex Mobile applications in a simple
                      way. With the help of Python programming, we can develop
                      more attractive mobile applications which will be
                      user-friendly as well.
                    </p>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/business.webp"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/woo-mobile.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>ECommerce And Marketplace Development</span>
                      </h2>
                    </div>
                    <p className="mb-40 text">
                      For e-Commerce business Mobile applications play an
                      important role. Because we are using e-Commerce Mobile
                      applications for Buying or Selling our product in the
                      market without interacting with people. We can design and
                      develop Market place applications also by using Python
                      Programming Language. The Market place is a place where we
                      can sell multiple goods in one place. Every user wants a
                      place where they can purchase all necessary goods in one
                      place and save time also.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>Headless And Microservices</span>
                      </h2>
                    </div>
                    <p className="text">
                      The microservice has a single point of entry that allows
                      the user to create a resource with a PUT request,
                      corresponding to a URL of their choice, the GET request
                      serves the request. The stale items are deleted after a
                      year. Microservices is supporting Kubernetes also. We
                      mostly see containers as if they are faster / simpler VMs,
                      in other words, each container includes an
                      execute-specific functionality with a unique lifecycle and
                      dependency on other containers.
                    </p>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/headless-and-microservices.webp"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/e-commerce-app.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>
                          Mobile Apps
                          <br /> Development
                        </span>
                      </h2>
                    </div>
                    <p className="text">
                      By using Python Programming language we can design &
                      develop a good and attractive Mobile application for our
                      business. Every Business is having their own unique path
                      for growth in the market and has its own requirement as
                      well. As we know that we are an application development
                      Company, We must understand the requirements of clients
                      and help them to build an application according to the
                      requirement of the client business. We are also building
                      applications for enterprise-level businesses. As per your
                      business requirements, we can also build applications for
                      your in-house use only. Which will be used for only your
                      users. We are building applications on the latest
                      technologies with advanced features.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>Services And API Integration</span>
                      </h2>
                    </div>
                    <p className="text">
                      Python programming is currently a boom in the market.
                      Everyone wants to design their website in Python. Python
                      is an open-source programming language that is used to
                      develop web applications. This is not possible to build a
                      lengthy process application in a single mobile application
                      so we need to use third-party API to integrate that
                      feature into the mobile Application. Aara Technologies is
                      integrating third-party API into the mobile application in
                      very easy ways such as payment gateway, shipping methods,
                      Chat Systems, reporting Systems, Multiple currencies, and
                      many more. As per business requirements Integration of
                      Third to Provide Best Services to Your Customers Can Give
                      Your Customers the Best of Convenience.
                    </p>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/laravel-api.webp"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
          <div className="mb-40 content1">
            <div className="container">
              <div className="mb-20 text-center section-head style-4">
                <h2 className="mb-20">
                  <span>
                    Support & Development Team Is Known For Its Good Service &
                    Experience
                  </span>
                </h2>
                <p>
                  From purchase to fulfillment – manage the entire order process
                  via the app. Scroll the summaries, or search by customer or
                  order specifics. Tap into the details to see itemized billing,
                  begin fulfillment, and change order status.
                </p>
                <LazyLoadImage src="https://aaratech.s3.ap-south-1.amazonaws.com/laravel-banner.webp" alt="" />
              </div>
            </div>
          </div>
        </section>
        <section
          className="bg-white portfolio-projects section-padding style-1"
        >
          <div className="container">
            <div className="mb-40 text-center section-head style-4">
              <h2 className="mb-20">
                Our  <span>Projects</span>
              </h2>
            </div>
            <section className="portfolio style-1">
              <div className="content">
                <div className="row mix-container">
                  {cards.map((card) => (
                    <div className="col-lg-4 mix security consultation">
                      <div className="text-center portfolio-card mb-30 scalmad">
                        <div className="img">
                          <LazyLoadImage src={card.image} alt="" style={{ objectFit: 'contain' }} />
                        </div>
                        <div className="info">
                          <h5>
                            <a href={card.link}>{card.name}</a>
                          </h5>
                          <small className="d-block color-main text-uppercase">
                            {card.type1}
                          </small>
                          <div className="text">{card.description}</div>
                          <div className="tags">
                            <a href="#" className="me-1">
                              {card.tags}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Python;
