import React, { useEffect } from "react";
import { Accordion, Card, Col, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Faq from "../../FAQ";

const Nodejs = (props) => {
  const cards = [
    {
      name: "OKM Web",
      type1: "Web app development",
      image: 'https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/okm-web.png',
      tags: "Nodejs development",
    },
    {
      name: "SG App",
      type1: "Web app development",
      image: 'https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/sg-app.png',
      tags: "Nodejs development",
    },
    {
      name: "Bus App",
      type1: "Web app development",
      image: 'https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/buss.png',
      tags: "Nodejs development",
    },
  ];

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div>
      <Helmet>
        <title>{props.title}</title>
        <meta name="description" content={props.description} />
        <meta name="keywords" content={props.keywords} />
        <link rel="canonical" href="https://aaratechnologies.com/nodejs-development" />
      </Helmet>
      <main className="portfolio-page style-1">
        <section
          className="bg-white portfolio-projects section-padding style-1"
        >
          <div className="container">
            <div className="mb-40 text-center section-head style-4">
              <h2 className="mb-20">
                <span>Node Js Development</span> Company
              </h2>
            </div>
          </div>
        </section>

        <section className="team section-padding style-6">
          <div className="mb-40 content1">
            <div className="container">
              <div className="mb-20 text-center section-head style-4">
                <small className="title_small">Web Development</small>
                <h2 className="mb-20">
                  <span>Node Js </span> Development
                </h2>
                <p>
                  Node.js development company is designed to easily create fast
                  and scalable web applications and develop solutions for
                  high-traffic needs. It offers a wide range of advantages: Easy
                  to learn: Since most developers already know JavaScript, it's
                  easy to get started using Node.
                </p>
                <LazyLoadImage
                  src="https://aaratech.s3.ap-south-1.amazonaws.com/andriod-app.webp"
                  className="imgmad"
                />
              </div>
            </div>
          </div>
          <div className="mb-40 conten">
            <div className="container">
              <div className="mb-20 text-center section-head style-4">
                <h2 className="mb-20">
                  <span>
                    Start Your Business With Our Node Js Development Enterprise{" "}
                  </span>
                </h2>
                <p>
                  One of the biggest reasons why Node.js is so widely used in
                  enterprise app development is because it helps in building
                  cross-platform apps for desktop users. The codes for building
                  web apps can be reused for desktop apps for macOS, Windows,
                  and Linux. Also, developers that develop web apps can work on
                  desktop apps without being required to acquire additional
                  skills, such as Objective-C, C#, and other such languages.
                </p>
                <LazyLoadImage src="https://aaratech.s3.ap-south-1.amazonaws.com/andriod-app-project.webp" alt="" />
              </div>
            </div>
          </div>
        </section>
        <section className="about section-padding style-4">
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/business.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>Every Minute Matters In The Digital World</span>
                      </h2>
                    </div>
                    <p className="text">
                      One of the biggest reasons why Node.js is so widely used
                      in enterprise app development is because it helps in
                      building cross-platform apps for desktop users. The codes
                      for building web apps can be reused for desktop apps for
                      macOS, Windows, and Linux. Also, developers that develop
                      web apps can work on desktop apps without being required
                      to acquire additional skills, such as Objective-C, C#, and
                      other such languages.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
          <div className="mb-40 conten">
            <div className="container">
              <div className="mb-20 text-center section-head style-4">
                <h2 className="mb-30">
                  <span>
                    Accomplish Your Business Goals With Android App Development
                    Service
                  </span>
                </h2>
                <p className="text-start">
                  Node-Js development focuses on creating apps that are
                  intuitive and accessible. Mobile apps are leveraged to deliver
                  a multi-channel or Omni channel experience to the users. Our
                  mobile app development has a set of advanced features that
                  deliver the finest user experience.
                </p>
                <Row className="mb-20">
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage src="https://aaratech.s3.ap-south-1.amazonaws.com/icon1.webp" alt="" />
                        <Card.Subtitle>Target Potential Users</Card.Subtitle>
                        <Card.Text>
                          This is a very effective step to choose a target
                          audience. If you are to launch any product in the
                          market for selling purposes then you must have
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage src="https://aaratech.s3.ap-south-1.amazonaws.com/icon1.webp" alt="" />
                        <Card.Subtitle>Increase Brand Exposure</Card.Subtitle>
                        <Card.Text>
                          It is a way where you can send appropriate
                          well-considered notifications to your mobile
                          application users will increase your brand
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage src="https://aaratech.s3.ap-south-1.amazonaws.com/icon1.webp" alt="" />
                        <Card.Subtitle>Push Quick Updates</Card.Subtitle>
                        <Card.Text>
                          This application is updated according to your target,
                          Whatever your updated application will be, it will
                          give you a notification keeping your security
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row className="mb-20">
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage src="https://aaratech.s3.ap-south-1.amazonaws.com/icon1.webp" alt="" />
                        <Card.Subtitle>Deliver High Performance</Card.Subtitle>
                        <Card.Text>
                          One of the biggest factors of Mobile Applications
                          every one wants to launch success Mobile application
                          on the basis of Mobile application performance.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage src="https://aaratech.s3.ap-south-1.amazonaws.com/icon1.webp" alt="" />
                        <Card.Subtitle>Bring Trust & Loyalty</Card.Subtitle>
                        <Card.Text>
                          This app takes care of the privacy of your users and
                          maintains their trust and integrity By which you get a
                          Positive way or path and you can create more apps.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage src="https://aaratech.s3.ap-south-1.amazonaws.com/icon1.webp" alt="" />
                        <Card.Subtitle>
                          Ensure Delightful Experience
                        </Card.Subtitle>
                        <Card.Text>
                          It will help to identify a way of assisting your
                          existing clients or customers. Your company feels good
                          and realistic. By this, you will get a unique
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="section-head style-4">
                  <h2 className="mb-30">
                    <span>
                      Here Are The Main Features Of The App Development
                    </span>
                  </h2>
                  <p>
                    Aara Technologies is a very famous company for building and
                    attracting Mobile Applications for their customers. As we
                    all know that in the current situation android app is having
                    a great future in market.
                  </p>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>App Development</span>
                      </h2>
                    </div>
                    <p className="text">
                      Choosing a development approach is subjective and requires
                      consideration as per the desired user experience,
                      resources, app features, and project timeline. You don’t
                      need any coding experience to start making your own. We
                      are a team of top-notch full stack android developers that
                      has great hands-on experience with Android app development
                      tools and framework. We build native Android applications
                      in Kotlin and Java languages. Apart from this Dart and
                      React Native is also used for Web app development.
                    </p>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/andriod-app-development.webp"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png" alt="" className="bubble" />
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/headless-and-microservices.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>App Integration</span>
                      </h2>
                    </div>
                    <p className="mb-40 text">
                      Application integration is the process of enabling
                      individual applications—each designed for its own specific
                      purpose—to work with one another. By merging and
                      optimizing data and workflows between multiple software
                      applications, organizations can achieve integrations that
                      modernize their infrastructures and support agile business
                      operations.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>Mobile Apps UI/UX Design</span>
                      </h2>
                    </div>
                    <p className="text">
                      We are aware of the fact that on day to day basis we are
                      taking steps ahead toward digitalization. In this era,
                      mobile phone usage is at its peak. Because it is well
                      known for its great user experience. Also, these days,
                      mobile-related technologies are also getting more popular
                      with their advancements. Better UI/UX provides a great
                      user experience and helps to meet their expectations.
                      These days for mobile app development companies the
                      competition is also at its peak. So there is a mush need
                      to be attractive and extra convenient for the users.
                      Ultimately it will also enhance the growth of mobile apps.
                    </p>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/andriod-app-ui.webp"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/odoo-theme-developement.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>Mobile App Maintenance</span>
                      </h2>
                    </div>
                    <p className="text">
                      Aara Technologies is providing Mobile Maintenance services
                      to our customers for updating their mobile applications
                      according to the latest technology and customer demands.
                      Customers want the latest thing so according to the market
                      demand Mobile applications also require maintenance on
                      time.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>Widget Development</span>
                      </h2>
                    </div>
                    <p className="text">
                      Widgets are the shortcut icons that are displayed on the
                      home screen of the application. It keeps working even
                      after minimizing the application. Also, it is the
                      miniature app views. It is also popularly known as
                      Graphical Control Elements. We build widgets for your
                      application that can be added to the home screen of the
                      users. It helps to let them see important information at a
                      glance. Widgets are also a direct gateway into the
                      applications for the users. There are some most common
                      widgets i.e Calendar, Clock, Weather, etc.
                    </p>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/Reporting%26BI.webp"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
          <div className="mb-40 conten">
            <div className="container">
              <div className="mb-20 text-center section-head style-4">
                <h2 className="mb-20">
                  <span>
                    Serving Middle East Countries With Our Android Apps
                  </span>
                </h2>
                <p>
                  Aara Technologies are providing application development
                  services globally ,Aara Technologies are providing all
                  services below mentioned countries:
                </p>
                <LazyLoadImage src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/Available-Countries.webp" alt="" />
              </div>
            </div>
          </div>
          <div className="mb-40 conten">
            <div className="container">
              <div className="mb-20 text-center section-head style-4">
                <h2 className="mb-30">
                  <span>
                    We Have Worked Across Different Verticals And Industries
                  </span>
                </h2>
                <Row className="mb-20">
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/food-grocery.svg"
                          className="mb-20"
                        />
                        <Card.Subtitle>Food & Grocery</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/retail.svg"
                          className="w-40 mb-20"
                        />
                        <br />
                        <Card.Subtitle>Retail</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/fmcg.svg"
                          className="w-40 mb-20"
                        />
                        <Card.Subtitle>FMCG</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/real-estate.svg"
                          className="w-40 mb-20"
                        />
                        <Card.Subtitle>Real Estate</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/construction.svg"
                          className="w-40 mb-20"
                        />
                        <Card.Subtitle>Construction</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/fashion-apparel.svg" alt="" />
                        <Card.Subtitle>Fashion & Apparel</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row className="mb-20">
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/hotel.svg"
                          className="w-40 mb-20"
                        />
                        <Card.Subtitle>Hotel</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/healthcare.svg"
                          className="w-40 mb-20"
                        />
                        <br />
                        <Card.Subtitle>Healthcare</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/telecom.svg"
                          className="w-40 mb-20"
                        />
                        <Card.Subtitle>Telecom</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/fintech.svg"
                          className="w-40 mb-20"
                        />
                        <Card.Subtitle>Fintech</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/manufacturing.svg"
                          className="w-40 mb-20"
                        />
                        <Card.Subtitle>Manufacturing</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/automotive.svg"
                          className="w-40 mb-20"
                        />
                        <Card.Subtitle>Automotive</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </div>
            </div>
          </div>

          <Faq />
        </section>
        <section
          className="bg-white portfolio-projects section-padding style-1"
        >
          <div className="container">
            <div className="mb-40 text-center section-head style-4">
              <h2 className="mb-20">
                Our  <span>Projects</span>
              </h2>
            </div>
            <section className="portfolio style-1">
              <div className="content">
                <div className="row mix-container">
                  {cards.map((card) => (
                    <div className="col-lg-4 mix security consultation">
                      <div className="text-center portfolio-card mb-30 scalmad">
                        <div className="img">
                          <LazyLoadImage src={card.image} alt="" style={{ objectFit: 'contain' }} />
                        </div>
                        <div className="info">
                          <h5>
                            <a href={card.link}>{card.name}</a>
                          </h5>
                          <small className="d-block color-main text-uppercase">
                            {card.type1}
                          </small>
                          <div className="tags">
                            <a href="#" className="me-1">
                              {card.tags}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </div>
        </section>

      </main>
    </div>
  );
};

export default Nodejs;
