import "bootstrap/dist/css/bootstrap.min.css";
import "./loader.css";

export const Loader = ({ className }) => {
  return (
    <div
      className={`position-absolute w-100  d-flex justify-content-center align-items-center ${className}`}
      style={{
        zIndex: 40,
        backgroundColor: "rgba(255, 204, 255, 0.1)",
        height: "100%",
      }}
    >
      <div
        id="page"
        className="position-sticky top-40 d-flex flex-columns justify-content-center align-items-center  text-danger rounded-circle"
        style={{
          height: "12rem",
          width: "12rem",
          backgroundColor: "rgba(255, 204, 255, 0.1)",
        }}
      >
        <div id="container" className="text-center">
          <div id="ring" className="loader-ring"></div>
          <div id="ring" className="loader-ring"></div>
          <div id="ring" className="loader-ring"></div>
          <div id="ring" className="loader-ring"></div>
          <div id="h3" className="h3 text-danger fw-bold">
            Loading
          </div>
        </div>
      </div>
    </div>
  );
};
