import React, { useEffect } from "react";
import { Accordion, Card, Col, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Faq from "../../FAQ";

const Android = (props) => {
  const cards = [
    {
      name: "ZZZ mart App",
      type1: "App development",
      image: 'https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/zzzmart-app.png',
      link: "",
      tags: "Android app",
    },
    {
      name: "BUS App",
      type1: "App development",
      image: 'https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/buss.png',
      link: "",
      tags: "Android app",
    },
    {
      name: "OKM App",
      type1: "App development",
      image: 'https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/okm-app.png',
      link: "",
      tags: "Android app",
    },
  ];

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <Helmet>
        <title>{props.title}</title>
        <meta name="description" content={props.description} />
        <meta name="keywords" content={props.keywords} />
        <link rel="canonical" href="https://aaratechnologies.com/android-app-development" />
      </Helmet>
      <main className="portfolio-page style-1">
        <section
          className="bg-white portfolio-projects section-padding style-1"
        >
          <div className="container">
            <div className="mb-40 text-center section-head style-4">
              <h2 className="mb-20">
                <span>Android App Development</span> Company
              </h2>
            </div>
          </div>
        </section>

        <section className="team section-padding style-6">
          <div className="mb-40 content1">
            <div className="container">
              <div className="mb-20 text-center section-head style-4">
                <small className="title_small">App Development</small>
                <h2 className="mb-20">
                  <span>Android App </span> Development
                </h2>
                <p>
                  We are one of the best leading Android app development company
                  in Noida, delhi, India, and worldwide. We have 5+ years of
                  hands-on experience and we are having a well-experienced team
                  for all Software Solutions, We have Developed 500+ Projects
                  across the world.
                </p>
                <LazyLoadImage
                  src="https://aaratech.s3.ap-south-1.amazonaws.com/andriod-app.webp"
                  className="imgmad"
                />
              </div>
            </div>
          </div>
          <div className="mb-40 conten">
            <div className="container">
              <div className="mb-20 text-center section-head style-4">
                <h2 className="mb-20">
                  <span>
                    Start Your Business With Our Android App Development
                    Enterprise{" "}
                  </span>
                </h2>
                <p>
                  As per the current situation Android app development is taking
                  80% of the market, All businessmen want to design and develop
                  Android Mobile applications for their Business, By developing
                  Mobile Applications they make their work easy or save lots of
                  time also. Apart from that, all Mobile Application Development
                  has a great impact on e-commerce Market because E-commerce
                  Mobile Applications gave a chance to make their business in
                  the top ones.
                </p>
                <LazyLoadImage src="https://aaratech.s3.ap-south-1.amazonaws.com/andriod-app-project.webp" alt="" />
              </div>
            </div>
          </div>
        </section>
        <section className="about section-padding style-4">
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/business.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>Every Minute Matters In The Digital World</span>
                      </h2>
                    </div>
                    <p className="text">
                      You know the current market depends on android apps and
                      everyone wants something new, and this is possible only
                      with continued updating, If you are an owner of any
                      android app development company then you must be always
                      ready for updating things according to the requirement of
                      the market. Because everyone wants where they stand they
                      Look Unique.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
          <div className="mb-40 conten">
            <div className="container">
              <div className="mb-20 text-center section-head style-4">
                <h2 className="mb-30">
                  <span>
                    Accomplish Your Business Goals With Android App Development
                    Service
                  </span>
                </h2>
                <p>
                  Currently mobile app development taking a huge place in the
                  market, and Currently, Android Mobile Application is playing
                  the main role in any business. It helps to make human life too
                  easy and smooth.
                </p>
                <p>
                  Mobile Application is mainly focused on attractive design with
                  advanced features, Because everyone wants to use mobile
                  applications now so the current situation of the market is
                  telling us to Design and Develop easy or user-friendly Android
                  Mobile Applications.
                </p>
                <Row className="mb-20">
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage src="https://aaratech.s3.ap-south-1.amazonaws.com/icon1.webp" alt="" />
                        <Card.Subtitle>Target Potential Users</Card.Subtitle>
                        <Card.Text>
                          This is a very effective step to choose a target
                          audience. If you are to launch any product in the
                          market for selling purposes then you must have
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage src="https://aaratech.s3.ap-south-1.amazonaws.com/icon1.webp" alt="" />
                        <Card.Subtitle>Increase Brand Exposure</Card.Subtitle>
                        <Card.Text>
                          It is a way where you can send appropriate
                          well-considered notifications to your mobile
                          application users will increase your brand
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage src="https://aaratech.s3.ap-south-1.amazonaws.com/icon1.webp" alt="" />
                        <Card.Subtitle>Push Quick Updates</Card.Subtitle>
                        <Card.Text>
                          This application is updated according to your target,
                          Whatever your updated application will be, it will
                          give you a notification keeping your security
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row className="mb-20">
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage src="https://aaratech.s3.ap-south-1.amazonaws.com/icon1.webp" alt="" />
                        <Card.Subtitle>Deliver High Performance</Card.Subtitle>
                        <Card.Text>
                          One of the biggest factors of Mobile Applications
                          every one wants to launch success Mobile application
                          on the basis of Mobile application performance.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage src="https://aaratech.s3.ap-south-1.amazonaws.com/icon1.webp" alt="" />
                        <Card.Subtitle>Bring Trust & Loyalty</Card.Subtitle>
                        <Card.Text>
                          This app takes care of the privacy of your users and
                          maintains their trust and integrity By which you get a
                          Positive way or path and you can create more apps.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage src="https://aaratech.s3.ap-south-1.amazonaws.com/icon1.webp" alt="" />
                        <Card.Subtitle>
                          Ensure Delightful Experience
                        </Card.Subtitle>
                        <Card.Text>
                          It will help to identify a way of assisting your
                          existing clients or customers. Your company feels good
                          and realistic. By this, you will get a unique
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="section-head style-4">
                  <h2 className="mb-30">
                    <span>
                      Here Are The Main Features Of The App Development
                    </span>
                  </h2>
                  <p>
                    Aara Technologies is a very famous company for building and
                    attracting Mobile Applications for their customers. As we
                    all know that in the current situation android app is having
                    a great future in market.
                  </p>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>App Development</span>
                      </h2>
                    </div>
                    <p className="text">
                      Every Business is having their own unique path for growth
                      in the market and has its own requirement as well. As we
                      know that we are an android application development
                      Company, We must understand the requirements of clients
                      and help them to build an application according to the
                      requirement of client business.
                    </p>
                    <p>
                      We are also building applications for enterprise-level
                      business. As per your business requirements, we can also
                      build an application for your in-house use only. Which
                      will be used for only your users .
                    </p>
                    <p>
                      We are building applications on the latest technologies
                      with advanced features.
                    </p>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/andriod-app-development.webp"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/headless-and-microservices.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>App Integration</span>
                      </h2>
                    </div>
                    <p className="mb-40 text">
                      This is not possible to build a lengthy process
                      application in a single mobile application so we need to
                      use third-party API to integrate that feature into the
                      Mobile Application. Aara Technologies is integrating
                      third-party API into the mobile application in very easy
                      ways such as like payment gateway, shipping methods, Chat
                      Systems, reporting Systems, Multiple currencies, and many
                      more. As per business requirements, Integration of Third
                      to Provide Best Services to Your Customers Can Give Your
                      Customers the Best of Convenience.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>Mobile Apps UI/UX Design</span>
                      </h2>
                    </div>
                    <p className="text">
                      Nowadays More people are using Mobile phones. It helps you
                      to make a good user’s experience. It will also give you
                      updation about the current market and current news.
                      Expectations of Mobile users are increasing day by day.
                      They only want to do all their work easily without facing
                      any complexity. UI/UX is helping to give a more attractive
                      design to your application.
                    </p>
                    <p>
                      UI/UX design also helps the user to increase their
                      knowledge and it is playing important role in any
                      application.
                    </p>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/andriod-app-ui.webp"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/andriod-app-ui.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>Mobile App Maintenance</span>
                      </h2>
                    </div>
                    <p className="text">
                      Every business requires a Mobile Application that can help
                      your business to save time and decrease your complexity
                      also. If I am talking about e-commerce business it is
                      important to have a good mobile application with advanced
                      features. And also be careful about one thing If you want
                      to make a good relationship with your client or app users
                      then you must be done update your business application
                      once a year according to the upgraded features.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>Widget Development</span>
                      </h2>
                    </div>
                    <p className="text">
                      Widget is used for small businesses. We can change the
                      Widget application to another. This will take an update in
                      a timely.
                    </p>
                    <p>
                      The widget is known by the name Graphical Control
                      Elements. This is a short way that we are using on any
                      application. It shows on the Home page Widget is used in
                      any application as an entry door. Some widget which is
                      used everywhere like: Calendar, Watch, Order Tracking etc
                    </p>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/e-commerce-app.webp"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
          <div className="mb-40 content1">
            <div className="container">
              <div className="mb-20 text-center section-head style-4">
                <h2 className="mb-20">
                  <span>
                    Technology
                  </span>
                </h2>
                <LazyLoadImage src="https://aaratech.s3.ap-south-1.amazonaws.com/app-technologies.webp" alt="" />
              </div>
            </div>
          </div>
          <div className="mb-40 conten">
            <div className="container">
              <div className="mb-20 text-center section-head style-4">
                <h2 className="mb-20">
                  <span>
                    Serving Middle East Countries With Our Android Apps
                  </span>
                </h2>
                <p>
                  Aara Technologies are providing application development
                  services globally ,Aara Technologies are providing all
                  services below mentioned countries:
                </p>
                <LazyLoadImage src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/Available-Countries.webp" alt="" />
              </div>
            </div>
          </div>
          <div className="mb-40 conten">
            <div className="container">
              <div className="mb-20 text-center section-head style-4">
                <h2 className="mb-20">
                  <span>
                    We Have Worked Across Different Verticals And Industries
                  </span>
                </h2>
                <Row className="mb-20">
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/food-grocery.svg"
                          className="mb-20"
                        />
                        <Card.Subtitle>Food & Grocery</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/retail.svg"
                          className="w-40 mb-20"
                        />
                        <br />
                        <Card.Subtitle>Retail</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/fmcg.svg"
                          className="w-40 mb-20"
                        />
                        <Card.Subtitle>FMCG</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/real-estate.svg"
                          className="w-40 mb-20"
                        />
                        <Card.Subtitle>Real Estate</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/construction.svg"
                          className="w-40 mb-20" alt=""
                        />
                        <Card.Subtitle>Construction</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/fashion-apparel.svg" alt="" />
                        <Card.Subtitle>Fashion & Apparel</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row className="mb-20">
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/hotel.svg"
                          className="w-40 mb-20"
                        />
                        <Card.Subtitle>Hotel</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/healthcare.svg"
                          className="w-40 mb-20"
                        />
                        <br />
                        <Card.Subtitle>Healthcare</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/telecom.svg"
                          className="w-40 mb-20"
                        />
                        <Card.Subtitle>Telecom</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/fintech.svg"
                          className="w-40 mb-20"
                        />
                        <Card.Subtitle>Fintech</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/manufacturing.svg"
                          className="w-40 mb-20"
                        />
                        <Card.Subtitle>Manufacturing</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/automotive.svg"
                          className="w-40 mb-20"
                        />
                        <Card.Subtitle>Automotive</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </div>
            </div>
          </div>

          <Faq />
        </section>
        <section
          className="bg-white portfolio-projects section-padding style-1"
        >
          <div className="container">
            <div className="mb-40 text-center section-head style-4">
              <h2 className="mb-20">
                Our  <span>Projects </span>
              </h2>
            </div>
            <section className="portfolio style-1">
              <div className="content">
                <div className="row mix-container">
                  {cards.map((card) => (
                    <div className="col-lg-4 mix security consultation">
                      <div className="text-center portfolio-card mb-30 scalmad">
                        <div className="img">
                          <LazyLoadImage src={card.image} alt="" style={{ objectFit: 'contain' }} />
                        </div>
                        <div className="info">
                          <h5>
                            <a href={card.link}>{card.name}</a>
                          </h5>
                          <small className="d-block color-main text-uppercase">
                            {card.type1}
                          </small>
                          <div className="tags">
                            <a href="#" className="me-1">
                              {card.tags}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Android;
