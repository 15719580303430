import axios from "axios";
import DOMPurify from "dompurify";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import Sharedbutton from "../../Sharelink";

import { axiosInstence, baseUrl } from "../../../Config/baseUrl";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Avatar, Divider } from "@mui/material";
const Blog = () => {


  const [commentsList, setCommentsList] = useState([])
  const [data1, setData2] = useState([]);
  const { slug, id } = useParams();
  const [data, setData] = useState([]);
  const blogData = () => {
    axios
      .post(
        `${baseUrl}/blog/blog-details`,
        { slug: slug },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((res) => {
        setData(res.data.blog_details[0]);
        console.log("Blog Details ==== : ", res.data.blog_details[0]);
      })
      .catch((err) => {
        console.log("ERROR: ====", err);
        alert(err);
      });
  };
  useEffect(() => {
    blogData();
  }, [slug]);
  console.log(data?.[0]?.id)
  const blogData2 = () => {
    axios
      .post(
        `${baseUrl}/blog/store-based-blog-list-api/`,
        { store_id: 1 },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((res) => {
        console.log("RESPONSE ==== : ", res.data?.blog_list);
        setData2(res.data?.blog_list);
      })
      .catch((err) => {
        console.log("ERROR: ====", err);
        alert(err.response.data.message);
      });
  };
  useEffect(() => {
    blogData2();
  }, []);

  const navigate = useNavigate();

  // blog commenting api

  const formik = useFormik({
    initialValues: {
      user_name: "",
      email: "",
      website_url: "",
      comment: "",
    },
    onSubmit: async (values) => {
      console.log("values=====", values)
      const reqBody = {
        post_id: data?.id,
        name: values.user_name,
        email: values.email,
        website_url: values.website_url,
        comment: values.comment,
        store_id: 8,
      };
      console.log("reqBody: ", reqBody)
      axiosInstence
        .post(
          //"http://192.168.1.188:9899/blog/post-comment-blog-api/",
          `${baseUrl}/blog/post-comment-blog-api/`,
          reqBody,
          {
            store_id: id,
            headers: {
              //Authorization: "c0951c5b80621b873302bc6d68669bdfe7876f73",
              Authorization: "Token e7b8852fe2ef05c740c18375cec4fbb722361f87",
              // "Access-Control-Allow-Origin": "*",
            },
          }
        )
        .then((res) => {
          console.log(res);
          alert(res.data.message);

        })
        .catch((err) => {
          console.log("ERROR: ====", err);
          alert(err.response.data.message);
        });
    },
  });

  const commentList = async (ids) => {

    const res = await axios.get(`${baseUrl}/blog/comment-list-blog-api/`, {
      params: { post_id: ids },
      headers: {
        //Authorization: "c0951c5b80621b873302bc6d68669bdfe7876f73",
        Authorization: "Token e7b8852fe2ef05c740c18375cec4fbb722361f87",
        // "Access-Control-Allow-Origin": "*",
      },
    })
    setCommentsList(res.data.data);
    console.log("Blog Comments: ", res.data.data);

  };

  useEffect(() => {

    data?.id && commentList(data?.id)

  }, [data?.id]);

  const sanitizedContent = DOMPurify.sanitize(data.content);
  return (
    <main className="portfolio-page style-1">
      <Helmet>
        <title>{data?.title}</title>
        <meta name="description" content={data?.sub_title} />
        <meta name="keywords" content={data?.meta_keyword} />
        <meta property="og:type" content="website" />
        {/* <meta property="og:url" content={data?.slug} /> */}
        <meta property="og:title" content={data?.meta_title} />
        <meta property="og:description" content={data?.meta_description} />
        <meta property="og:image" content={data?.images} />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={data?.slug} />
        <meta property="twitter:title" content={data?.meta_title} />
        <meta property="twitter:description" content={data?.meta_description} />
        <meta property="twitter:image" content={data?.images} />
      </Helmet>
      <section className="bg-white portfolio-projects section-padding style-1">
        <div className="container mad44">
          <div className="mb-40 section-head style-4 col-lg-8">
            <small className="title_small">Blogs</small>
            <h2 className="mb-20">
              <span> {data.meta_title} </span>
            </h2>
            <span className="mb-20"> {data.meta_title} </span>
            <br />
            <LazyLoadImage src={data.images} className="mb-30" />
            <div
              className="text-start"
              dangerouslySetInnerHTML={{ __html: sanitizedContent }}
            />
            <Sharedbutton url={window.location.href} />
            <form className="comment-form mt-50" onSubmit={formik.handleSubmit}>
              <p className="text-2xl font-bold">{commentsList?.length} Comments</p>
              {commentsList?.map((comment) => (

                <>
                  <span className="flex gap-2 my-10">
                    <Avatar src="broken" alt={comment.name} className="" />

                    <span className="flex justify-between w-full ">
                      <div className="flex flex-col px-3">
                        <p className="font-bold">{comment.name}</p>
                        <p className="text-xs">{comment.comment}</p>
                        <p className="text-sm "><strong className="text-base">Reply: </strong>{comment?.comment_replies[0]?.reply}</p>
                      </div>
                      <p className="text-xs">{comment?.created_at?.slice(0, 10)}</p>
                    </span>
                  </span>
                  <Divider />
                </>

              ))}
              <div className="mb-20 comment-form-fields">
                <h4 className="mb-20">Comment</h4>
                <input
                  type="text"
                  name="user_name"
                  id="user_name"
                  className="form-control"
                  placeholder="Name"
                  value={formik.values["user_name"]}
                  onChange={formik.handleChange}
                />
                <br />
                <input
                  type="text"
                  name="email"
                  id="email"
                  className="form-control"
                  placeholder="Email"
                  value={formik.values["email"]}
                  onChange={formik.handleChange}
                />
                <br />
                <input
                  type="text"
                  name="website_url"
                  id="website_url"
                  className="form-control"
                  placeholder="website"
                  value={formik.values["website_url"]}
                  onChange={formik.handleChange}
                />
                <br />

                <textarea
                  name="comment"
                  id="comment"
                  className="form-control"
                  placeholder="Comment"
                  value={formik.values["comment"]}
                  onChange={formik.handleChange}
                  rows="4"
                />
              </div>
              <div className="text-center col-lg-12">
                <input
                  type="submit"
                  className="btn rounded-pill blue5-3Dbutn hover-blue2 fw-bold md1"
                  value="Send Your Request"
                />
              </div>
            </form>
          </div>

          <div className="col-lg-4 mt-70 mad88 ">
            <h6 className="text-center mb-50">RECENT POST</h6>
            <div className="mb-50 ">
              {data1?.slice(0, 15).map((card) => (
                <>
                  <div
                    className="portfolio-card madcaf1"
                    onClick={() => {
                      navigate(`/blog/${card?.slug}`)
                    }}
                  >
                    <div className="img" style={{ width: "50%" }}>
                      <LazyLoadImage src={card.images} alt="" />
                    </div>
                    <div className="info1">
                      <h5>
                        <a href={card.link}>{card.title.slice(0, 40)}</a>
                      </h5>
                      <div className="text text1">
                        {card.sub_title.slice(0, 80)}
                      </div>
                    </div>
                  </div>
                  <hr />
                </>
              ))}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Blog;
