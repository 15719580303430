import React, { useEffect } from "react";
import { Accordion, Card, Col, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Faq from "../../FAQ";

const Laravel = (props) => {
  const cards = [
    {
      name: "RYR App",
      type1: "App development",
      image: 'https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/ryr-app.png',
      tags: "IOS app",
    },
    {
      name: "NRP App",
      type1: "App development",
      image: 'https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/nrp-app.png',
      tags: "IOS app",
    },
    {
      name: "Oemspa App",
      type1: "App DEVELOPEMNT",
      image: 'https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/oemspa-app.png',
      tags: "IOS app",
    },
  ];

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <Helmet>
        <title>{props.title}</title>
        <meta name="description" content={props.description} />
        <meta name="keywords" content={props.keywords} />
        <link rel="canonical" href="https://aaratechnologies.com/ios-app-development" />
      </Helmet>
      <main className="portfolio-page style-1">
        <section
          className="bg-white portfolio-projects section-padding style-1"
        >
          <div className="container">
            <div className="mb-40 text-center section-head style-4">
              <small className="title_small">IOS App Development</small>
              <h2 className="mb-20">
                <span>IOS App Development</span> Company
              </h2>
              <p>
                Aara technologies is the best iOS Application Development
                Company which is knowledge and adept iOS Developer to having
                hands on experience
              </p>
            </div>
          </div>
        </section>

        <section className="team section-padding style-6">
          <div className="mb-40 content1">
            <div className="container">
              <div className="mb-20 text-center section-head style-4">
                <small className="title_small">App Development</small>
                <h2 className="mb-20">
                  <span>IOS App Development</span> Company
                </h2>
                <p>
                  iOS Development services always require in-depth Knowledge
                  expertise and experience also to handling all complex problems
                  of the development process or development programming. In our
                  knowledge Aara technology is the best iOS Application
                  Development Company which is knowledge and adept iOS Developer
                  to having hands on experience in developing best iOS
                  Application for our client that are robust , compatible and
                  secure. We are very pride to be launching multiple successful
                  iOS Mobile Application in Market.
                </p>
                <LazyLoadImage
                  src="https://aaratech.s3.ap-south-1.amazonaws.com/andriod-app.webp"
                  className="imgmad"
                />
              </div>
            </div>
          </div>
          <div className="conten">
            <div className="container">
              <div className="mb-20 text-center section-head style-4">
                <h2 className="mb-20">
                  <span>Start Your Business</span> With Our{" "}
                  <span>IOS App Development Enterprise</span>
                </h2>
                <p>
                  Aara Technologies is already developed multiple successful iOS
                  Mobile Applications in Marketing. If you also want to do your
                  business online then Aara Technology is best company to launch
                  your business application according to your requirement with
                  advance Technology.
                </p>
                <LazyLoadImage src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/IOS-App-Development-Enterprise.webp" alt="" />
              </div>
            </div>
          </div>
        </section>
        <section className="about section-padding style-4">
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/Digital-World.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>Every Minute Matters In The Digital World</span>
                      </h2>
                    </div>
                    <p className="mb-40 text">
                      In current Digital world Even after developing thousands
                      of seamlessly working applications, we are still not
                      stopped. We still carry a sign of intense courage to build
                      prime quality applications with the latest technology and
                      clean features. We under our target audience and from and
                      update ourselves as per the latest updates in iOS and
                      other platforms.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
          <div className="content sec-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="info">
                  <div className="section-head style-4">
                    <h2 className="mb-30">
                      <span> Accomplish Your Business Goals</span> With{" "}
                      <span>IOS App Development Service</span>
                    </h2>
                  </div>
                  <Row className="mb-20">
                    <Col>
                      <Card>
                        <Card.Body>
                          <LazyLoadImage
                            src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/service/target-potential-users.svg"
                            className="mb-20"
                          />
                          <Card.Subtitle>Target Potential Users</Card.Subtitle>
                          <Card.Text>
                            Aara Technologies is the best place because Aara
                            Technology is target only potential User who can
                            give us business revenue.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card>
                        <Card.Body>
                          <LazyLoadImage
                            src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/service/increase-brand-exposure.svg"
                            className="mb-20"
                          />
                          <Card.Subtitle>Increase Brand Exposure</Card.Subtitle>
                          <Card.Text>
                            Aara Technologies will help you to increase your
                            brand awareness, by which consumers recognize and
                            remember your business.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card>
                        <Card.Body>
                          <LazyLoadImage
                            src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/service/updates.svg"
                            className="mb-20"
                          />
                          <Card.Subtitle>Push Quick Updates</Card.Subtitle>
                          <Card.Text>
                            Push notifications look like SMS text messages and
                            mobile alerts or a pop-up, but they only reach users
                            who have installed your application.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                  <Row className="mb-20">
                    <Col>
                      <Card>
                        <Card.Body>
                          <LazyLoadImage
                            src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/service/deliver-high-performance.svg"
                            className="mb-20"
                          />
                          <Card.Subtitle>
                            Deliver High Performance
                          </Card.Subtitle>
                          <Card.Text>
                            Aara Technologies are designing and developing
                            high-performance website and mobile applications.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card>
                        <Card.Body>
                          <LazyLoadImage
                            src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/service/bring-trust-loyalty.svg"
                            className="mb-20"
                          />

                          <Card.Subtitle>Bring Trust & Loyalty</Card.Subtitle>
                          <Card.Text>
                            Aara Technologies is building trust between the
                            company and the client. Aara technologies.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card>
                        <Card.Body>
                          <LazyLoadImage
                            src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/service/ensure-experience.svg"
                            className="mb-20"
                          />
                          <Card.Subtitle>
                            Load Development Environment
                          </Card.Subtitle>
                          <Card.Text>
                            Laravel is a perfect framework for web application
                            development and custom development.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png' alt="" className="bubble2" />
          </div>
          <div className="content sec-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="info">
                  <div className="section-head style-4">
                    <h2 className="mb-30">
                      <span>
                        Here Are The Main Features Of The App Development
                      </span>
                    </h2>
                  </div>
                </div>
                <p>
                  Aara Technologies are mentioned below all features for Mobile
                  Applications.
                </p>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png' alt="" className="bubble2" />
          </div>
          <div className="content sec-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="order-2 col-lg-6 order-lg-0">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span> App Development</span>
                      </h2>
                    </div>
                    <p className="mb-40 text">
                      Application Development is platform where we can create a
                      computer program or set of programs to perform the
                      different- different task for specific Business. Mobile
                      application is helping us to calculating monthly expenses
                      to scheduling sales report. Mobile application is also
                      help us in automating the process and increase efficiency.
                    </p>
                  </div>
                </div>
                <div className="col-lg-5 order-0 order-lg-2">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/andriod-app-development.webp"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png' alt="" className="bubble2" />
          </div>
          <div className="content trd-content bgmad">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/headless-and-microservices.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>App Integration </span>
                      </h2>
                    </div>
                    <p className="mb-40 text">
                      Application Integration is process which is enable
                      independently designed application to work together.
                      Application integration is a process to merging and
                      optimization of data and workflows between two disparate
                      software applications . Application Integration is process
                      which is allow to sharing of process and Business data
                      among the wide range of application.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
          <div className="content sec-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="order-2 col-lg-6 order-lg-0">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span> Mobile Apps UI/UX Design </span>
                      </h2>
                    </div>
                    <p className="text">
                      Mobile UX design is the design of user experiences for
                      hand-held and wearable devices. Designers create solutions
                      (typically applications) to meet mobile users' through the
                      unique way requirements and restrictions. UI( User
                      Interface) can allow to user to interact with each other.
                      Process of developing an UI include selecting a color
                      ,corporate Identity and the latest designing process.
                    </p>
                    <p>
                      The idea of UX is to provide the best experience for the
                      user with the ease of usage and the feel on how the user
                      gets an impact on establishing your business value or how
                      can users increase revenue . Creating a UX includes
                      defining the way a product operates and meets the
                      requirements of the users. UX must be clear, comfortable,
                      User-friendly and should work in the direction to turn in
                      to the customers or retain any customers.
                    </p>
                  </div>
                </div>
                <div className="col-lg-5 order-0 order-lg-2">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/andriod-app-ui.webp"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png' alt="" className="bubble2" />
          </div>
          <div className="content trd-content bgmad">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/andriod-app-ui.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>Mobile App Maintenance </span>
                      </h2>
                    </div>
                    <p className="mb-40 text">
                      Mobile app Maintenance is a process by which you can keep
                      your Mobile App compatible with the latest changes in
                      mobile technologies and enables the better user experience
                      and increased app productivity Mobile application
                      Maintenance includes Plans and budget which is depend on
                      fixing bugs, audit against the latest version of
                      technologies of iOS and Android .
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
          <div className="content sec-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="order-2 col-lg-6 order-lg-0">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>Widget Development </span>
                      </h2>
                    </div>
                    <p className="mb-40 text">
                      It is reusable user-interface components and it is key to
                      providing a rich user interface, Widget is an graphical
                      user interface that’s display specific information or it
                      is provide a specific way for a user interact with the
                      operation system and Mobile Applications.
                    </p>
                  </div>
                </div>
                <div className="col-lg-5 order-0 order-lg-2">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/e-commerce-app.webp"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png' alt="" className="bubble2" />
          </div>
        </section>
        <section className="team section-padding style-6">
          <div className="mb-40 conten">
            <div className="container">
              <div className="mb-20 text-center section-head style-4">
                <h2 className="mb-20">
                  <span>Technology</span>
                </h2>
                <LazyLoadImage
                  src="https://aaratech.s3.ap-south-1.amazonaws.com/app-technologies.webp"
                  className="mb-30"
                />
              </div>
            </div>
          </div>
          <div className="mb-40 conten">
            <div className="container">
              <div className="mb-20 text-center section-head style-4">
                <h2 className="mb-20">
                  <span>
                    Serving Middle East Countries With Our Android Apps
                  </span>
                </h2>
                <p>
                  Aara Technologies are providing application development
                  services globally ,Aara Technologies are providing all
                  services below mentioned countries:
                </p>
                <LazyLoadImage src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/Available-Countries.webp" alt="" />
              </div>
            </div>
          </div>
          <div className="mb-40 conten">
            <div className="container">
              <div className="mb-20 text-center section-head style-4">
                <h2 className="mb-20">
                  <span>
                    We Have Worked Across Different Verticals And Industries
                  </span>
                </h2>
                <Row className="mb-20">
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/food-grocery.svg"
                          className="mb-20"
                        />
                        <Card.Subtitle>Food & Grocery</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/retail.svg"
                          className="w-40 mb-20"
                        />
                        <br />
                        <Card.Subtitle>Retail</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/fmcg.svg"
                          className="w-40 mb-20"
                        />
                        <Card.Subtitle>FMCG</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/real-estate.svg"
                          className="w-40 mb-20"
                        />
                        <Card.Subtitle>Real Estate</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/construction.svg"
                          className="w-40 mb-20"
                        />
                        <Card.Subtitle>Construction</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/fashion-apparel.svg" alt="" />
                        <Card.Subtitle>Fashion & Apparel</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row className="mb-20">
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/hotel.svg"
                          className="w-40 mb-20"
                        />
                        <Card.Subtitle>Hotel</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/healthcare.svg"
                          className="w-40 mb-20"
                        />
                        <br />
                        <Card.Subtitle>Healthcare</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/telecom.svg"
                          className="w-40 mb-20"
                        />
                        <Card.Subtitle>Telecom</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/fintech.svg"
                          className="w-40 mb-20"
                        />
                        <Card.Subtitle>Fintech</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/manufacturing.svg"
                          className="w-40 mb-20"
                        />
                        <Card.Subtitle>Manufacturing</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/automotive.svg"
                          className="w-40 mb-20"
                        />
                        <Card.Subtitle>Automotive</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </section>

        <Faq />
        <section
          className="bg-white portfolio-projects section-padding style-1"
        >
          <div className="container">
            <div className="mb-40 text-center section-head style-4">
              <small className="title_small">IOS App Development</small>
              <h2 className="mb-20">
                Our  <span>Projects</span>
              </h2>
              <p>Here are given our some projects to know about our work.</p>
            </div>

            <section className="portfolio style-1">
              <div className="content">
                <div className="row mix-container">
                  {cards.map((card) => (
                    <div className="col-lg-4 mix security consultation">
                      <div className="text-center portfolio-card mb-30 scalmad">
                        <div className="img">
                          <LazyLoadImage src={card.image} alt="" style={{ objectFit: 'contain' }} />
                        </div>
                        <div className="info">
                          <h5>
                            <a href={card.link}>{card.name}</a>
                          </h5>
                          <small className="d-block color-main text-uppercase">
                            {card.type1}
                          </small>
                          <div className="tags">
                            <a href="#" className="me-1">
                              {card.tags}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Laravel;
