import { Button, Input, TextField } from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import gtag_report_conversion from "../../GoogleConversionFn";
import axios from "axios";
import img from "../images/map.png";
import MarkAsUnreadOutlinedIcon from "@mui/icons-material/MarkAsUnreadOutlined";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import NearMeRoundedIcon from "@mui/icons-material/NearMeRounded";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import bg from "../images/bg.png";
import msg from "../images/msg.png";
import back from "../images/bg1.png";
import txt from "../images/txt.png";
import mainpic from "../images/mainpic.webp";

import { Loader } from "../../Loader";
import { LazyLoadImage } from "react-lazy-load-image-component";
const Contactus = () => {
  const images = [
    {
      id: 1,
      text: "Lucknow, India",
      image: "https://aaratech.s3.ap-south-1.amazonaws.com/lucknow.png",
    },
    {
      id: 2,
      text: "New York, United States of America",
      image: "https://aaratech.s3.ap-south-1.amazonaws.com/newyark.png",
    },
    {
      id: 3,
      text: "Noida, India",
      image: "https://aaratech.s3.ap-south-1.amazonaws.com/noida.png",
    },
    {
      id: 4,
      text: "Dubai, United Arab Emirates",
      image: "https://aaratech.s3.ap-south-1.amazonaws.com/dubai.jpg",
    },
  ];
  const [data, setData] = useState(false);
  const navigate = useNavigate();
  const [isPending, setIsPending] = useState(false);
  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      mobile_no: "",
      customer_message: "",
      customer_type: "",
    },

    onSubmit: (values, action) => {
      setIsPending(true);
      const reqBody = new FormData();
      reqBody.append("first_name", values.first_name);
      reqBody.append("last_name", values.last_name);
      reqBody.append("email", values.email);
      reqBody.append("mobile_no", values.mobile_no);
      reqBody.append("customer_message", values.customer_message);
      reqBody.append("customer_type", "ONLINE/AARATECH");

      axios
        .post("https://b1.bhaaraterp.com/Api_data/add-contact-us/", reqBody, {
          headers: {
            Authorization: "c0951c5b80621b873302bc6d68669bdfe7876f73",
          },
        })
        .then((res) => {
          if (res.data.response_code === 200) {
            setIsPending(false);
            setData(res.data.message);
            alert(res.data.message);
            gtag_report_conversion("https://aaratechnologies.com/");
            window.location.href = `https://crmapp.bhaaraterp.com/whatsapp?template=contact_form&name=${values.first_name + " " + values.last_name
              }&mobile=${values.mobile_no}&email=${values.email
              }&callback_url=https://aaratechnologies.com/&access_token=b8bb5f83ea8a4ab4886394dbd00dbd97`;
            action.resetForm();
          }
        })
        .catch((err) => {
          setIsPending(false);
          alert(err.response.data.message);
        });
    },
  });
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="flex flex-col items-center justify-center ">
      {isPending && <Loader />}
      <div className="flex h-full w-full bg-gray-700 !relative !pt-20 md:!pt-0">
        <LazyLoadImage
          src={mainpic}
          alt=""
          className="lg:h-[600px] h-[400px] lg:w-full sm:!w-[100%] object-cover relative"
        />
        <div className="!absolute inset-0 flex items-center  bg-[#C7DCEF] bg-opacity-25">
          <div className="lg:h-72 lg:w-[40%] px-10">
            <p className="font-serif font-bold text-white lg:text-5xl sm:text-3xl lg:pt-11">
              Get In Touch
            </p>
            <p className="text-white lg:text-3xl sm:xl">
              We will contact you again after receiving your <br />
              request within 24 hours
            </p>
            <button
              className="!p-2 text-white border border-black rounded-lg md:p-0 md:w-56 md:h-16"
              onClick={() => navigate("/job-opening")}
            >
              Scheduae a demo
            </button>
          </div>
        </div>
      </div>
      <p className="flex items-center justify-center p-4 text-3xl font-bold underline bg-white">
        Our Location
      </p>
      <div className="relative flex items-center justify-center w-full px-4 font-bold py-28">
        <LazyLoadImage src={img} alt="" className="absolute top-0 left-0 z-0 object-cover w-full h-full" />
        <div className="z-10 grid items-center justify-center grid-cols-2 gap-8 md:grid-cols-4">
          {images.map((i, index) => (
            <div
              key={index}
              className="flex flex-col items-center self-start justify-center gap-4"
            >
              <div className="flex items-center justify-center mx-auto bg-black bg-opacity-75 rounded-full h-36 w-36 md:h-28 md:w-28 lg:h-36 lg:w-36">
                <LazyLoadImage
                  src={i.image}
                  alt=""
                  className="w-32 h-32 rounded-full md:h-24 md:w-24 lg:h-32 lg:w-32"
                />
              </div>
              <div className="!text-xs !md:text-sm flex items-center justify-center p-2 w-32 md:w-40 lg:w-48 bg-white shadow-md rounded-lg">
                <p className="text-center">{i.text}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="lg:flex md:gap-2 lg:flex-col  w-[100%] md:top-0 !relative">
        <p className="flex justify-center text-[#346B9D] md:pt-0 items-center underline text-3xl font-bold pt-4">
          Contact Us
        </p>
        <div className="flex justify-center w-full lg:!w-[100%] p-2 ">
          <LazyLoadImage
            src={bg}
            className="!absolute lg:left-2 -left-0.5 bottom-4  !rotate-45 !opacity-40 h-32 w-44 lg:h-80 lg:w-96"
          />

          <LazyLoadImage
            src={msg}
            className="!absolute lg:left-14 left-10 bottom-10 h-20 w-28 lg:!h-72 lg:!w-80"
          />

          <div></div>
          <div className="flex gap-5">
            <div className="flex flex-col gap-2 text-2xl font-semibold">
              <p className="">
                <MarkAsUnreadOutlinedIcon /> Email
              </p>

              <p className="">
                <CallOutlinedIcon /> Number
              </p>

              <p className="flex !mt-14">
                <NearMeRoundedIcon />
                Address
              </p>
            </div>
            <div className="flex flex-col text-xl">
              <p>info@aaratechnologies.com</p>

              <p>+91 9266667999</p>
              <p>++91 05224047511</p>

              <p>+91 8881108882</p>

              <p>
                <RoomOutlinedIcon />
                <Link className="text-sm text-gray-800" to={"https://maps.app.goo.gl/71rDLrvN1MLkNHR49"}>
                  C-379, Indira Nagar, Lucknow
                </Link>
              </p>

              <p>
                <RoomOutlinedIcon />
                <Link className="text-sm text-gray-800" to={'https://maps.app.goo.gl/QWsUisvQ1iZKAPM9A'}>
                  C-25 sector 8 Noida
                </Link>
              </p>
              <div className="flex">
                <RoomOutlinedIcon />
                <Link className="text-sm text-gray-800" to={"https://maps.app.goo.gl/71rDLrvN1MLkNHR49"}>
                  B-7 Real Earth City Lucknow
                  <br />
                  Sector-8, Noida
                </Link>
              </div>
              <div className="flex my-4">
                <RoomOutlinedIcon />
                <Link className="text-sm text-gray-800" to={"https://maps.app.goo.gl/fkC6eGrrB3D8DrFt5"}>
                  325 Third Ave 3rd Floor, New
                  <br />
                  York, NY 10017
                </Link>
              </div>
              <div className="flex">
                <RoomOutlinedIcon />{" "}
                <Link className="text-sm text-gray-800"
                  to={"https://www.google.com/maps/search/210+Opposite+Aster+Clinic+Jubilee+Complex,+Burdubai,+Makani+28163+94769,+Khalid+bin+Al+Waleed+Road,+PO+Box+66135,+Dubai,+United+Arab+Emirates/@25.2563894,55.2983123,17z/data=!3m1!4b1?entry=ttu&g_ep=EgoyMDI0MDkwOS4wIKXMDSoASAFQAw%3D%3D"}
                >
                  210 Opposite Aster Clinic Jubilee
                  <br />
                  Complex,
                  <br />
                  Burdubai, Makani 28163 94769,
                  <br />
                  Khalid bin Al Waleed Road,
                  <br />
                  PO Box 66135, Dubai, United
                  <br />
                  Arab Emirates
                </Link>
              </div>
            </div>
          </div>
          <div></div>
          <LazyLoadImage
            src={txt}
            alt=""
            className="flex h-12 w-14 lg:h-28 lg:w-36 !absolute  -rotate-12 md:top-4 top-12 md:right-16  right-12"
          />
        </div>
      </div>
      <div className="p-4 bg-[#C7DCEF] w-full h-[700px] md:h-[800px] lg:h-[900px] top-10 relative">
        <p className="flex items-center justify-center text-xl lg:text-2xl font-bold bg-[#C7DCEF] p-2">
          Please Fill the details
        </p>
        <div className="flex items-center justify-center">
          <LazyLoadImage
            src={back}
            className="w-[85rem] lg:w-[60rem] md:w-[73rem] md:h-[500px] h-[500px] lg:h-[600px] mx-auto"
          />
          <form
            onSubmit={formik.handleSubmit}
            className="lg:w-[25%] md:w-[50%]  w-[50%] mx-auto flex flex-col justify-center items-center absolute -translate-x-1/2 -translate-y-1/2 top-[50%] md:top-[45%] lg:top-[45%] left-1/2"
            method="post"
          >
            <div className="flex flex-col w-full gap-2">
              <input
                type="text"
                name="first_name"
                id="first_name"
                className="lg:form-control rounded shadow-xl py-3 px-2 bg-[#5492A6]"
                placeholder="First Name"
                value={formik.values["first_name"]}
                onChange={formik.handleChange}
              />
              <input
                type="text"
                name="last_name"
                id="last_name"
                className="lg:form-control rounded shadow-xl py-3 px-2 bg-[#5492A6]"
                placeholder="Last Name"
                value={formik.values["last_name"]}
                onChange={formik.handleChange}
              />
              <input
                type="text"
                name="email"
                id="email"
                className="lg:form-control rounded shadow-xl py-3 px-2 bg-[#5492A6]"
                placeholder="Email"
                value={formik.values["email"]}
                onChange={formik.handleChange}
              />
              <input
                type="text"
                name="mobile_no"
                id="mobile_no"
                className="lg:form-control rounded py-3 px-2 bg-[#5492A6] shadow-xl"
                placeholder="Mobile number"
                value={formik.values["mobile_no"]}
                onChange={formik.handleChange}
              />
              <textarea
                name="customer_message"
                id="customer_message"
                className="lg:form-control rounded shadow-xl py-3 px-2 bg-[#5492A6]"
                placeholder="Message"
                value={formik.values["customer_message"]}
                onChange={formik.handleChange}
                rows="2"
              />
            </div>
            <button
              type="submit"
              className="p-4 mt-3 text-sm font-medium text-center text-white rounded-lg bg-gradient-to-br from-green-400 to-blue-600 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-green-200 dark:focus:ring-green-800 w-36"
              value="Submit"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contactus;
