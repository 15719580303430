import axios from "axios";

//export const baseUrl = "http://192.168.1.188:9899";
export const baseUrl = "https://m1blog.aaragroups.com";

// export const baseUrl =
//   process.env.NODE_ENV === "production"
//     ? "https://m1blog.aaragroups.com"
export const axiosInstence = axios.create({ baseURL: baseUrl });
//     : "http://192.168.1.188:9898";
