import TagManager from "react-gtm-module";

export const pageViewEvent = ({ path, title = "page" }) => {
  TagManager.dataLayer({
    dataLayer: {
      event: "custom_page_view",
      pagePath: path,
      pageTitle: title,
    },
  });
};

export const clickEvent = ({
  click_action,
  click_section,
  click_text,
  click_type,
  click_url,
  page_path,
}) => {
  window.dataLayer.push({
    click_action,
    click_section,
    click_text,
    click_type,
    click_url,
    page_path,
    event: "custom_click_event",
  });
};
