import React, { useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";
const Shopify = (props) => {
  const cards = [
    {
      name: "Competetive Cyclist",
      type1: "Shopify Development",
      image: 'https://aaratech.s3.ap-south-1.amazonaws.com/shopifypro.PNG',
      link: "",
      description:
        "Competetive Cyclist website to sell different types of cycle.",
      tags: "Web development",
    },
    {
      name: "Lusso Lifestyle",
      type1: "Shopify Development",
      description:
        "Lusso Lifestyle website is to sell the shoe products.",
      image: 'https://aaratech.s3.ap-south-1.amazonaws.com/shopifypro2.PNG',
      link: "",
      tags: "Web development",
    },
    {
      name: "Petsandco",
      type1: "Shopify DEVELOPEMNT",
      description:
        "Petsandco website to sell different types of pet collections.",
      image: 'https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/petsandco.png',
      link: "",
      tags: "Web development",
    },
  ];

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <Helmet>
        <title>{props.title}</title>
        <meta name="description" content={props.description} />
        <meta name="keywords" content={props.keywords} />
        <link rel="canonical" href="https://aaratechnologies.com/shopify-development" />
      </Helmet>
      <main className="portfolio-page style-1">
        <section
          className="bg-white portfolio-projects section-padding style-1"
          id="MixItUpFCF1BF"
        >
          <div className="container">
            <div className="mb-40 text-center section-head style-4">
              <h2 className="mb-20">
                <span>Shopify CMS Development</span> Company
              </h2>
              <p>
                Shopify CMS Development is a very popular Ecommerce framework,
                Aara technologies is the experience in Shopify CMS Development
                Company.
              </p>
            </div>
          </div>
        </section>

        <section className="team section-padding style-6">
          <div className="mb-40 content1">
            <div className="container">
              <div className="mb-20 text-center section-head style-4">
                <small className="title_small">CMS Development</small>
                <h2 className="mb-20">
                  <span>Shopify CMS </span> Development
                </h2>
                <p>
                  Aara Technologies is providing the best services for Shopify
                  CMS Development. Our technical team can address complex
                  problems, and multi-technology, and multi-disciplinary Shopify
                  development store projects. Just hire Shopify experts with
                  flexible engagement models personalized to your business
                  needs.
                </p>
                <Row>
                  <Col>
                    <ul>
                      <li className="mb-20">Project Requirement</li>
                      <li className="mb-20">Business Analysis</li>
                      <li className="mb-20">UI/UX Design & Development</li>
                    </ul>
                  </Col>
                  <Col>
                    <ul>
                      <li className="mb-20">Testing& Quality Assurance</li>
                      <li className="mb-20">Support & Maintenance</li>
                    </ul>
                  </Col>
                </Row>
                <LazyLoadImage
                  src="https://aaratech.s3.ap-south-1.amazonaws.com/shopify.webp"
                  className="imgmad"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="about section-padding style-4">
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/Shopify-Online-Store.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        Why Should You Choose{" "}
                        <span>Shopify For Your Online Store Development?</span>
                      </h2>
                    </div>
                    <p className="mb-40 text">
                      There are multiple E-commerce platforms available in the
                      market, whether they are new in the market or established
                      in the international market.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
          <div className="content sec-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="section-head style-4">
                  <h2 className="mb-30">
                    Key Features Of <span>Shopify</span>
                  </h2>
                </div>
                <div className="order-2 col-lg-6 order-lg-0">
                  <div className="info">
                    <Row>
                      <Col>
                        <ul className="list-style-two">
                          <li className="mb-20 d-flex">
                            <CheckCircleOutlineIcon />
                            <h6>
                              We will develop Fully customizable website, online
                              store, and blog.
                            </h6>
                          </li>
                          <li className="mb-20 d-flex">
                            <CheckCircleOutlineIcon />
                            <h6 className="fw-bold">
                              Unlimited bandwidth, product inventory, and
                              customer data.
                            </h6>
                          </li>
                          <li className="mb-20 d-flex">
                            <CheckCircleOutlineIcon />
                            <h6>
                              Sell on new sales channels like Pinterest and
                              Amazon.
                            </h6>
                          </li>
                        </ul>
                      </Col>
                      <Col>
                        <ul className="list-style-two">
                          <li className="mb-20 d-flex">
                            <CheckCircleOutlineIcon />
                            <h6>All popular payment gateways supported.</h6>
                          </li>
                          <li className="mb-20 d-flex">
                            <CheckCircleOutlineIcon />
                            <h6 className="fw-bold">
                              Automate your fulfillment process with 3rd party
                              shipping apps.
                            </h6>
                          </li>
                          <li className="mb-20 d-flex">
                            <CheckCircleOutlineIcon />
                            <h6>24/7 award-winning customer support.</h6>
                          </li>
                        </ul>
                      </Col>
                    </Row>
                  </div>
                </div>
                <div className="col-lg-5">
                  <LazyLoadImage src="https://aaratech.s3.ap-south-1.amazonaws.com/shopify2.webp" alt="" />
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png' alt="" className="bubble2" />
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/shopify-app.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>Shopify App Development</span>
                      </h2>
                    </div>
                    <p className="text">
                      Shopify Apps give our merchants the ability to add
                      extended functionality or additional functionality and
                      custom integrations to their eCommerce stores with the
                      advanced version. Our blog features frequent content to
                      show you how to develop and sell apps in the Shopify App
                      Store with attractive graphics and advance features.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>Custom Shopify Development</span>
                      </h2>
                    </div>
                    <p className="mb-40 text">
                      Everyone who wants to build his/her business is unique and
                      so are the expectations of your customers. This is the
                      reason why you will probably need some special features
                      with advanced technology for your Shopify store. After
                      all, your brand revenues and success totally depend on the
                      experience that it delivers to the users/Clients. While
                      the quest may baffle you, the answer is as simple as
                      having a Shopify app which facilitates the advance
                      functionalities that you are looking for. As Shopify app
                      development specialists, we are capable of building custom
                      Shopify apps that match the needs of the client to
                      purchase goods and products. Whether you are looking for
                      an app that adds a new functionality to your Shopify
                      store, an app which customizes its UX, or an
                      integration-based Shopify app, we will have just the right
                      one to help you.
                    </p>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/Shopify_Custom.webp"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/Multi-Vendor-Marketplace.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>Multi Vendor Marketplace Development</span>
                      </h2>
                    </div>
                    <p className="text">
                      Multi Vendor Market Place is a platform for third-party
                      sellers or buyers where seller can sell their all products
                      in one place or buyers can buy all necessary goods in one
                      place. A multi-vendor marketplace is a website, which
                      hosts multiple vendors on a website and provides an
                      opportunity for various vendors to sell online on a single
                      platform. The owner of the website only manages the
                      website and the third-party vendors get to sell their
                      products online
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>Shopify Theme UI Development</span>
                      </h2>
                    </div>
                    <p className="text">
                      It is the combination of program and technology, HTML,
                      CSS, JavaScript Liquid, and image files into what exactly
                      shoppers or customers want to see exactly on screens. A
                      custom Shopify theme provides a user interface or
                      user-friendly designed specifically for your user needs
                      and your business objectives.
                    </p>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/shopify.webp"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
          <div className="content trd-content bgmad">
            <div className="container">
              <div className="row justify-content-between">
                <Card>
                  <Card.Body>
                    <Card.Title>
                      <h2>
                        We are ready to work and build the on-demand solution <br />for your business.
                      </h2>
                    </Card.Title>
                  </Card.Body>
                </Card>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
        </section>
        <section
          className="bg-white portfolio-projects section-padding style-1"
        >
          <div className="container">
            <div className="mb-40 text-center section-head style-4">
              <h2 className="mb-20">
                Our  <span>Projects</span>
              </h2>
              <p>Here are given our some projects to know about our work.</p>
            </div>
            <section className="portfolio style-1">
              <div className="content">
                <div className="row mix-container">
                  {cards.map((card) => (
                    <div className="col-lg-4 mix security consultation">
                      <div className="text-center portfolio-card mb-30 scalmad">
                        <div className="img">
                          <LazyLoadImage src={card.image} alt="" style={{ objectFit: 'contain' }} />
                        </div>
                        <div className="info">
                          <h5>
                            <a href={card.link}>{card.name}</a>
                          </h5>
                          <small className="d-block color-main text-uppercase">
                            {card.type1}
                          </small>
                          <div className="text">{card.description}</div>
                          <div className="tags">
                            <a href="#" className="me-1">
                              {card.tags}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Shopify;
