import React, { useEffect } from "react";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";
const Project = (props) => {
  const cards = [
    {
      name: "Delivery 1",
      type1: "App development",
      image: "https://aaratech.s3.ap-south-1.amazonaws.com/delivery-01.webp",
      link: "",
      tags: "Enterprise software",
    },
    {
      name: "Mengo retaurant",
      type1: "App DEVELOPMENT",
      image: "https://aaratech.s3.ap-south-1.amazonaws.com/delivery-02.webp",
      link: "",
      tags: "Enterprise software",
    },
    {
      name: "Grocery App",
      type1: "App DEVELOPEMNT",
      image: "https://aaratech.s3.ap-south-1.amazonaws.com/operator-1.webp",
      link: "",
      tags: "Enterprise software",
    },
  ];

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <Helmet>
        <title>{props.title}</title>
        <h1 name="h1" content={props.h1} h1 />
        <meta name="description" content={props.description} />
        <meta name="keywords" content={props.keywords} />
        <meta name="title" content=""></meta>
        <link rel="canonical" href="https://aaratechnologies.com/enterprise-Software-solutions" />
      </Helmet>
      <main className="portfolio-page style-1">
        <section className="bg-white portfolio-projects section-padding style-1">
          <div className="container">
            <div className="mb-40 text-center section-head style-4">
              <small className="title_small">Enterprise Software</small>
              <h2 className="mb-20">
                <span>Enterprise Software Solutions</span>
              </h2>
              <p>
                We are providing best Enterprise Software Solutions service.
              </p>
            </div>
          </div>
        </section>

        <section className="about section-padding style-4">
          <div className="content frs-content" id="about">
            <div className="container">
              <div className="row align-items-center justify-content-evenly">
                <div className="col-lg-5">
                  <div className="mb-20 img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/sldc.webp"
                      alt=""
                    />
                  </div>
                  <h2 className="mb-20 pt-60">Ready Go Enterprise Products:</h2>
                  <ul>
                    <li className="mb-20 d-flex align-items-center">
                      <ArrowRightIcon />
                      <h6>HR Payroll system(to manage employee engagement).</h6>
                    </li>
                    <li className="mb-20 d-flex align-items-center">
                      <ArrowRightIcon />
                      <h6>Work day Services</h6>
                    </li>
                    <li className="mb-20 d-flex align-items-center">
                      <ArrowRightIcon />
                      <h6>CRM</h6>
                    </li>
                    <li className="mb-20 d-flex align-items-center">
                      <ArrowRightIcon />
                      <h6>Sales Force Cosulting</h6>
                    </li>
                    <li className="mb-20 d-flex align-items-center">
                      <ArrowRightIcon />
                      <h6>Inventory management system.</h6>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2>
                        <span> Enterprise Software Solutions </span>
                      </h2>
                      <br />
                    </div>
                    <h5 className="fw bold">
                      Best Enterprise Software solutions Company
                    </h5>
                    <br />
                    <p>
                      AARA Technologies deals in ready to go live enterprise
                      solutions to their customers as well as with customized
                      product. These products are effective in managing in
                      customer's house process and to create time effective
                      environemnt with track systems with follow up interfaces.
                      We also provide upgrade to existing running solutions as
                      per needs and demands.
                    </p>
                    <p>
                      We offer clients the benefit of our expertise knowledge of
                      technology components, software test tools, enterprise
                      application integration techniques, and diffrent platform
                      environments to create optimized integrated robust systems
                      that give support client’s business and technology needs.
                    </p>
                    <ul>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6>
                          Customised Application Design & development of open
                          source based , using Linux, Apache, Perl, Python,
                          PHP/UNIX, Java/J2EE and Microsoft .NET technologies.
                        </h6>
                      </li>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6>
                          Customization deployment and integration of
                          third-party software, including in-depth knowledge of
                          leading application server terminology.
                        </h6>
                      </li>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6>
                          Design and development of content management systems
                          for corporate intranets and extranets.
                        </h6>
                      </li>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6>
                          Web-enablement of legacy applications with all
                          security features in build.
                        </h6>
                      </li>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6>
                          migration of applications to new operating systems.
                        </h6>
                      </li>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6>
                          Real estate inventory management and maintance systems
                          readiness.
                        </h6>
                      </li>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6>
                          Infrastructure design and maintance with all require
                          enterise solutions.
                        </h6>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt=""
              className="bubble"
            />
          </div>
          <div className="integration pt-30" data-scroll-index="3">
            <div className="text-center section-head style-4">
              <small className="title_small">Steps For Done</small>
              <h2 className="mb-10">
                Our <span> Working Process</span>{" "}
              </h2>
            </div>
            <div className="container">
              <div className="content">
                <div className="img">
                  <LazyLoadImage
                    src="https://aaratech.s3.ap-south-1.amazonaws.com/provisioning.png"
                    alt=""
                    className="mt-30"
                  />
                  <h3 className="text-center">1. Provisioning</h3>
                </div>
                <div className="img">
                  <h3 className="text-center">2. Strategy</h3>
                  <LazyLoadImage
                    src="https://aaratech.s3.ap-south-1.amazonaws.com/strategy.png"
                    alt=""
                    className="mt-30"
                  />
                </div>
                <div className="img">
                  <LazyLoadImage
                    src="https://aaratech.s3.ap-south-1.amazonaws.com/design%26developement.png"
                    alt=""
                    className="mt-30"
                  />
                  <h3 className="text-center">3. Development</h3>
                </div>
                <div className="img">
                  <h3 className="text-center">4. Go Live</h3>
                  <LazyLoadImage
                    src="https://aaratech.s3.ap-south-1.amazonaws.com/go-live.png"
                    alt=""
                    className="mt-30"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-white portfolio-projects section-padding style-1">
          <div className="container">
            <div className="mb-40 text-center section-head style-4">
              <small className="title_small">Enterprise Software</small>
              <h2 className="mb-20">
                Our <span>Projects</span>
              </h2>
              <p>Here are given some related technologies projects.</p>
            </div>
            <section className="portfolio style-1">
              <div className="content">
                <div className="row mix-container">
                  {cards.map((card) => (
                    <div className="col-lg-4 mix security consultation">
                      <div className="text-center portfolio-card mb-30 scalmad">
                        <div className="img">
                          <LazyLoadImage
                            src={card.image}
                            alt=""
                            style={{ objectFit: "contain" }}
                          />
                        </div>
                        <div className="info">
                          <h5>
                            <a href={card.link}>{card.name}</a>
                          </h5>
                          <small className="d-block color-main text-uppercase">
                            {card.type1}
                          </small>

                          <div className="tags">
                            <a href="#" className="me-1">
                              {card.tags}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Project;
