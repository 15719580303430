import React, { useEffect } from "react";
import { Accordion, Card, Col, Row } from "react-bootstrap";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Faq from "../../FAQ";

const Reactnative = (props) => {
  const cards = [
    {
      name: "OKM Web",
      type1: "WEBSITE DEVELOPMENT",
      image: 'https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/okm-web.png',
      tags: "CS Cart Development",
    },
    {
      name: "cripl Website",
      type1: "WEBSITE DEVELOPMENT",
      image: 'https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/cripl.png',
      tags: "CS Cart Development",
    },
    {
      name: "ZZZ Mart web",
      type1: "WEBSITE DEVELOPMENT",
      image: 'https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/zzzmart-web.png',
      tags: "CS Cart Development",
    },
  ];

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <Helmet>
        <title>{props.title}</title>
        <meta name="description" content={props.description} />
        <meta name="keywords" content={props.keywords} />
        <link rel="canonical" href="https://aaratechnologies.com/codeigniter-development" />
      </Helmet>
      <main className="portfolio-page style-1">
        <section
          className="bg-white portfolio-projects section-padding style-1"
        >
          <div className="container">
            <div className="mb-40 text-center section-head style-4">
              <h2 className="mb-20">
                <span>CS Cart Development</span> Company
              </h2>
              <p>
                CS Cart is a very popular Web application framework and Aara
                technologies is the finest CS Cart Development Company which is
                knowledge to having hands on experience.
              </p>
            </div>
          </div>
        </section>

        <section className="team section-padding style-6">
          <div className="mb-40 content1">
            <div className="container">
              <div className="mb-20 text-center section-head style-4">
                <small className="title_small">App Development</small>
                <h2 className="mb-20">
                  <span>CS Cart </span> Development
                </h2>
                <p>
                  Aara Technologies are taking third position on the CS- Cart
                  market place as a CS-Cart Development.
                </p>
                <LazyLoadImage
                  src="https://aaratech.s3.ap-south-1.amazonaws.com/cs-cart.webp"
                  className="imgmad"
                />
              </div>
            </div>
          </div>
          <div className="mb-40 conten">
            <div className="container">
              <div className="mb-20 text-center section-head style-4">
                <h2 className="mb-20">
                  <span>
                    CS-Cart Is Known For Shopping Cart Solution For Any
                    ECommerce Business
                  </span>
                </h2>
                <p>
                  Grapple with your online presence and customer reach? Don’t
                  worry! You must have to come to the right place . Having a
                  flexible and salable online store Is a must for all
                  businesses. Above all are depend on user-friendly platform is
                  what every person need, Everyone want a good and attractive
                  design.
                </p>
                <LazyLoadImage src="https://aaratech.s3.ap-south-1.amazonaws.com/cs-cart-2.webp" alt="" />
              </div>
            </div>
          </div>
        </section>
        <section className="about section-padding style-4">
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="section-head style-4">
                  <h2 className="mb-30">
                    <span>
                      CS-Cart Features That Need To Boot ECommerce Store
                    </span>
                  </h2>
                  <p>
                    CS - Cart is providing advance and latest features with
                    latest technology.By default it is include Mobile
                    application for his customer.An application is very easier
                    than the browsing website for online shopping. CS- Cart is
                    user friendly platform and anyone can access the backend and
                    perform all necessary operation. CS- Cart software now
                    offers a platform for all kind of online marketplace such
                    B2B or B2C etc.
                  </p>
                </div>
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/cs-cart-open-souurce.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>OpenSource Platform</span>
                      </h2>
                    </div>
                    <p className="mb-40 text">
                      CS-Cart is an open source platform and it can be available
                      for edit and modify according to the demand. We can do
                      changes according to the updation . It can be made easy
                      with this amazing features.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>
                          Ready To Get
                          <br /> Executed
                        </span>
                      </h2>
                    </div>
                    <p className="text">
                      If we are talking about CS-cart installation then CS-Cart
                      installation is very easy. There is no any special effort
                      required to operate this platform.
                    </p>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/cs-cart-execute.webp"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/cs-cart-security.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>Highly Secure</span>
                      </h2>
                    </div>
                    <p className="mb-40 text">
                      The security of the data is the most important and
                      considerable aspect of any online business. CS-Cart
                      continuously updates the version of the software after a
                      point of time to protect the platform from advanced cyber
                      crime.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>Retina Responsive</span>
                      </h2>
                    </div>
                    <p className="text">
                      CS-Cart software is providing a separate layout for Mobile
                      devices in order to provide the best view of the platform
                      on small screens. The software is compatible with all
                      sizes of screens including Mobiles and tablets.
                    </p>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/cs-ratina-responsive.webp"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/headless-and-microservices.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>International Reach</span>
                      </h2>
                    </div>
                    <p className="text">
                      It is internationally known for easy to use e-commerce
                      platforms. The business owners of all over the world are
                      using only the CS-Cart online platform for their online
                      business.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>Visual Layout Editor</span>
                      </h2>
                    </div>
                    <p className="text">
                      CS-Cart software is allowing facilities its customers with
                      visual layout editor so that the customer can directly
                      edit the layout from website.
                    </p>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/cs-cart-visual-editor.webp"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
          <div className="mb-40 content trd-content bgmad">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="section-head style-4">
                  <h2 className="mb-20">
                    <span>
                      Start Your Business With Our E-Commerce Plateform
                    </span>
                  </h2>
                </div>
                <p>
                  If you are running your store and want to move on digital
                  plateform then start your business with our E-Commerce
                  plateform.
                </p>
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/flutter-ui.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>CS-Cart App Development</span>
                      </h2>
                    </div>
                    <p className="text">
                      For developing an E-Commerce application for your business
                      is a pluse point to bring it digitally.In this rapidally
                      growing digital platform it is important to adopt latest
                      technology or with latest functionality. We at Aara
                      Technologies are skilled in CS-Cart Development to provide
                      customize e-commerce platform.
                    </p>
                    <h4 className="mb-20">
                      Have a look at our strategy for app development
                    </h4>
                    <ul>
                      <li className="mb-20 d-flex">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          We have been serving various big brands with our
                          applications. Nokia, HTC, Accenture, Tech Mahindra,
                          etc. are some of them.
                        </h6>
                      </li>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          We design a simple and navigating application for your
                          website.
                        </h6>
                      </li>
                      <li className="mb-20 d-flex">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          Our team always develop mobile-optimized website pages
                          for the small screen devices.
                        </h6>
                      </li>
                      <li className="mb-20 d-flex">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          We take care of a good response time for the developed
                          application for a better experience.
                        </h6>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
          <div className="mb-40 content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>CS-Cart Customization</span>
                      </h2>
                    </div>
                    <p className="text">
                      We must understand the need of Business, in this
                      fast-growing market. It is not enough to stay constant
                      with the old fashioned features. To have a good
                      appearance, a good CS-Cart development is required. We
                      offer on-demand customization beyond the features
                      available out of box in CS-Cart.We develop almost all kind
                      of custom solutions to fulfill business needs. The
                      businesses should adopt new features so as to upgrade
                      their stores as per the ongoing e-commerce scenarios and
                      customers’ demand..
                    </p>
                    <h4 className="mb-20">
                      Take a quick look at the customization services that we
                      provide in here
                    </h4>
                    <ul>
                      <li className="mb-20 d-flex">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          End to End CS-Cart Development Services.
                        </h6>
                      </li>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">App-End Customization Services.</h6>
                      </li>
                      <li className="mb-20 d-flex">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">eCommerce Shipping Methods.</h6>
                      </li>
                      <li className="mb-20 d-flex">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          Molding Payment Methods as per your store requirement.
                        </h6>
                      </li>
                      <li className="mb-20 d-flex">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          Designing your Stores by integrating best themes, and
                          so on.
                        </h6>
                      </li>
                      <li className="mb-20 d-flex">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">Other platform connectors</h6>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/cs-cart-customization.webp"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
          <div className="mb-40 content trd-content bgmad">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/cs-cart-theme.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>CS-Cart Theme Development</span>
                      </h2>
                    </div>
                    <p className="text">
                      It is important to have an attractive and appropriate
                      theme that suits your business ideas . The theme should be
                      very attractive and easily navigable as per customers'
                      needs. An appealing and optimized theme will retain more
                      customers.
                    </p>
                    <h4 className="mb-20">
                      Take a deep dive into our theme development parameters
                    </h4>
                    <ul>
                      <li className="mb-20 d-flex">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          CS-Cart is understood for its easy to go installation
                          process.
                        </h6>
                      </li>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          At Webkul, we attempt to deliver themes that are
                          highly responsive.
                        </h6>
                      </li>
                      <li className="mb-20 d-flex">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          It essential to develop themes for the shop that are
                          optimized, and that we do understand that.
                        </h6>
                      </li>
                      <li className="mb-20 d-flex">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          Optimization is in terms of Mobile SEO and definitely
                          Speed Optimization.
                        </h6>
                      </li>
                      <li className="mb-20 d-flex">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          To add to the present, the AB test/ conversion based
                          optimization also.
                        </h6>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
          <div className="mb-40 content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>CS-Cart Marketplace Development</span>
                      </h2>
                    </div>
                    <p className="text">
                      You are planning to develop an Marketplace for your
                      e-Commerce business? Then I am here to assist you
                      developing your e-Commerce marketplace. I assure you to
                      that we will develop more attractive e-commerce platform
                      according to the customers need. Why not the other
                      e-commerce platform for online marketplace development.
                      Then we might wish to remind you that CS-Cart is
                      understood for its easy to go installation process. it’s
                      completely open-source, so just convey your requirements
                      and we’ll create your dream store. A responsive online
                      e-commerce system comes handy with CS-Cart.
                    </p>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/e-commerce-app.webp"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
        </section>
        <section className="team section-padding style-6">
          <div className="mb-40 conten">
            <div className="container">
              <div className="mb-20 text-center section-head style-4">
                <h2 className="mb-20">
                  <span>Technology</span>
                </h2>
                <LazyLoadImage
                  src="https://aaratech.s3.ap-south-1.amazonaws.com/app-technologies.webp"
                  className="mb-30"
                />
              </div>
            </div>
          </div>
          <div className="mb-40 conten">
            <div className="container">
              <div className="mb-20 text-center section-head style-4">
                <h2 className="mb-20">
                  <span>
                    Serving Middle East Countries With Our Android Apps
                  </span>
                </h2>
                <p>
                  We are providing services not only in India we are covering
                  middle east area also like UAE, Abu- Dhabi, Oman,and covering
                  all golf countries. We are not covering only Oil and Gas
                  industry We are covering all industry and serving best
                  solution according to the requirements.
                </p>
                <LazyLoadImage src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/Available-Countries.webp" alt="" />
              </div>
            </div>
          </div>
          <div className="mb-40 conten">
            <div className="container">
              <div className="mb-20 text-center section-head style-4">
                <h2 className="mb-30">
                  <span>
                    We Have Worked Across Different Verticals And Industries
                  </span>
                </h2>
                <Row className="mb-20">
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/food-grocery.svg"
                          className="mb-20"
                        />
                        <Card.Subtitle>Food & Grocery</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/retail.svg"
                          className="w-40 mb-20"
                        />
                        <br />
                        <Card.Subtitle>Retail</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/fmcg.svg"
                          className="w-40 mb-20"
                        />
                        <Card.Subtitle>FMCG</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/real-estate.svg"
                          className="w-40 mb-20"
                        />
                        <Card.Subtitle>Real Estate</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/construction.svg"
                          className="w-40 mb-20"
                        />
                        <Card.Subtitle>Construction</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/fashion-apparel.svg" alt="" />
                        <Card.Subtitle>Fashion & Apparel</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row className="mb-20">
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/hotel.svg"
                          className="w-40 mb-20"
                        />
                        <Card.Subtitle>Hotel</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/healthcare.svg"
                          className="w-40 mb-20"
                        />
                        <br />
                        <Card.Subtitle>Healthcare</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/telecom.svg"
                          className="w-40 mb-20"
                        />
                        <Card.Subtitle>Telecom</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/fintech.svg"
                          className="w-40 mb-20"
                        />
                        <Card.Subtitle>Fintech</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/manufacturing.svg"
                          className="w-40 mb-20"
                        />
                        <Card.Subtitle>Manufacturing</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/automotive.svg"
                          className="w-40 mb-20"
                        />
                        <Card.Subtitle>Automotive</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </section>

        <Faq />
        <section
          className="bg-white portfolio-projects section-padding style-1"
        >
          <div className="container">
            <div className="mb-40 text-center section-head style-4">
              <h2 className="mb-20">
                Our  <span>Projects </span>
              </h2>
              <p>Here are given our some projects to know about our work.</p>
            </div>
            <section className="portfolio style-1">
              <div className="content">
                <div className="row mix-container">
                  {cards.map((card) => (
                    <div className="col-lg-4 mix security consultation">
                      <div className="text-center portfolio-card mb-30 scalmad">
                        <div className="img">
                          <LazyLoadImage src={card.image} alt="" style={{ objectFit: 'contain' }} />
                        </div>
                        <div className="info">
                          <h5>
                            <a href={card.link}>{card.name}</a>
                          </h5>
                          <small className="d-block color-main text-uppercase">
                            {card.type1}
                          </small>
                          <div className="tags">
                            <a href="#" className="me-1">
                              {card.tags}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Reactnative;
