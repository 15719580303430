import "./App.css";

import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import About from "./Pages/AboutUs";
import Mobileappdevelopment from "./Pages/Services/Mobileappdevelopment";
import Ecommercewithcms from "./Pages/Services/ecommercewithcms";
import Websitedesignanddevelopment from "./Pages/Services/Websitedesignanddevelopment";
import Cloudbasedsoftware from "./Pages/Services/Cloudbasedsoftware";
import Deliveryapp from "./Pages/Services/Deliveryapp";
import APIdevelopment from "./Pages/Services/Apidevelopment";
import Customcrmdevelopment from "./Pages/Services/Customcrmdevelopment";
import Customhrmdevelopment from "./Pages/Services/Customhrmdevelopment";
import Automatedremindersystem from "./Pages/Services/Automatedremindersystem";
import Mlm from "./Pages/Services/Mlm";
import Ecommerceappdevelopment from "./Pages/Services/Ecommerceappdevelopment";
import Enterprisesoftwaresolution from "./Pages/Services/Enterprisesoftwaresolution";
import Softwaredevelopment from "./Pages/Services/Software development";
import Amcforwebsiteandmobileapp from "./Pages/Services/Amcforwebsiteandmobileapp";
import Ecommercemarketplacedevelopment from "./Pages/Services/Ecommercemarketplacedevelopment";
import Customerpdevelopment from "./Pages/Services/Customerpdevelopment";
import Customsoftwaredevelopment from "./Pages/Services/Customsoftwaredevelopment";
import Learningmanagementsystem from "./Pages/Services/Learningmanagementsystem";
import Uiuxdesign from "./Pages/Services/Uiuxdesign";
import Magentodevelopment from "./Pages/Technologies/Magentodevelopment";
import Woocommercedevelopment from "./Pages/Technologies/Woocommercedevelopment";
import Prestashopdevelopment from "./Pages/Technologies/Prestashopdevelopment";
import Odoodevelopment from "./Pages/Technologies/Odoodevelopment";
import Laraveldevelopment from "./Pages/Technologies/Laraveldevelopment";
import Iosappdevelopment from "./Pages/Technologies/Iosappdevelopment";
import Flutterdevelopment from "./Pages/Technologies/Flutterdevelopment";
import Iotappdevelopment from "./Pages/Technologies/Iotappdevelopment";
import Reactnativedevelopment from "./Pages/Technologies/Reactnativedevelopment";
import Shopifydevelopment from "./Pages/Technologies/Shopifydevelopment";
import Cscartdevelopment from "./Pages/Technologies/Cscartdevelopment";
import Codeigniterdevelopment from "./Pages/Technologies/Codeigniterdevelopment";
import Pythondevelopment from "./Pages/Technologies/Pythondevelopment";
import Aiarproduct from "./Pages/Technologies/Aiarproduct";
import Angulardevelopment from "./Pages/Technologies/Angulardevelopment";
import Nodejsdevelopment from "./Pages/Technologies/Nodejsdevelopment";
import Androidappdevelopment from "./Pages/Technologies/Androidappdevelopment";
import Opencartdevelopment from "./Pages/Technologies/Opencartdevelopment";
import Layout from "./Layout";
import ContactUs from "./Pages/ContactUs";
import Project from "./Pages/Project";
import Blog from "./Pages/Blog";
import Job from "./Pages/Jobopening";
import Privacy from "./Pages/Privacypolicy";
import Page from "./Pages/Blog/Page";
import Page404 from "./Pages/pagenotfound";
import Signin from "./Authentication/SignIn";
import News from "./Pages/News";
import Detailnews from "./Pages/News/Detailnews";
import ConsentBanner from "./ConsentBanner";
import Customer from "./Pages/Services/our-cousmoter";
import About1 from "./Pages/Services/our-cousmoter";
import TryCode from "./try";

function App() {
  return (
    <>
      <BrowserRouter>
        <ConsentBanner />
        <Routes>
          <Route path="/signin" element={<Layout component={<Signin />} />} />
          <Route
            path="/"
            element={
              <Layout
                component={
                  <Home
                    title="Software & Mobile Apps Development Services | Aara Technologies"
                    description="Aara Technologies is Trusted and leading software and mobile app developement company in Noida, India. We have team of expert developers to build Software, CRM, ERP, AI, Digital Transformation, Cloud Operation & mobile apps."
                    keywords="Best Website Development Company in Lucknow, Best Mobile App Development Company in Lucknow, Software Development Company in Lucknow, Software Development company in NCR, best Software Development Company in Noida, E-commerce website development company in Lucknow."
                  />
                }
              />
            }
          />
          <Route
            path="/about"
            element={
              <Layout
                component={
                  <About
                    title="About The Leading Software Company - Aara Technologies"
                    description="Aara Technologies - Best website design & development company specializing in responsive and parallax website designing in Lucknow (India), Africa & Europe."
                    keywords="Leading Software Company In India"
                  />
                }
              />
            }
          />
          <Route
            path="/contact"
            element={
              <Layout
                component={
                  <ContactUs
                    title="Contact Us | Best Software Development Company - Aara Technologies"
                    description="Contact Us right away to discuss your software application development projects with the best leading software company in India."
                    keywords="Best Software Development Company, Website Designing & Development"
                  />
                }
              />
            }
          />
          <Route
            path="/ourproject"
            element={
              <Layout
                component={
                  <Project
                    title="Projects and Portfolio of Aara technologies"
                    description="Check the latest project and portfolios competed By Aara Technologies. We have successfully completed more than 650 projects so far now."
                    keywords="Best Website Development Company in Lucknow, Best Mobile App Development Company in Lucknow, Software Development Company in Lucknow, Software Development company in NCR, best Software Development Company in Noida, E-commerce website development company in Lucknow."
                  />
                }
              />
            }
          />
          <Route
            path="/mobile-app-development"
            element={
              <Layout
                component={
                  <Mobileappdevelopment
                    title="Mobile App Development Company For Android & IOS"
                    h1="Mobile App Development Company."
                    description="Do you find a mobile app development company for IOS? Aara Technologies offers innovative solutions to bring your app ideas to life. Contact us today!"
                    keywords="Mobile app development company in delhi,top mobile app development company,top mobile app development company in india,mobile app development company in noida,best mobile app development company."

                  />

                }
              />

            }
          />
          <Route
            path="/e-commerce-app-with-cms"
            element={
              <Layout
                component={
                  <Ecommercewithcms
                    title="Professional CMS Development Services In India  |CMS Development Services"
                    h1="eCommerce App with CMS."
                    description="Get Expert CMS development services for efficient content management solutions. We help to maintain, and manage your online content."
                    keywords="Cms mobile app,headless cms mobile app,cms mobile app development company,cms mobile app development company in noida,cms mobile app development company in india."
                  />
                }
              />
            }
          />
          <Route
            path="/website-design-and-development"
            element={
              <Layout
                component={
                  <Websitedesignanddevelopment
                    title="Top Web design and Development Services | Aara Technologies| Best Website Design & Development Company."
                    description="Elevate your digital presence with Aara Technologies's expert website design and development services. We create stunning, user-friendly websites that captivate your audience."
                    keywords="Website development company in india,website development company india,website development company in delhi,website development company in noida,best website development company in india,website development company in lucknow,website design & development,website design & development services,website design & development compan."
                  />
                }
              />
            }
          />
          <Route
            path="/cloud-based-open-source-software-development"
            element={
              <Layout
                component={
                  <Cloudbasedsoftware
                    title="Best Cloud Based Software Development Company- Aara Technologies | Customized Cloud Based Open Source Software Development"
                    h1="Customized Cloud Based Open Source Software Development."
                    description="Transform your business with cutting-edge cloud-based software solutions. Our experienced team delivers scalable, secure, and innovative applications, leveraging the full potential of cloud technologies. "
                    keywords="cloud based software company,cloud based software services,cloud based software development,cloud-based application development,cloud-based application development in india,cloud-based application development in noida."
                  />
                }
              />
            }
          />
          <Route
            path="/delivery-app"
            element={
              <Layout
                component={
                  <Deliveryapp
                    title="Best Delivery App Development Company - Aara Technologies"
                    description="Are you looking for a Delivery App? Aara Technologies is the top rated Delivery App Development company that offers quality Delivery App Development solutions."
                    keywords="Best Delivery App Development Company, Delivery App Development Company, Best Delivery App Development."
                  />
                }
              />
            }
          />
          <Route
            path="/api-development"
            element={
              <Layout
                component={
                  <APIdevelopment
                    title="API development Services for CMS, ERP CRM, Mobile App"
                    h1="API Development company."
                    description="Get custom API development services in India for e-commerce, marketplaces, mobile apps, POS, headless microservices, CMS, ERP, and CRM. Call us now."
                    keywords="Api development,api development company,custom api development company,API development company in india,API development company in noida,api development services,api development service provider."
                  />
                }
              />
            }
          />
          <Route
            path="/crm-development"
            element={
              <Layout
                component={
                  <Customcrmdevelopment
                    title="CRM Development Company In India | Aara Technologies"
                    description="Get to enhance your business efficiency with CRM solutions. We are dedicated to offering the Best CRM Development services in India. Contact us today."
                    keywords="Best crm development company, top crm development company, crm development company, custom crm software company, crm software design company, best crm for small business, crm software at a budget price, best cloud crm service seller, crm software company near me, crm integration services, affordable crm design company, best crm development company, customized crm development, crm software development firm, powerful sales crm platform, India's Best Sales crm company, custom crm development company ."
                  />
                }
              />
            }
          />
          <Route
            path="/hrm-development"
            element={
              <Layout
                component={
                  <Customhrmdevelopment
                    title="HRM Development Company In India | Custom HR Process"
                    h1="Custmoized HRM Software Development."
                    description="Unlock the full potential of your HR operations with Aara Technologies, the industry's top HRM software development company."
                    keywords="Aara Technologies is a leading HRM development company. We offer a range of services that streamline your HR process with custom solutions for efficient employee management."
                  />
                }
              />
            }
          />
          <Route
            path="/automated-reminder-system"
            element={
              <Layout
                component={
                  <Automatedremindersystem
                    title="Best Automated Reminder System Development Company - Automated Reminder System"
                    h1="Automated Reminder System."
                    description="Revolutionizing Productivity with Best-in-Class Reminder Software Development with Aara Technologies."
                    keywords="Reminder software,Reminder Software development company,online reminder software,Appointment Reminder software."
                  />
                }
              />
            }
          />
          <Route
            path="/mlm"
            element={
              <Layout
                component={
                  <Mlm
                    title="Mlm Software Development Company | Multilevel Marketing Software Development"
                    h1="Mlm Software Development Company."
                    description="Aaratechnologies: A trusted name in MLM software development, renowned for crafting exceptional solutions that empower businesses to thrive and dominate the network marketing industry."
                    keywords="Mlm software company,mlm software company in india,mlm software company in delhi,MLM software development company in Noida,mlm software company in noida,mlm software development company,mlm software development company in delhi,mlm software company in lucknow,mlm software,mlm service provider, mlm service provider noida, mlm service provider in noida, multi level marketing."
                  />
                }
              />
            }
          />
          <Route
            path="/ecommerce-app-development"
            element={
              <Layout
                component={
                  <Ecommerceappdevelopment
                    title="eCommerce App Development Services in India | Aara Technology"
                    h1="eCommerce App Development Company."
                    description="Get custom eCommerce app development solutions for Android and iOS with Aara Tech. We are a leading Ecommerce development company in India."
                    keywords="Ecommerce app development company,ecommerce app development company in india,top ecommerce app development company,ecommerce app development,ecommerce app development services,ecommerce app development agency,eCommerce App Development company in noida,eCommerce App Development company in lucknow."
                  />
                }
              />
            }
          />
          <Route
            path="/software-development"
            element={
              <Layout
                component={
                  <Softwaredevelopment
                    title="Best Software Development Company In Noida | Best Software Development Company "
                    h1="Best Software Development Company."
                    description="Choose Aara Technologies, the leading software development company, for innovative solutions tailored to your needs."
                    keywords="Software development company in india,software development company india,software development company,software development company in noida,top software development company,best software development company,best software development company in lucknow."
                  />
                }
              />
            }
          />
          <Route
            path="/enterprise-Software-solutions"
            element={
              <Layout
                component={
                  <Enterprisesoftwaresolution
                    title="Best Enterprise software development company| Aara Technologies - Enterprise Software Solutions"
                    h1="Enterprise Software Solutions."
                    description="Unlock your enterprise's full potential with Aara Technologies, the trusted software development company. Tailored solutions for lasting success."
                    keywords="Enterprise software solutions,enterprise software development,enterprise software development company,enterprise software development services,enterprise software development company in india,enterprise software development company in noida,enterprise software development company in lucknow."
                  />
                }
              />
            }
          />
          <Route
            path="/amc-for-website-and-mobile-app"
            element={
              <Layout
                component={
                  <Amcforwebsiteandmobileapp
                    title="Best AMC For Website And Mobile App - AMC for website & Mobile app"
                    h1="AMC for website & Mobile app."
                    description="Get the best AMC Software for your website and mobile app with Aara Technologies."
                    keywords="Amc software,amc software solutions,amc software company,amc software company in india,amc software development company in noida,amc software development company in India."
                  />
                }
              />
            }
          />
          <Route
            path="/e-commerce-web-development"
            element={
              <Layout
                component={
                  <Ecommercemarketplacedevelopment
                    title="Best eCommerce Web Development Company - E-Commerce Web Development"
                    h1="E-Commerce Web Development"
                    description="Unlock E-commerce Excellence with Aara Technologies - Your Trusted Partner for Top-tier Web Development Solutions. Elevate your online business with our seamless and innovative E-commerce services."
                    keywords="E-commerce web development company,e-commerce web development,e-commerce web development services,e-commerce web development company in india."
                  />
                }
              />
            }
          />
          <Route
            path="/erp-development"
            element={
              <Layout
                component={
                  <Customerpdevelopment
                    title="ERP Software Development Company in India | Custom ERP"
                    h1="ERP Software Development Company."
                    description="Empower business with our custom ERP software. We provide ERP software development services in India. We listen to your needs and requirements."
                    keywords="Erp software development services,erp software development,custom erp software,erp software development company,erp software development company in india,erp software development company in noida,erp software development company  in lucknow."
                  />
                }
              />
            }
          />
          <Route
            path="/customized-software-development"
            element={
              <Layout
                component={
                  <Customsoftwaredevelopment
                    title="Best Customized Software Development Company - Aara Technologies"
                    description="Aara Technologies is the Best Customized Software Development Company offering software design and application development. We build customized software."
                    keywords="Top leading software development company, Best Customized Software Development Company, Customized Software Development."
                  />
                }
              />
            }
          />
          <Route
            path="/lms"
            element={
              <Layout
                component={
                  <Learningmanagementsystem
                    title="Best Learning Management Systems Company (LMS) - LMS Software Development Company"
                    h1="LMS Software Development Company."
                    description="Empowering Modern Learning:  Our Unmatched LMS Software Development Services for Seamless Education and Training Solutions."
                    keywords="Earning management system software,best learning management system software,lms software in india,best lms software in india,top lms software in india."
                  />
                }
              />
            }
          />
          <Route
            path="/ui-ux-design"
            element={
              <Layout
                component={
                  <Uiuxdesign
                    title="Best UI/UX Design Company - Aara Technologies"
                    description="Aara Technologies is the best UI/UX design company, that specializes in user research. We help businesses create unique user interfaces & experience."
                    keywords="Best UI/UX design company, UI/UX design company."
                  />
                }
              />
            }
          />

          <Route
            path="/magento-development"
            element={
              <Layout
                component={
                  <Magentodevelopment
                    title="Magento Development Company | Best Magento Development- Aara Technologies"
                    description="Best Magento Software Development Company, Aara Technologies offers scalable Magento eCommerce Development & providing excellent Services."
                    keywords="Best magento development company, magento development company, top magento development company, magento multi level marketing, magento software development, magento software development company, magento app development, magento mobile app development company, magento open source development, magento application development, magento development company, magento development firm, magento developer company."
                  />
                }
              />
            }
          />
          <Route
            path="/woocommerce-development"
            element={
              <Layout
                component={
                  <Woocommercedevelopment
                    title="Best Woocommerce Development Company - Aara Technologies"
                    description="Aara Technologies is leading WooCommerce Development Company. We incorporate custom functionalities into your eCommerce store to match your Business."
                    keywords="Best Woocommerce Development Company, Woocommerce Development Company, Top Woocommerce Development Company."
                  />
                }
              />
            }
          />
          <Route
            path="/prestashop-development"
            element={
              <Layout
                component={
                  <Prestashopdevelopment
                    title="Best Prestashop Development Company - Aara Technologies"
                    description="Aara Technologies is a leading Prestashop Development Company. We provide Custom and Responsive Prestashop eCommerce stores at affordable prices."
                    keywords="Best Prestashop Website Development Company, Prestashop Website Development Company, Top Prestashop Website Development Company, Prestashop Development."
                  />
                }
              />
            }
          />
          <Route
            path="/odoo-development"
            element={
              <Layout
                component={
                  <Odoodevelopment
                    title="Odoo Development Company | Custom Odoo ERP development - Aara Technologies"
                    description="Best Odoo Development Company, Aara Technologies provides kinds of odoo development services including SAAS, POS, Migrations, and Mobile apps."
                    keywords="Odoo open source erp software development, best odoo open source erp software development, top odoo open source erp software development, odoo development company, odoo development company in lucknow, odoo development company in noida."
                  />
                }
              />
            }
          />
          <Route
            path="/laravel-development"
            element={
              <Layout
                component={
                  <Laraveldevelopment
                    title="Best Laravel Development Company | Laravel development - Aara Technologies"
                    description="Best Laravel Development Company in Noida, Lucknow, Delhi, and India offering complete Laravel Development Framework services. We build secure and scalable Frameworks."
                    keywords="Best laravel development company, top laravel application development company, laravel application development company, laravel development, laravel development company in lucknow, laravel development company in noida."
                  />
                }
              />
            }
          />
          <Route
            path="/ios-app-development"
            element={
              <Layout
                component={
                  <Iosappdevelopment
                    title="Best iOS App Development Company - Aara Technologies"
                    description="Aara Technologies is leading iOS App Development Company creating fully feature-rich iOS apps. Hire our iOS app developers for iOS application development."
                    keywords="Best iOS App Development Company, Top iOS App Development Company, iOS App Development Company."
                  />
                }
              />
            }
          />
          <Route
            path="/flutter-development"
            element={
              <Layout
                component={
                  <Flutterdevelopment
                    title="Best Flutter Development Company | Flutter app development - Aara Technologies"
                    description="If you want to start your project with an industry Best Flutter Development Company. Aara Technologies is the right option for flutter development."
                    keywords="Best Flutter Development Company, Top Flutter App Development Company, Best Flutter App Development Company, Flutter Development."
                  />
                }
              />
            }
          />
          <Route
            path="/iot-development"
            element={
              <Layout
                component={
                  <Iotappdevelopment
                    title="Best IoT Development Company | Aara Technologies"
                    description="Aara Technologies is a trusted IoT Development Company that builds IoT mobile apps for wearables & smart devices. We clearly understand the need for IoT."
                    keywords="Best IoT Development Company, Top Internet of Things (IoT) Development Company,  Internet of Things (IoT) Development Company, Best IoT Development Company."
                  />
                }
              />
            }
          />
          <Route
            path="/react-native-development"
            element={
              <Layout
                component={
                  <Reactnativedevelopment
                    title="React Native Development Company In India | Aara Technologies"
                    description="Aara Technologies is a leading React Native Development Company delivering App Solutions. We offer Mobile App Development with React Native."
                    keywords="Do you find a React Native development company? Aara Technologies offers expert React Native solutions to build high-performance mobile apps. Call us."
                  />
                }
              />
            }
          />
          <Route
            path="/opencart-development"
            element={
              <Layout
                component={
                  <Opencartdevelopment
                    title="Best OpenCart CMS Development Company | Opencart Development Services"
                    description="Searching the Best OpenCart CMS Development Company in Noida, Lucknow & entire India? Aara technologies offer different types of highly profitable Opencart eCommerce solutions with a great experience."
                    keywords="Best opencart development company, top opencart development company, opencart development company, opencart cms devlopment company, opencart api development company, opencart plugins development company, opencart development, opencart development company in india, opencart development company india, opencart fulfillment company, opencart fulfillment provider, home delivery software opencart."
                  />
                }
              />
            }
          />
          <Route
            path="/shopify-development"
            element={
              <Layout
                component={
                  <Shopifydevelopment
                    title="Best Shopify Development Company - Aara Technologies"
                    description="Aara Technologies is one of the leading Shopify Development Company. We provide extensive Shopify ecommerce website development services."
                    keywords="Best Shopify Development Company, Top Shopify Development Company, Shopify Development Company."
                  />
                }
              />
            }
          />
          <Route
            path="/cs-cart-development"
            element={
              <Layout
                component={
                  <Cscartdevelopment
                    title="Best CS Cart Development Company - Aara Technologies"
                    description="Aara Technologies is a CS Cart Development Company that offers Best CS-Cart Development Services. It is open-source eCommerce platform for shopping cart."
                    keywords="Best CS Cart Development Company, Top CS-Cart Development Company, CS-Cart Development Company."
                  />
                }
              />
            }
          />
          <Route
            path="/codeigniter-development"
            element={
              <Layout
                component={
                  <Codeigniterdevelopment
                    title="Best CodeIgniter Development Company | CI development company- Aara Technologies"
                    description="Looking for a Certified Codeigniter Development Company? Aara Technologies provides top-skilled CI Web Development Services for Startups or Agencies."
                    keywords="Top codeIgniter development company, Best codeIgniter development company, codeIgniter development company, ci development company."
                  />
                }
              />
            }
          />
          <Route
            path="/python-development"
            element={
              <Layout
                component={
                  <Pythondevelopment
                    title="Python Development Company in India | Aara Technologies"
                    description="Upgrade your business with a Python Development company in India. We offer high-quality Python development services to build complex web platforms."
                    keywords="Best python development company, top python development company, python development company, python software development, python development, python development services, python website development."
                  />
                }
              />
            }
          />
          <Route
            path="/android-app-development"
            element={
              <Layout
                component={
                  <Androidappdevelopment
                    title="Android App Development Company | Android App Development - Aara Technologies"
                    description="Trusted and top Android App Development Company in Noida, Lucknow, and entire India, Aara Technologies is provide services worldwide. We build Android Apps, iOS Apps with React JS."
                    keywords="Best android app development company, top android app development company, android app development company, android app development company in noida, android development company in noida, android development company in delhi, android development company in lucknow, android development company in india."
                  />
                }
              />
            }
          />
          <Route
            path="/ai-ar-product"
            element={
              <Layout
                component={
                  <Aiarproduct
                    title="Best AI AR Product Development Company - Aara Technologies"
                    description="Aara Technologies is a leading AI AR Product Development Company. We provide solutions for VR, MR, AI & IoT Technologies. We Build Interactive AR Applications."
                    keywords="Best Augmented Reality App Development Company, Top Augmented Reality App Development Company, Augmented Reality App Development Company, Best AI AR Product Development Company, AI AR Product Development."
                  />
                }
              />
            }
          />
          <Route
            path="/angular-development"
            element={
              <Layout
                component={
                  <Angulardevelopment
                    title="Best Angular Developmet Company | Angular JS Development - Aara Technologies"
                    description="Best Angular Development Company, Aara Technologies offer a complete range of solutions to build a variety of angular js Web and Mobile Apps."
                    keywords="Best angular web development company, top angular Web development company, angular Web development company,  angular web development, best angular js development company."
                  />
                }
              />
            }
          />
          <Route
            path="/nodejs-development"
            element={
              <Layout
                component={
                  <Nodejsdevelopment
                    title="Best Node js Development Company | Node.js Development - Aara Technologies"
                    description="Aara Technologies is a top-notch Node.js Development Company that provides Node.js Development Services for the company. We provide a secure user interface."
                    keywords="Best Node js Development Company, Top Node.js Development Company, Node.js Development Company."
                  />
                }
              />
            }
          />
          <Route
            path="/blog"
            element={
              <Layout
                component={
                  <Blog
                    title="Blogs The Leading Software Company - Aara Technologies"
                    description="Aara Technologies - Best website design & development company specializing in responsive and parallax website designing in Lucknow (India), Africa & Europe."
                    keywords="Leading Software Company In India."
                  />
                }
              />
            }
          />
          <Route
            path="/blog/:slug"
            element={<Layout component={<Page />} />}
          />
          <Route
            path="/job-opening"
            element={
              <Layout
                component={
                  <Job
                    title="Job opening The Leading Software Company - Aara Technologies"
                    description="Aara Technologies - Best website design & development company specializing in responsive and parallax website designing in Lucknow (India), Africa & Europe."
                    keywords="Leading Software Company In India."
                  />
                }
              />
            }
          />
          <Route
            path="/news/:id/:slug"
            element={<Layout component={<Detailnews />} />}
          />
          <Route
            path="/news"
            element={
              <Layout
                component={
                  <News
                    title="Latest News for aara technologies - Aara Technologies"
                    description="Aara Technologies - Get latest news for aara technologies."
                    keywords="Leading Software Company In India."
                  />
                }
              />
            }
          />
          <Route
            path="/privacy-policy"
            element={
              <Layout
                component={
                  <Privacy
                    title="privacy - Aara Technologies"
                    description="Aara Technologies - Best website design & development company specializing in responsive and parallax website designing in Lucknow (India), Africa & Europe."
                    keywords="Leading Software Company In India."
                  />
                }
              />
            }
          />
          <Route
            path="*"
            element={
              <Layout
                component={
                  <Page404
                    title="404 page - Aara Technologies"
                    description="This page is not found"
                    keywords="This page is not found."
                  />
                }
              />
            }
          />



          <Route
            path="/our-customer"
            element={
              <Layout
                component={
                  <Customer
                    title="CRM Development Company In India | Aara Technologies"
                    description="Get to enhance your business efficiency with CRM solutions. We are dedicated to offering the Best CRM Development services in India. Contact us today."
                    keywords="Best crm development company, top crm development company, crm development company, custom crm software company, crm software design company, best crm for small business, crm software at a budget price, best cloud crm service seller, crm software company near me, crm integration services, affordable crm design company, best crm development company, customized crm development, crm software development firm, powerful sales crm platform, India's Best Sales crm company, custom crm development company ."
                  />
                }
              />
            }
          />
          <Route
            path="/about1"
            element={
              <Layout
                component={
                  <About1
                    title="CRM Development Company In India | Aara Technologies"
                    description="Get to enhance your business efficiency with CRM solutions. We are dedicated to offering the Best CRM Development services in India. Contact us today."
                    keywords="Best crm development company, top crm development company, crm development company, custom crm software company, crm software design company, best crm for small business, crm software at a budget price, best cloud crm service seller, crm software company near me, crm integration services, affordable crm design company, best crm development company, customized crm development, crm software development firm, powerful sales crm platform, India's Best Sales crm company, custom crm development company ."
                  />
                }
              />
            }
          />
          <Route
            path="/try"
            element={
              <Layout
                component={
                  <TryCode

                  />
                }
              />
            }
          />

        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
