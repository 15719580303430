import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { axiosInstence, baseUrl } from '../Config/baseUrl';
import axios from 'axios';
import DOMPurify from 'dompurify';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const TryCode = () => {
    const [data, setData] = useState([]);
    const ContentData = () => {
        const reqBody = new FormData();
        reqBody.append("store_id", 1);
        axiosInstence.get(`${baseUrl}/blog/change-page-content-api/?store_id=${1}`, {
            headers: {
                Authorization: "Token 108f496c431f17940641ad58f8c72bae292c80be",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then((res) => {
                console.log("RESPONSE ==== : ", res.data);
                setData(res.data?.data);

            })
            .catch((err) => {
                console.log("ERROR: ====", err);
                toast.error(err.message);
            });
    };

    useEffect(() => {
        ContentData();
    }, []);

    return (
        <div>
            <div className="client_card">
                <div className="user_img">
                    <div
                    />
                </div>
                <div className="relative inf_content">

                    <h6 className="truncate-multiline w-[230px]">
                        {data[0]?.heading}
                    </h6>
                    <p>
                        {data[0]?.sub_heading}
                        <div className='p-4' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data[0]?.content) }}>
                        </div>
                    </p>
                </div>
                <div className="relative inf_content">

                    <h6 className="truncate-multiline w-[230px]">
                        {data[1]?.heading}
                    </h6>
                    <p>
                        {data[1]?.sub_heading}
                        <div className='p-4' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data[1]?.content) }}>
                        </div>
                    </p>
                </div>
                <div className="relative inf_content">

                    <h6 className="truncate-multiline w-[230px]">
                        {data[2]?.heading}
                    </h6>
                    <p>
                        {data[2]?.sub_heading}
                        <div className='p-4' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data[2]?.content) }}>
                        </div>
                    </p>
                </div>
            </div>
        </div>


    )
}

export default TryCode