import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Project = (props) => {
  const cards = [
    {
      name: "ZZZ Mart App",
      type1: "App development",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/zzzmart-app.png",
      link: "/page-single-project-5/",
      description:
        "Trust our top minds to eliminate workflow pain points, implement new tech & app.",
      tags: "Ecommerce app",
    },
    {
      name: "Grocery App",
      type1: "App DEVELOPMENT",
      description:
        "Trust our top minds to eliminate workflow pain points, implement new tech & app.",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/grocery1.png",
      link: "",
      tags: "Ecommerce app",
    },
    {
      name: "Review and rental app",
      type1: "App DEVELOPEMNT",
      description:
        "Trust our top minds to eliminate workflow pain points, implement new tech & app.",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/ryr-app.png",
      link: "",
      tags: "Ecommerce app",
    },
  ];

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <Helmet>
        <title>{props.title}</title>
        <h1 name="h1" content={props.h1} h1 />
        <meta name="description" content={props.description} />
        <meta name="keywords" content={props.keywords} />
        <link rel="canonical" href="https://aaratechnologies.com/ecommerce-app-development" />

      </Helmet>
      <main className="portfolio-page style-1">
        <section
          className="bg-white portfolio-projects section-padding style-1"
          id="MixItUpFCF1BF"
        >
          <div className="container">
            <div className="mb-40 text-center section-head style-4">
              <small className="title_small">App development</small>
              <h2 className="mb-20">
                <span> eCommerce App Development </span> Company{" "}
              </h2>
              <p>We providing best e-Commerce App development Services</p>
            </div>
          </div>
        </section>

        <section className="team section-padding style-6">
          <div className="content1">
            <div className="container">
              <div className="text-center section-head style-4 mb-60">
                <small className="title_small">eCommerce App Development</small>
                <h2 className="mb-20">
                  <span>eCommerce App Development Company</span>{" "}
                </h2>
                <p>
                  We are one of the best eCommerce Mobile App Development
                  Company based in Noida, Lucknow, and the entire India,
                  offering the best mobile app development services. Since the
                  dawn of smartphones, human behaviour has drastically changed.
                  Technology has also changed the buying habits of the mass
                  population. The new shopping trend is lead by online shopping
                  based on ecommerce website or ecommerce app. Being a business
                  owner, you can't ignore impact mobile app for your business.
                </p>
                <LazyLoadImage
                  src="https://aaratech.s3.ap-south-1.amazonaws.com/intro-mobile.webp"
                  className="imgmad mt-30"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="about section-padding style-4">
          <div className="content frs-content" id="about" data-scroll-index="2">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-6">
                  <div className="mb-10 img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/woo-mobile.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">
                        Ecommerce Development
                      </small>
                      <h4>
                        Let's Discuss Why We Are One Of The Best ECommerce
                        Mobile App Development Company
                      </h4>
                      <br />
                    </div>

                    <p>
                      While searching for the best Ecommerce Application
                      Development Company, it is important to have a clear
                      picture of the application they are designing. The main
                      aim of such an application is to help the customers to
                      avail the best possible shopping experience and the
                      designing of the product page in eCommerce is very
                      important for the customers to view the product as well as
                      the price clearly. We, Aara Technologies, as an eCommerce
                      apps development company always analyze in a way that
                      gives our customers more profit that's why we are counted
                      as the Best App Development Company in Noida, India.
                    </p>
                    <p>
                      An E-commerce App is a new kind of platform that is
                      designed to increase the conversion rate for your
                      business. You need to choose the App development Company
                      When you decide to start a business online, you want to be
                      able to reach all your customers. Your company needs to
                      stay on top of the current trends in the marketplace. By
                      making use of the apps available, you can keep up with
                      your competitors. As the Best eCommerce App Development
                      Company in Noida, Lucknow. we have helped many start-ups
                      with eCommerce app to boost their business to the next
                      level.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_lines.png"
              alt=""
              className="lines1"
            />
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt=""
              className="bubble"
            />
          </div>
          <div className="content sec-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="order-2 col-lg-5 order-lg-0">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">Web development</small>
                      <h2 className="mb-40">
                        Factors To Consider While Getting Developed An{" "}
                        <span>ECommerce App</span>
                      </h2>
                    </div>
                    <p>
                      Web designing is very much important for business owners
                      because it would help them promote their products and
                      services by making people aware of their products and
                      services. Having a website for business, a business owner
                      can expect various benefits like:
                    </p>
                    <p className="mb-20 text">
                      But it is very difficult to achieve all these things
                      without a professional website designing company. A
                      website can make a company famous fast if the perfect
                      website designing services are taken care of properly. And
                      as the business grows bigger, it becomes a need for the
                      website to grow and develop and that is where website
                      designing services come in very handy.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 order-0 order-lg-2">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/opencart-mobile.webp"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png"
              alt=""
              className="bubble2"
            />
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-6">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/magento-mobile.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">Aara Technologies</small>
                      <h2 className="mb-30">
                        While Selecting <span>ECommerce Development</span>{" "}
                        company
                      </h2>
                    </div>
                    <p>
                      One Important Thing to Remember One important thing to
                      keep in mind when looking for a company that is right for
                      you and your needs, is their cost. There is no point in
                      hiring someone that can charge you a lot of money without
                      actually delivering anything of value to your company or
                      business. Make sure to compare the cost of each project,
                      to make sure you get value for the money you spend.
                      Keeping in mind all the factors try to choose best
                      eCommerce App Development Company.{" "}
                    </p>
                    <p>
                      When you work with a company to create an app, ensure that
                      they understand your business, and what it is all about.
                      They need to be a team player, and able to help you figure
                      out exactly what you need to improve on in order to
                      provide an excellent app that works well.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt=""
              className="bubble"
            />
          </div>
          <div className="content sec-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="order-2 col-lg-5 order-lg-0">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">Web development</small>
                      <h2 className="mb-40">
                        {" "}
                        <span>Proper Strategy</span>
                      </h2>
                    </div>
                    <p>
                      Businesses continue to grow, and you need to keep up. It
                      is not enough to design an app; you need to implement a
                      robust eCommerce strategy that keeps up with the
                      competition. This means investing in the latest tools and
                      technologies, and tools that will improve your business.
                    </p>
                    <p className="mb-20 text">
                      Aara Technologies is fast growing eCommerce App
                      Development Company that knows the shifting market trends
                      of the business and thereby shapes your company concepts
                      into viable solutions. As a significant make-shift is
                      identified in the purchasing patterns of the consumers, we
                      grasp the significance of having a highly effective mobile
                      solution for every single business entity. Be it a
                      start-up idea or an existing business, we have an
                      eCommerce solution for your brick-and-mortar shop, we've
                      got intelligent and smart solutions for your complex
                      business challenges.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 order-0 order-lg-2">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/add-product.webp"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png"
              alt=""
              className="bubble2"
            />
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-6">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/notification.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">Aara Technologies</small>
                      <h2 className="mb-30">
                        Benefits Of{" "}
                        <span>ECommerce Mobile App Development</span> company
                      </h2>
                    </div>
                    <p>Growth in retention and customer traffic. </p>
                    <p>Huge engagement of customers.</p>
                    <p>Cart abandonment rate is very less in Mobile Apps.</p>
                    <p>Give a better shopping experience to the customers.</p>
                    <p>Increase customers loyalty.</p>
                    <p>
                      Because of the higher reachability of the eCommerce Mobile
                      App, your business can grow in an outstanding way.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt=""
              className="bubble"
            />
          </div>
          <div className="content sec-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="order-2 col-lg-5 order-lg-0">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">Web development</small>
                      <h2 className="mb-40">
                        Core Features Of Our <span> ECommerce Mobile App</span>
                      </h2>
                    </div>
                    <p>B2C ecommerce services</p>
                    <p className="mb-20 text">
                      ecommerce android app development
                    </p>
                    <p className="mb-20 text">ecommerce iOS app development</p>
                    <p className="mb-20 text">
                      ecommerce payment gateway integration
                    </p>
                    <p className="mb-20 text">
                      ecommerce shopping cart development
                    </p>
                    <p className="mb-20 text">
                      ecommerce services for multi-vendor and many more
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 order-0 order-lg-2">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/app.webp"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png"
              alt=""
              className="bubble2"
            />
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-6">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/track_states.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">Aara Technologies</small>
                      <h2 className="mb-30">
                        <span>Industries</span> We Have Worked For{" "}
                      </h2>
                    </div>
                    <p>Education </p>
                    <p>Retail</p>
                    <p>Hospitality </p>
                    <p>Booking</p>
                    <p>Real estate </p>
                    <p>Banking and Finance</p>
                    <p>Entertainment </p>
                    <p>ETC..</p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt=""
              className="bubble"
            />
          </div>
          <div className="integration pt-30" data-scroll-index="3">
            <div className="integration pt-60" data-scroll-index="3">
              <div className="text-center section-head style-4">
                <small className="title_small">Steps For Done</small>
                <h2 className="mb-20">
                  Our <span> Working Process</span>{" "}
                </h2>
              </div>
              <div className="container">
                <div className="content">
                  <div className="img">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/provisioning.png"
                      alt=""
                      className="mt-30"
                    />
                    <h3 className="text-center">1. Provisioning</h3>
                  </div>
                  <div className="img">
                    <h3 className="text-center">2. Strategy</h3>
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/strategy.png"
                      alt=""
                      className="mt-30"
                    />
                  </div>
                  <div className="img">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/design%26developement.png"
                      alt=""
                      className="mt-30"
                    />
                    <h3 className="text-center">3. Development</h3>
                  </div>
                  <div className="img">
                    <h3 className="text-center">4. Go Live</h3>
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/go-live.png"
                      alt=""
                      className="mt-30"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="bg-white portfolio-projects section-padding style-1"
          id="MixItUpFCF1BF"
        >
          <div className="container">
            <div className="mb-40 text-center section-head style-4">
              <small className="title_small">App development</small>
              <h2 className="mb-20">
                <span>Our Projects </span>
              </h2>
              <p>Here are given some related technologies projects.</p>
            </div>
            <section className="portfolio style-1">
              <div className="content">
                <div className="row mix-container">
                  {cards.map((card, index) => (
                    <div className="col-lg-4 mix security consultation">
                      <div className="text-center portfolio-card mb-30 scalmad">
                        <div className="img">
                          <LazyLoadImage
                            src={card.image}
                            alt=""
                            style={{ objectFit: "contain" }}
                          />
                        </div>
                        <div className="info">
                          <h5>
                            <a href={card.link}>{card.name}</a>
                          </h5>
                          <small className="d-block color-main text-uppercase">
                            {card.type1}
                          </small>
                          <div className="text">{card.description}</div>
                          <div className="tags">
                            <a href="#" className="me-1">
                              {card.tags}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Project;
