import React, { useEffect } from "react";
import { Accordion } from "react-bootstrap";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";
const Opencart = (props) => {
  const cards = [
    {
      name: "Nabeel perfumes",
      type1: "Opencart ",
      image: 'https://aaratech.s3.ap-south-1.amazonaws.com/nabeelopencart.png',
      link: "",
      description:
        "Nabeel perfumes is ecommerce site tio sell perfumes.",
      tags: "Opencart",
    },
    {
      name: "Ayurveda Is Life",
      type1: "Opencart",
      description:
        "Ayurveda Is Life website is to sell the ayurvedic products in different cities.",
      image: 'https://aaratech.s3.ap-south-1.amazonaws.com/opencartproject.PNG',
      link: "",
      tags: "Opencart",
    },
    {
      name: "Thrifter",
      type1: "Opencart",
      description:
        "Thrifter is an ecommerce website to shop here many products in different cities.",
      image: 'https://aaratech.s3.ap-south-1.amazonaws.com/opnecart3p.png',
      link: "",
      tags: "Opencart",
    },
  ];

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div>
      <Helmet>
        <title>{props.title}</title>
        <meta name="description" content={props.description} />
        <meta name="keywords" content={props.keywords} />
        <link rel="canonical" href="https://aaratechnologies.com/opencart-development" />

      </Helmet>
      <main className="portfolio-page style-1">
        <section
          className="bg-white portfolio-projects section-padding style-1"
        >
          <div className="container">
            <div className="mb-40 text-center section-head style-4">
              <h2 className="mb-20">
                <span>OpenCart CMS Development</span> Company
              </h2>
              <p>
                OpenCart Development is a very popular Ecommerce framework, Aara
                technologies is the experience in opencart Development Company.
              </p>
            </div>
          </div>
        </section>

        <section className="team section-padding style-6">
          <div className="mb-40 content1">
            <div className="container">
              <div className="mb-20 text-center section-head style-4">
                <small className="title_small">CMS Development</small>
                <h2 className="mb-20">
                  <span>OpenCart </span> Development
                </h2>
                <p>
                  OpenCart Development is a leading open-source eCommerce
                  platform built on PHP. It is lightweight and used for
                  eCommerce website development and shopping cart.
                </p>
                <LazyLoadImage
                  src="https://aaratech.s3.ap-south-1.amazonaws.com/opencart.webp"
                  className="imgmad"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="about section-padding style-4">
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/store-front.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        Why Should You Choose{" "}
                        <span>OpenCart For Your Online Store Development?</span>
                      </h2>
                    </div>
                    <p className="mb-40 text">
                      OpenCart development company is highly suitable for small
                      and medium-scale eCommerce, As it is written on PHP and
                      jQuery with Bootstrap as a CSS framework. It is easy to
                      customize. The MVC system of the OpenCart development
                      company platform is easy to understand and scale. OpenCart
                      is a complete shopping cart platform and is widely popular
                      and used by thousands of websites worldwide. We are the
                      best Opencart CMS development company in Noida, Lucknow,
                      and the entire of India that provide the best opencart
                      development solutions.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
          <div className="content sec-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="section-head style-4">
                  <h2 className="mb-30">
                    <span>Features Of Opencart Development</span>
                  </h2>
                </div>
                <div className="order-2 col-lg-6 order-lg-0">
                  <div className="info">
                    <p className="text">
                      Key features of React Native App Development.
                    </p>
                    <ul className="list-style-two">
                      <li className="mb-20 d-flex">
                        <CheckCircleOutlineIcon />
                        <h6>Unlimited categories.</h6>
                      </li>
                      <li className="mb-20 d-flex">
                        <CheckCircleOutlineIcon />
                        <h6 className="fw-bold">
                          Unlimited products and manufacturers.
                        </h6>
                      </li>
                      <li className="mb-20 d-flex">
                        <CheckCircleOutlineIcon />
                        <h6>Sell digital products.</h6>
                      </li>
                      <li className="mb-20 d-flex">
                        <CheckCircleOutlineIcon />
                        <h6 className="fw-bold">Multi currency supported.</h6>
                      </li>
                      <li className="mb-20 d-flex">
                        <CheckCircleOutlineIcon />
                        <h6>Multilingual.</h6>
                      </li>
                      <li className="mb-20 d-flex">
                        <CheckCircleOutlineIcon />
                        <h6 className="fw-bold">
                          Product reviews and product ratings.
                        </h6>
                      </li>
                      <li className="mb-20 d-flex">
                        <CheckCircleOutlineIcon />
                        <h6>Can be extended with templates (designs).</h6>
                      </li>
                      <li className="mb-20 d-flex">
                        <CheckCircleOutlineIcon />
                        <h6 className="fw-bold">More than 20 payment gateways.</h6>
                      </li>
                      <li className="mb-20 d-flex">
                        <CheckCircleOutlineIcon />
                        <h6>More than 8 shipping methods.</h6>
                      </li>
                      <li className="mb-20 d-flex">
                        <CheckCircleOutlineIcon />
                        <h6 className="fw-bold">PCI compliant.</h6>
                      </li>
                      <li className="mb-20 d-flex">
                        <CheckCircleOutlineIcon />
                        <h6>Multiple tax rates.</h6>
                      </li>
                      <li className="mb-20 d-flex">
                        <CheckCircleOutlineIcon />
                        <h6 className="fw-bold">
                          Shipping calculations (weight and size).
                        </h6>
                      </li>
                      <li className="mb-20 d-flex">
                        <CheckCircleOutlineIcon />
                        <h6>Discount coupon system.</h6>
                      </li>
                      <li className="mb-20 d-flex">
                        <CheckCircleOutlineIcon />
                        <h6 className="fw-bold">Backup and restore tools.</h6>
                      </li>
                      <li className="mb-20 d-flex">
                        <CheckCircleOutlineIcon />
                        <h6>Printable invoices.</h6>
                      </li>
                      <li className="mb-20 d-flex">
                        <CheckCircleOutlineIcon />
                        <h6 className="fw-bold">Sales reports.</h6>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-6">
                  <LazyLoadImage src="https://aaratech.s3.ap-south-1.amazonaws.com/store-front.webp" alt="" />
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png' alt="" className="bubble2" />
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>OpenCart App Development</span>
                      </h2>
                    </div>
                    <p className="text">
                      Aara Technologies develops Opencart CMS solutions that
                      combine best-in-class design, best-in-class technology,
                      and an understanding of business values. We do everything
                      we can to ensure that every opencart development solution
                      we offer meets your expectations. By working with us you
                      will experience the ease of working with smart,
                      experienced, remote teams. We build your website as an
                      application that facilitates simple navigation i.e. click
                      and go. We will optimize your website for both Android and
                      iOS devices.
                    </p>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/opencart-mobile.webp"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/store-front.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>Custom OpenCart Development</span>
                      </h2>
                    </div>
                    <p className="mb-40 text">
                      Create, edit, and publish products directly from your
                      favorite mobile device. Capture your creativity at the
                      moment – turn your ideas into products in seconds, or save
                      them as drafts for later.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>OpenCart Marketplace Development</span>
                      </h2>
                    </div>
                    <p className="text">
                      Never miss an order or review. Keep that FOMO at bay by
                      enabling real-time alerts – and listen out for that
                      addictive “cha-ching” sound that comes with every new
                      sale!
                    </p>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/store-front.webp"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/store-front.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>OpenCart Theme UI Development</span>
                      </h2>
                    </div>
                    <p className="text">
                      From purchase to fulfillment – manage the entire order
                      process via the app. Scroll the summaries, or search by
                      customer or order specifics. Tap into the details to see
                      itemized billing, begin fulfillment, and change order
                      status.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>OpenCart PWA Development</span>
                      </h2>
                    </div>
                    <p className="text">
                      See at a glance which products are winning. Keep tabs on
                      overall revenue, order count, and visitor data by week,
                      month, and year.
                    </p>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/store-front.webp"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
          <div className="mb-40 content trd-content bgmad">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/store-front.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>OpenCart API Development</span>
                      </h2>
                    </div>
                    <p className="text">
                      From purchase to fulfillment – manage the entire order
                      process via the app. Scroll the summaries, or search by
                      customer or order specifics. Tap into the details to see
                      itemized billing, begin fulfillment, and change order
                      status.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>OpenCart B2B Development</span>
                      </h2>
                    </div>
                    <p className="text">
                      From purchase to fulfillment – manage the entire order
                      process via the app. Scroll the summaries, or search by
                      customer or order specifics. Tap into the details to see
                      itemized billing, begin fulfillment, and change order
                      status.
                    </p>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/store-front.webp"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
        </section>
        <section className="team section-padding style-4">
          <div className="content sec-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="section-head style-4">
                  <small className="title_small">
                    <strong>FAQ's</strong>
                  </small>
                </div>
                <Accordion className="mb-20">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <h5>
                        Can you guys turn my OpenCart website into a wonderful
                        marketplace?
                      </h5>
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes of course. Contemporary eCommerce trends are focused
                      on the marketplace. Aara Technologies has vast experience
                      in building multi-vendor online marketplaces like Etsy,
                      Amazon, eBay, and others. We offer a wide range of
                      services apart from their marketplace. These include
                      product listings, a shopping cart, a website search,
                      payments, shipping, notifications, support chat, and more.
                      We can create an OpenCart Marketplace website that drives
                      conversions. We assure you to introduce, develop and
                      integrate excellent online marketplace management with
                      your existing OpenCart so that your store can jump right
                      in to start your online marketplace.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      <h5> Are you into custom plugin development?</h5>
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, we create custom plugins to add additional
                      functionality to your default OpenCart online store in
                      addition to the default OpenCart features. We develop
                      every custom plugin from Booking Calendar or Hyperlocal or
                      POS or RMA to full-featured online marketplaces - you can
                      add them all to your website with different plugins. There
                      are many ready-made modules available in our OpenCart
                      plugin market. We also customize existing plugins and
                      develop new custom plugins as per your requirements.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      <h5>Are you developed OpenCart mobile app?</h5>
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, we are developing OpenCart mobile apps that combine
                      creative design, the latest technology, and a better
                      understanding of business values. Applications are now
                      more essential to your business than ever before. We can
                      customize them or you can create a new one either way. Our
                      solution works every step of the way to ensure that each
                      mobile solution we offer meets your expectations. We
                      format your website in an application that facilitates
                      simple navigation i.e. click and go. We will optimize your
                      website to provide you with fully native optimized Android
                      and iOS apps for OpenCart Store.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      <h5>Do you provide integration services?</h5>
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, we are providing integration services like Amazon
                      Connector, eBay Connector, Shipping, Payment integration,
                      etc with the OpenCart platform.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      <h5>
                        Are you providing a migration service to the OpenCart
                        platform?
                      </h5>
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, We develop OpenCart mobile apps that combine great
                      design, great technologies, and an understanding of
                      business values. Yes, we are providing migration services
                      to migrate your website on the OpenCart platform from any
                      other platform.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </section>
        <section
          className="bg-white portfolio-projects section-padding style-1"
        >
          <div className="container">
            <div className="mb-40 text-center section-head style-4">
              <h2 className="mb-20">
                Our  <span>Projects</span>
              </h2>
              <p>Here are given our some projects to know about our work.</p>
            </div>
            <section className="portfolio style-1">
              <div className="content">
                <div className="row mix-container">
                  {cards.map((card) => (
                    <div className="col-lg-4 mix security consultation">
                      <div className="text-center portfolio-card mb-30 scalmad">
                        <div className="img">
                          <LazyLoadImage src={card.image} alt="" style={{ objectFit: 'contain' }} />
                        </div>
                        <div className="info">
                          <h5>
                            <a href={card.link}>{card.name}</a>
                          </h5>
                          <small className="d-block color-main text-uppercase">
                            {card.type1}
                          </small>
                          <div className="text">{card.description}</div>
                          <div className="tags">
                            <a href="#" className="me-1">
                              {card.tags}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Opencart;
