import React, { useState } from "react";
import {
  ContentCopy,
  Facebook,
  Share,
  Twitter,
  WhatsApp,
  Pinterest,
  Email,
} from "@mui/icons-material";
import { Button, Divider, Drawer } from "@mui/material";
import {
  TwitterShareButton,
  FacebookShareButton,
  WhatsappShareButton,
  EmailShareButton,
  PinterestShareButton,
} from "react-share";

const ShareLink = ({ url }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = (event) => {
    event.stopPropagation();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onCopyLink = () => {
    navigator?.clipboard?.writeText(url);
    console.log("hi this cpy", url);
  };

  return (
    <>
      <Button
        size="small"
        endIcon={<Share className="!text-lg" />}
        className="!capitalize !bg-amber-300"
        onClick={(event) => handleClickOpen(event)}
      >
        Share
      </Button>
      <Drawer
        anchor="bottom"
        open={open}
        onClose={handleClose}
        PaperProps={{ className: "!rounded-t-lg !h-[30vh]" }}
      >
        <span className="flex justify-center p-2" onClick={handleClose}>
          <span className="h-[5px] w-24 rounded-full bg-zinc-200 dark:bg-zinc-700"></span>
        </span>
        {/* <span className="flex items-center p-4 gap-3">
          <LazyLoadImage src={variantImage} alt="" className="h-14 w-14 rounded" />
          <span className="flex flex-col gap-1">
            <p className="text-sm font-semibold">{variantName}</p>
            <p className="text-xs">{url}</p>
          </span>
        </span> */}
        <Divider />
        <span className="flex gap-4 items-center justify-center p-3 item-center">
          <span
            className="shadow items-center flex flex-col p-3 rounded-sm gap-1 w-[80px]"
            onClick={onCopyLink}
          >
            <ContentCopy className="!text-xl" />
            <p className="text-xs">Copy Link</p>
          </span>
          <WhatsappShareButton url={url}>
            <span className="shadow items-center flex flex-col p-3 rounded-sm gap-1 w-[80px]">
              <WhatsApp className="!text-xl" style={{ color: "#00a884" }} />
              <p className="text-xs">WhatsApp</p>
            </span>
          </WhatsappShareButton>
          <FacebookShareButton url={url}>
            <span className="shadow items-center flex flex-col p-3 rounded-sm gap-1 w-[80px]">
              <Facebook className="!text-xl" style={{ color: "#1877F2" }} />
              <p className="text-xs">Facebook</p>
            </span>
          </FacebookShareButton>
          <TwitterShareButton url={url}>
            <span className="shadow items-center flex flex-col p-3 rounded-sm gap-1 w-[80px]">
              <Twitter className="!text-xl" style={{ color: "#1CA1F2" }} />
              <p className="text-xs">Twitter</p>
            </span>
          </TwitterShareButton>
          <PinterestShareButton url={url}>
            <span className="shadow items-center flex flex-col p-3 rounded-sm gap-1 w-[80px]">
              <Pinterest className="!text-xl" style={{ color: "#f22a1c" }} />
              <p className="text-xs">Pinterest</p>
            </span>
          </PinterestShareButton>
          <EmailShareButton url={url}>
            <span className="shadow items-center flex flex-col p-3 rounded-sm gap-1 w-[80px]">
              <Email className="!text-xl" style={{ color: "#1CA1F2" }} />
              <p className="text-xs">Email</p>
            </span>
          </EmailShareButton>
        </span>
        <Divider />
      </Drawer>
    </>
  );
};
export default ShareLink;
