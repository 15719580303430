import React, { useEffect } from "react";
import { useState } from "react";
import { Card } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
const Project = (props) => {
  const [activeFilter, setActiveFilter] = useState("all");

  const handleFilterClick = (filter) => {
    setActiveFilter(filter);
  };

  const cards = [
    {
      name: "product Inventory management",
      type1: "Software",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/product-inventory-management.jpg",
      type: "fruit1",
      link: "/page-single-project-5/",
    },
    {
      name: "HRM Software",
      type1: "Software",
      type: "fruit1",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/product-human-resource-management.jpg",
      link: "/page-single-project-5/",
    },
    {
      name: "MLM Software",
      type1: "Software DEVELOPEMNT",
      type: "fruit1",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/product-multi-level-marketing.jpg",
      link: "/page-single-project-5/",
    },
    {
      name: "Okpay E-Wallet Optimized",
      type1: "SEO ANALYSIS",
      type: "fruit1",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/product-school-management-system.jpg",
      link: "/page-single-project-5/",
    },
    {
      name: "Amaka Industry",
      type1: "mobile app",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/grocery1.png",
      type: "fruit",
      link: "/page-single-project-5/",
    },
    {
      name: "Education App",
      type1: "mobile app",
      type: "fruit",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/buss.png",
      link: "/page-single-project-5/",
    },
    {
      name: "Petsanco",
      type1: "Web development",
      type: "vegetable",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/petsandco.png",
      link: "/page-single-project-5/",
    },
    {
      name: "Okpay E-Wallet Optimized",
      type1: "SEO ANALYSIS",
      type: "vegetable",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/Rajatcollege.png",
      link: "/page-single-project-5/",
    },
    {
      name: "Ketomeals",
      type1: "WEBSITE DESIGN & DEVELOMENT",
      type: "vegetable",
      type2: "vegetables5",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/Ketomeals.png",
      link: "/page-single-project-5/",
    },
    {
      name: "Jmd Fire",
      type1: "DATA SECURITY",
      type: "vegetable",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/jmdfire.png",
      link: "/page-single-project-5/",
    },
    {
      name: "D.A.V. Public School JamshedPur",
      type1: "UI/UX DESIGN",
      type: "vegetable",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/davpublicschool.png",
      link: "/page-single-project-5/",
    },
    {
      name: "Central Public School JamshedPur",
      type1: "DATA SECURITY, IT CONSULTATION",
      type: "vegetable",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/cpsjamshedpur.png",
      link: "/page-single-project-5/",
    },
    {
      name: "Thrift Shop",
      type1: "Ecommerce",
      type: "vegetable",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/thriftshop.png",
      link: "/page-single-project-5/",
    },
    {
      name: "Virtual Design hub",
      type1: "MACHINE LEARNING",
      type: "vegetable",
      type2: "vegetables5",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/virtualdesignhub.png",
      link: "/page-single-project-5/",
    },
    {
      name: "Nabeel Perfumes",
      type1: "Ecommerce",
      type: "vegetable",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/nabeel.png",
      link: "/page-single-project-5/",
    },
    {
      name: "Decode Interiors",
      type1: "MACHINE LEARNING",
      type: "vegetable",
      type2: "vegetables5",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/decode.png",
      link: "/page-single-project-5/",
    },
    {
      name: "The Career ads",
      type1: "MACHINE LEARNING",
      type: "vegetable",
      type2: "vegetables5",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/careerads.png",
      link: "/page-single-project-5/",
    },
    {
      name: "Chetak Roadlines",
      type1: "MACHINE LEARNING",
      type: "vegetable",
      type2: "vegetables5",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/cripl.png",
      link: "/page-single-project-5/",
    },
    {
      name: "Mariam Beauty",
      type1: "MACHINE LEARNING",
      type: "vegetable",
      type2: "vegetables5",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/mariam-beauty.png",
      link: "/page-single-project-5/",
    },
    {
      name: "Ashirwadum",
      type1: "MACHINE LEARNING",
      type: "vegetable",
      type2: "vegetables5",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/ashirwadum.png",
      link: "/page-single-project-5/",
    },
    {
      name: "rajs sound waves",
      type1: "MACHINE LEARNING",
      type: "vegetable",
      type2: "vegetables5",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/rajssoundwaves.png",
      link: "/page-single-project-5/",
    },
    {
      name: "astraea",
      type1: "MACHINE LEARNING",
      type: "vegetable",
      type2: "vegetables5",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/astraea.png",
      link: "/page-single-project-5/",
    },
    {
      name: "Clop Solutions",
      type1: "MACHINE LEARNING",
      type: "vegetable",
      type2: "vegetables5",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/clop-solution.png",
      link: "/page-single-project-5/",
    },
    {
      name: "Nishta",
      type1: "MACHINE LEARNING",
      type: "vegetable",
      type2: "vegetables5",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/nishta.png",
      link: "/page-single-project-5/",
    },
    {
      name: "Rootless auto",
      type1: "MACHINE LEARNING",
      type: "vegetable",
      type2: "vegetables5",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/rootless-auto.png",
      link: "/page-single-project-5/",
    },
    {
      name: "Aditya Gold",
      type1: "MACHINE LEARNING",
      type: "vegetable",
      type2: "vegetables5",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/aditya-gold.png",
      link: "/page-single-project-5/",
    },
    {
      name: "Competitive cyclist",
      type1: "MACHINE LEARNING",
      type: "vegetable",
      type2: "vegetables5",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/competitive-cyclist.png",
      link: "/page-single-project-5/",
    },
    {
      name: "the-young-stove",
      type1: "MACHINE LEARNING",
      type: "vegetable",
      type2: "vegetables5",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/the-young-stove.png",
      link: "/page-single-project-5/",
    },
    {
      name: "buguda nac",
      type1: "MACHINE LEARNING",
      type: "vegetable",
      type2: "vegetables5",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/bugudanac.png",
      link: "/page-single-project-5/",
    },
    {
      name: "taiton-app",
      type1: "MACHINE LEARNING",
      type: "fruit",
      type2: "vegetables5",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/taiton-app.png",
      link: "/page-single-project-5/",
    },
    {
      name: "SG app",
      type1: "MACHINE LEARNING",
      type: "fruit",
      type2: "vegetables5",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/sg-app.png",
      link: "/page-single-project-5/",
    },
    {
      name: "Oemspa App",
      type1: "MACHINE LEARNING",
      type: "fruit",
      type2: "vegetables5",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/oemspa-app.png",
      link: "/page-single-project-5/",
    },
    {
      name: "Oemspa Web",
      type1: "MACHINE LEARNING",
      type: "vegetable",
      type2: "vegetables5",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/oemspa-web.png",
      link: "/page-single-project-5/",
    },
    {
      name: "Okm App",
      type1: "MACHINE LEARNING",
      type: "fruit",
      type2: "vegetables5",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/okm-app.png",
      link: "/page-single-project-5/",
    },
    {
      name: "Okm web",
      type1: "MACHINE LEARNING",
      type: "vegetable",
      type2: "vegetables5",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/okm-web.png",
      link: "/page-single-project-5/",
    },
    {
      name: "Robotchat AI Development",
      type1: "MACHINE LEARNING",
      type: "fruit",
      type2: "vegetables5",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/ryr-app.png",
      link: "/page-single-project-5/",
    },
    {
      name: "Rti web",
      type1: "MACHINE LEARNING",
      type: "vegetable",
      type2: "vegetables5",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/rti-web.png",
      link: "/page-single-project-5/",
    },
    {
      name: "Robotchat AI Development",
      type1: "MACHINE LEARNING",
      type: "fruit",
      type2: "vegetables5",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/nrp-app.png",
      link: "/page-single-project-5/",
    },
    {
      name: "Nrp Web",
      type1: "MACHINE LEARNING",
      type: "vegetable",
      type2: "vegetables5",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/nrp-web.png",
      link: "/page-single-project-5/",
    },
    {
      name: "AEC Web",
      type1: "MACHINE LEARNING",
      type: "vegetable",
      type2: "vegetables5",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/aec-web.png",
      link: "/page-single-project-5/",
    },
    {
      name: "Robotchat AI Development",
      type1: "MACHINE LEARNING",
      type: "fruit",
      type2: "vegetables5",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/zzzmart-app.png",
      link: "/page-single-project-5/",
    },
    {
      name: "zzzmart-web",
      type1: "MACHINE LEARNING",
      type: "vegetable",
      type2: "vegetables5",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/zzzmart-web.png",
      link: "/page-single-project-5/",
    },
    {
      name: "PickleMan",
      type1: "Ecommerce",
      type: "vegetable",
      type2: "vegetables5",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/PickleMan.png",
      link: "/page-single-project-5/",
    },
    {
      name: "amakaindustries",
      type1: "Ecommerce",
      type: "vegetable",
      type2: "vegetables5",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/amakaindustries.png",
      link: "/page-single-project-5/",
    },
  ];


  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <Helmet>
        <title>{props.title}</title>
        <meta name="description" content={props.description} />
        <meta name="keywords" content={props.keywords} />
      </Helmet>
      <main class="portfolio-page style-1">
        <section
          class="portfolio-projects section-padding pt-50 style-1 bg-white"
        >
          <div class="container">
            <div class="section-head text-center style-4 mb-40">
              <small class="title_small">portfolio</small>
              <h2 class="mb-20">
                Our <span> Projects </span>{" "}
              </h2>
              <p>
                We have an experienced team of production and inspection
                personnel to ensure quality.
              </p>
            </div>
            <div class="controls">
              <button
                className={`filter-button ${activeFilter === "all" ? "mixitup-control-active" : ""
                  }`}
                onClick={() => handleFilterClick("all")}
              >
                All
              </button>
              <button
                className={`filter-button ${activeFilter === "fruits" ? "mixitup-control-active" : ""
                  }`}
                onClick={() => handleFilterClick("fruits")}
              >
                Software Development
              </button>
              <button
                className={`filter-button ${activeFilter === "vegetables" ? "mixitup-control-active" : ""
                  }`}
                onClick={() => handleFilterClick("vegetables")}
              >
                Web Development
              </button>

              <button
                className={`filter-button ${activeFilter === "vegetables2" ? "mixitup-control-active" : ""
                  }`}
                onClick={() => handleFilterClick("vegetables2")}
              >
                E-Commerece
              </button>

              <button
                className={`filter-button ${activeFilter === "vegetables3" ? "mixitup-control-active" : ""
                  }`}
                onClick={() => handleFilterClick("vegetables3")}
              >
                Mobile App
              </button>

              <button
                className={`filter-button ${activeFilter === "vegetables4" ? "mixitup-control-active" : ""
                  }`}
                onClick={() => handleFilterClick("vegetables4")}
              >
                IT Infrastructure Development
              </button>
              <button
                className={`filter-button ${activeFilter === "vegetables5" ? "mixitup-control-active" : ""
                  }`}
                onClick={() => handleFilterClick("vegetables5")}
              >
                Product
              </button>
            </div>
            <section class="portfolio style-1">
              <div className="content">
                <div className="row mix-container">
                  {cards
                    .filter(
                      (card) =>
                        activeFilter === "all" ||
                        (activeFilter === "fruits" && card.type === "fruit1") ||
                        (activeFilter === "vegetables" &&
                          card.type === "vegetable") ||
                        (activeFilter === "vegetables2" &&
                          card.type1 === "Ecommerce") ||
                        (activeFilter === "vegetables3" &&
                          card.type1 === "mobile app") ||
                        (activeFilter === "vegetables4" &&
                          card.type === "vegetables4") ||
                        (activeFilter === "vegetables5" &&
                          card.type2 === "vegetables5")
                    )
                    .map((card) => (
                      <div className="col-lg-4">
                        <Card className="mb-30 scalmad">
                          <Card.Img variant="top" src={card.image} style={{ objectFit: 'contain' }} />
                          <Card.Body>
                            <small class="color-main text-uppercase">
                              {card.type1}
                            </small>
                            <Card.Title>{card.name}</Card.Title>

                          </Card.Body>
                        </Card>
                      </div>


                    ))}
                </div>
              </div>
            </section>
          </div>
        </section>
        <section className="download section-padding style-5 bg-light">
          <div className="container mt-70">
            <div className="row justify-content-center">
              <div className="col-lg-9">
                <div className="content text-center">
                  <div className="section-head text-center style-4 mt-70">
                    <h2 class="mb-20 ">
                      {" "}
                      Access your business potentials today &amp; find
                      opportunity for <span>bigger success</span>
                    </h2>
                  </div>
                  <div className="butns mt-70 mb-60">
                    <Link as={Link}
                      class="btn rounded-pill fw-bold bg-blue4 text-white"
                      target="_blank"
                      to="/"
                    >
                      <small>Start A Project Now</small>
                    </Link>&nbsp;
                    <Link
                      className="btn rounded-pill fw-bold bg-blue4 text-white"
                      target="_blank"
                      to="/contact"
                    >
                      <small>Contact Us for &amp; Plan</small>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <LazyLoadImage
            src="https://aaratech.s3.ap-south-1.amazonaws.com/contact_globe.svg"
            alt=""
            class="contact_globe"
          />
        </section>
      </main>
    </div>
  );
};

export default Project;
