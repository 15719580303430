import React, { useEffect } from "react";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Accordion } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Project = (props) => {
  const cards = [
    {
      name: "Product Inventory mangement Software",
      type1: "Software development",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/product-inventory-management.jpg",
      link: "",
      description:
        "Product Inventory management Software is the part of cloud based erp software. ",
      tags: "Cloud based software",
    },
    {
      name: "Product human resource management App",
      type1: "Software DEVELOPMENT",
      description:
        "Product human resource management App is the part of cloud based hrm software.",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/product-human-resource-management.jpg",
      link: "",
      tags: "Cloud based software",
    },
    {
      name: "Product multi level marketing App",
      type1: "Software DEVELOPEMNT",
      description:
        "Product multi level marketing App is the part of cloud based mlm software",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/product-multi-level-marketing.jpg",
      link: "",
      tags: "Cloud based software",
    },
  ];

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <Helmet>
        <title>{props.title}</title>
        <h1 name="h1" content={props.h1} h1 />
        <meta name="description" content={props.description} />
        <meta name="keywords" content={props.keywords} />
        <link rel="canonical" href="https://aaratechnologies.com/cloud-based-open-source-software-development" />
      </Helmet>
      <main className="portfolio-page style-1">
        <section
          className="bg-white portfolio-projects section-padding style-1"
          id="MixItUpFCF1BF"
        >
          <div className="container">
            <div className="mb-40 text-center section-head style-4">
              <small className="title_small">Customized Software</small>
              <h2 className="mb-20">
                <span> Cloud </span> Based <span>Open Source Software</span>{" "}
                Development
              </h2>
              <p>
                We providing best Cloud Based Open Source Software Development.
              </p>
            </div>
          </div>
        </section>

        <section className="team section-padding style-6">
          <div className="content1">
            <div className="container">
              <div className="text-center section-head style-4 mb-60">
                <small className="title_small">Open source software</small>
                <h2 className="mb-20">
                  Customized <span> Cloud </span> Based{" "}
                  <span>Open Source Software</span> Development
                </h2>
                <p>
                  Cloud-based solutions are believed to dominate the digital
                  world.The cloud approach is also becoming extremely popular in
                  eCommerce and other specific business areas due to advantages
                  such as scalability, enhanced productivity, traffic cost
                  reduction, and significantly lower equipment cost. A
                  cloud-based app is an Internet-run program with components
                  stored online with some (or all) processes executed in the
                  cloud.
                </p>
                <LazyLoadImage
                  src="https://aaratech.s3.ap-south-1.amazonaws.com/cloud.webp"
                  className="imgmad mt-30"
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>

        <section className="about section-padding style-4">
          <div className="content frs-content" id="about" data-scroll-index="2">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="mb-10 img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/mobile-app-development.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">Customized Software</small>
                      <h2>
                        Why <span> Open Source?</span>
                      </h2>
                      <br />
                    </div>
                    <p>
                      The digital world is becoming interlinked and more
                      connected. Every retailer or a merchant wishes to serve an
                      open-source omnichannel experience to its customer
                      base.Today, most of the retailer owns a physical store
                      along with an online eCommerce Store which is accompanied
                      by a Mobile App. Retailers also wish to sell their goods
                      at popular eCommerce Marketplaces like Etsy, eBay or
                      others to outreach beyond their customer base.
                    </p>
                    <p>
                      It becomes quite hard for retailers to manage all these
                      different entities at the same time. However, going
                      omnichannel is a must have for any vendor in today’s
                      competitive world, but it is hard to invest a huge amount
                      of capital and resources for the sprouting small scaled
                      ventures or vendors to get started. Apart from this, it is
                      obvious a vendor will turn into an enterprise sooner or
                      later and he may need Product Information Manager (PIM) to
                      manage the huge dataset. So, How a retailer can cope up
                      with such a complex architecture? This is where
                      open-source frameworks comes into play. Open-source
                      software or frameworks are built with an eye on
                      collaboration and have “open to read” codebase; a
                      developer can easily access the code to create set of
                      tools or apps which are required for the integrations.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt=""
              className="bubble"
            />
          </div>
          <div className="content sec-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="order-2 col-lg-6 order-lg-0">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">Customized Software</small>
                      <h2 className="mb-40">
                        <span>ECommerce Connectors?</span>
                      </h2>
                    </div>
                    <p className="mb-20 text">
                      We have created significant connectors which can be used
                      to integrate the entire operations at a glance. We have
                      following set of categories which has ready to use
                      connectors for popular eCommerce frameworks –
                    </p>
                    <ul>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">Marketplace</h6>
                      </li>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">CRM</h6>
                      </li>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">ERP</h6>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-5 order-0 order-lg-2">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/enterprises-customization.png"
                      className="w-75"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png"
              alt=""
              className="bubble2"
            />
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-4">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/e-commerce-app.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">Customized Software</small>
                      <h2 className="mb-30">
                        <span>Native ECommerce App </span> And{" "}
                        <span>PWA Builder?</span>{" "}
                      </h2>
                    </div>
                    <p>
                      You can get your eCommerce store portable and sync with a
                      mobile app. You can either choose a Native app builder or
                      go with the Progressive Web App paradigm.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt=""
              className="bubble"
            />
          </div>
          <div className="content sec-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="order-2 col-lg-6 order-lg-0">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">Customized Software</small>
                      <h2 className="mb-40">
                        {" "}
                        <span>Inventory </span> And{" "}
                        <span>Retail Management </span> Apps?
                      </h2>
                    </div>
                    <p className="mb-20 text">
                      If you are selling via an offline medium or physical store
                      you need to use a Point of Sale (POS) and if you are
                      selling on an enterprise level you should be preferring
                      Product Information Manager (PIM).Don’t you worry, we have
                      also created PIM connectors which syncs everywhere and a
                      POS extension which manages your physical sales and
                      customers base.
                    </p>
                  </div>
                </div>
                <div className="col-lg-5 order-0 order-lg-2">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/software-2.webp"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png"
              alt=""
              className="bubble2"
            />
          </div>
          <div className="content sec-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <h4>How does open-source integration work?</h4>
                    </Accordion.Header>
                    <Accordion.Body>
                      Open-source software provides the distributed source code
                      to the users or developers so that they can use, modify,
                      distribute with original rights. Source codes can be
                      manipulated to change the behaviour of any application.
                      The open-source codes are always stored in a public
                      repository and accessed by anyone. Anyone can manipulate
                      the code to make their share to the project for
                      maintaining new feature implementation.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      <h4>
                        AIs there any license required for open-source
                        integration?
                      </h4>
                    </Accordion.Header>
                    <Accordion.Body>
                      Open-source integration requires licensing, but its costs
                      are minimal and affordable. Licensing allows the
                      developers to change the system according to the
                      requirement. Some of the licenses are MIT, GNU GPL 2.0,
                      BSD 2.0.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      <h4>
                        What are the top open-source frameworks in the
                        integration process?
                      </h4>
                    </Accordion.Header>
                    <Accordion.Body>
                      The framework has the responsibility of the database, it
                      ensures the proper communication with the frontend and
                      generates the respective backend functionality. Some of
                      the frameworks are Laravel, Django, ASP.Net, Ruby on
                      Rails, etc.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      <h4>
                        What are the examples of some open-source platforms?
                      </h4>
                    </Accordion.Header>
                    <Accordion.Body>
                      There are multiple open-source communities available like
                      GITHUB, Open Source Friday, First Timers Only, Your First
                      PR, CodeTriage, Up For Grabs, Contributor-ninja, 24 Pull
                      Request, etc. These platforms are helping the developers
                      to grow, learn codes and also contribute their effort to
                      the projects.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      <h4>
                        How does software release affect open-source
                        integration?
                      </h4>
                    </Accordion.Header>
                    <Accordion.Body>
                      The software release is one of the biggest challenges for
                      open-source software. Any new release can be unstable or
                      may have issues. The enterprise application must be kind
                      of stable and consistent so that the training cost does
                      not get increased. In the IT industry, it is genuinely
                      important for the developers to use the same version and
                      release to develop or test the modules from a specific
                      project. Otherwise, it can create a mess for sure.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png"
              alt=""
              className="bubble2"
            />
          </div>
        </section>
        <section
          className="bg-white portfolio-projects section-padding style-1"
          id="MixItUpFCF1BF"
        >
          <div className="container">
            <div className="mb-40 text-center section-head style-4">
              <small className="title_small">Customized Software</small>
              <h2 className="mb-20">
                Our <span> Projects </span>
              </h2>
              <p>Here our some projects.</p>
            </div>
            <section className="portfolio style-1">
              <div className="content">
                <div className="row mix-container">
                  {cards.map((card) => (
                    <div className="col-lg-4 mix security consultation">
                      <div className="text-center portfolio-card mb-30 scalmad">
                        <div className="img">
                          <LazyLoadImage src={card.image} alt="" />
                        </div>
                        <div className="info">
                          <h5>
                            <a href={card.link}>{card.name}</a>
                          </h5>
                          <small className="d-block color-main text-uppercase">
                            {card.type1}
                          </small>
                          <div className="text">{card.description}</div>
                          <div className="tags">
                            <a href="#" className="me-1">
                              {card.tags}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Project;
