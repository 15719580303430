import React, { useEffect } from "react";
import { Accordion } from "react-bootstrap";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Project = (props) => {
  const cards = [
    {
      name: "Amaka Industry",
      type1: "Grocery",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/grocery1.png",
      link: "",
      description: "Amaka industry app is full ecommerce mobile app. ",
      tags: "Mobile app development",
    },
    {
      name: "Education App",
      type1: "App Mobility",
      description: "Education App app is full learning mobile app.",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/buss.png",
      link: "",
      tags: "Mobile app",
    },
    {
      name: "Taiton App",
      type1: "App Mobility",
      description: "Taiton App app is commercial harware mobile app.",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/taiton-app.png",
      link: "",
      tags: "commercial mobile app",
    },
  ];

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <Helmet>
        <title>{props.title}</title>
        <h1 name="h1" content={props.h1}> </h1>
        <meta name="description" content="Do you find a mobile app development company for IOS? Aara Technologies offers innovative solutions to bring your app ideas to life. Contact us today!" />
        <meta name="keywords" content={props.keywords} />
        <link rel="canonical" href="https://aaratechnologies.com/mobile-app-development" />
        <meta name="title" content="Mobile App Development Company For Android & IOS"></meta>

      </Helmet>
      <main className="portfolio-page style-1">
        <section className="bg-white portfolio-projects section-padding style-1">
          <div className="container">
            <div className="mb-40 text-center section-head style-4">
              <small className="title_small">Mobile App development</small>
              <h2 className="mb-20">
                <span> Mobile App Development </span> Company{" "}
              </h2>
              <p>We providing best Mobile App development Services</p>
            </div>
          </div>
        </section>

        <section className="about section-padding style-4">
          <div className="content frs-content" id="about" data-scroll-index="2">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-6">
                  <div className="mb-10 img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/at-banner-1.png"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">
                        Best Mobile App Development Company
                      </small>
                      <h4>Mobile App Development</h4>
                      <br />
                    </div>

                    <p>
                      If you wish to scale to great heights in the internet
                      business, contacting an experienced top-notch Indian
                      mobile app development company really helps you in
                      achieving that target. There are numerous reputed and
                      experienced mobile android app development company and
                      service providers in India that provide iOS and Android
                      application development services at attractive prices. So,
                      if you have a plan to develop an iOS or Android app for
                      your respective organizations, it is imperative to contact
                      an efficient Indian app development company in India for
                      the same and without any hesitation we, Aara Technologies
                      counted among the best Mobile App Development companies
                    </p>
                    <h6>
                      Why is Aara Technologies known as the Best Mobile App
                      Development Company in Delhi, Noida, Dubai, USA,
                      Bangalore, or India?
                    </h6>
                    <p>
                      Aara Technologies is a reputed Mobile App Development
                      Company in Noida, Delhi, Dubai, USA, and Bangalore, India
                      which provides cost-effective mobile application
                      development services to its clients across the world. We
                      have won the trust of customers for being counted in
                      developing the best innovative apps for global clients.
                      Android apps are popular amongst all age groups and
                      professionals across the globe. If you are looking to
                      market your products and services globally through mobile
                      devices, Android would be a perfect fit for you.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_lines.png"
              alt=""
              className="lines1"
            />
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt=""
              className="bubble"
            />
          </div>
          <div className="content sec-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <h4>
                        When you are researching about the companies that are
                        offering
                      </h4>
                    </Accordion.Header>
                    <Accordion.Body>
                      <ul>
                        <li className="d-flex align-items-center ">
                          <p>
                            The major reason why mobile apps are preferred by
                            developers around the globe is the availability of
                            hardware support. Various smartphone device
                            manufacturers are providing their respective
                            smartphones with extensive OS platform support and
                            other hardware solutions to increase their market
                            share. Therefore, in case you wish to market your
                            app in India, you need not look out for a developer
                            with extensive technical knowledge. You need to get
                            in touch with an experienced mobile app development
                            company in India that would be able to provide you
                            with experienced and expert android app developers
                            for customizing your app's user interface and other
                            functionalities in a way that fulfills your business
                            objectives and enhances your corporate image.
                          </p>
                        </li>
                        <li className="d-flex align-items-center mb-30 ">
                          <p>
                            Apart from tapping the professional android
                            developers in India, it is equally important to
                            check out the third-party developer community as
                            well. The android ecosystem is very huge and so it
                            can be tough for a developer to make a presence and
                            gain a following. This is where third-party
                            developers come into play. Since a big chunk of
                            people uses android devices worldwide, it would be
                            ideal for an Indian company to collaborate or tie up
                            with international android application development
                            companies. Such a move will help the company provide
                            a more convenient and attractive user experience to
                            its customers and thus improve its brand image. On
                            the other hand, it will also allow a cheaper
                            expenditure on the part of the company which will
                            help it cut down on expenditures incurred on the
                            development of new apps.
                          </p>
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png"
              alt=""
              className="bubble2"
            />
          </div>
          <section className="team section-padding style-6">
            <div className="content1">
              <div className="container">
                <div className="text-center section-head style-4 mb-60">
                  <h2 className="mb-20">
                    <span>Website Design & Development</span>{" "}
                  </h2>
                  <p>
                    Mobile app developers in India are well-versed with the
                    latest technologies used by android programmers and can
                    readily adapt them to the needs of your company. They have
                    the technical expertise and are aware of the various
                    functionalities of each android operating system. In
                    addition to this, they can also help your company to
                    customize its existing mobile apps by adding new features
                    and functionalities along with the application of your
                    choice. Apart from this, it also provides you with
                    cost-effectiveness and greater flexibility and control over
                    the future of your product.
                  </p>
                  <p>
                    The developers can also give you assistance with the android
                    app development costs that may be required for your project.
                    Indian companies offer competitive prices to their overseas
                    clients in comparison to the rates charged by companies in
                    developed countries like the USA and UK. There are certain
                    disadvantages of working with local developers for your
                    android apps, however. For example, Indian software
                    companies are not as capable as their western counterparts
                    when it comes to creating applications for more complex
                    android platforms. Their understanding of the android
                    platform may be less and they might not be able to
                    incorporate all the features required for your project
                    without any glitches.
                  </p>
                  <LazyLoadImage
                    src="https://aaratech.s3.ap-south-1.amazonaws.com/mobileapp.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </section>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-6">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage src="https://aaratech.s3.ap-south-1.amazonaws.com/best-solution.png" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">Best Services</small>
                      <h2 className="mb-30">
                        Our <span> Offered Services</span>{" "}
                      </h2>
                    </div>
                    <p>
                      Being a leading mobile app development company across the
                      world, we comprehensively cover every aspect of mobile app
                      development both on the consumer-facing side and
                      enterprise side. These include the following:{" "}
                    </p>
                    <ul>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">IOS App Development</h6>
                      </li>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">Android App Development</h6>
                      </li>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          Hybrid App Development (Xamarin & React Native)
                        </h6>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt=""
              className="bubble"
            />
          </div>
          <div className="content sec-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="order-2 col-lg-6 order-lg-0">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">App development</small>
                      <h2 className="mb-40">
                        {" "}
                        Our <span> Offered Services</span>
                      </h2>
                    </div>
                    <p>
                      We create mobile applications of any complexity and size:
                      from award-winning B2C mobile applications to heavy,
                      enterprise-grade mobile apps solutions that power
                      mission-critical development processes beyond mere
                      automation:
                    </p>
                    <ul>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          Mobility App Consulting: View Mobility from a New
                          Perspective
                        </h6>
                      </li>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          Mobile Application Development and Maintenance
                        </h6>
                      </li>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          User Experience: Design and Testing Laboratories
                        </h6>
                      </li>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          Automated Mobile Application Testing and Performance
                          Analysis
                        </h6>
                      </li>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">Mobile Application Security</h6>
                      </li>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          Integration with Mobile Advertisement Network
                        </h6>
                      </li>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">Mobile Analytics</h6>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-6 order-0 order-lg-2">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/support.webp"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png"
              alt=""
              className="bubble2"
            />
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/notification.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">Why Choose Us</small>
                      <h2 className="mb-30">
                        Get <span>Benefits</span> and{" "}
                        <span> Advantages Market </span> Goal
                      </h2>
                    </div>
                    <ul>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          Proven track record of successful mobile application
                          project delivery
                        </h6>
                      </li>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          In-house, front-end specialists: Business Analysts,
                          UX/UI Experts & Designers.
                        </h6>
                      </li>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          Shorter development times translate to lower costs for
                          customers.
                        </h6>
                      </li>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          Mobile technology expertise and architectural best
                          practices.
                        </h6>
                      </li>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          Strategic mobility consulting capability.
                        </h6>
                      </li>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          Rigorous quality assurance (QA) testing before
                          “Go-Live” events.
                        </h6>
                      </li>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          User interface design capability.
                        </h6>
                      </li>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          HTML5 Hybrid application development and automated
                          mobile testing platform.
                        </h6>
                      </li>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          Strong industry vertical domain knowledge.
                        </h6>
                      </li>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          Ability to integrate across standard and proprietary
                          back-end systems.
                        </h6>
                      </li>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          A proven ability to deliver award-winning mobile
                          applications.
                        </h6>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt=""
              className="bubble"
            />
          </div>

          <div className="integration pt-30" data-scroll-index="3">
            <div className="integration pt-60" data-scroll-index="3">
              <div className="text-center section-head style-4">
                <small className="title_small">Steps For Done</small>
                <h2 className="mb-20">
                  Our <span> Working Process</span>{" "}
                </h2>
              </div>
              <div className="container">
                <div className="content">
                  <div className="img">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/provisioning.png"
                      alt=""
                      className="mt-30"
                    />
                    <h3 className="text-center">1. Provisioning</h3>
                  </div>
                  <div className="img">
                    <h3 className="text-center">2. Strategy</h3>
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/strategy.png"
                      alt=""
                      className="mt-30"
                    />
                  </div>
                  <div className="img">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/design%26developement.png"
                      alt=""
                      className="mt-30"
                    />
                    <h3 className="text-center">3. Development</h3>
                  </div>
                  <div className="img">
                    <h3 className="text-center">4. Go Live</h3>
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/go-live.png"
                      alt=""
                      className="mt-30"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-white portfolio-projects section-padding style-1">
          <div className="container">
            <div className="mb-40 text-center section-head style-4">
              <small className="title_small">Mobile App development</small>
              <h2 className="mb-20">
                Our <span>Projects</span>
              </h2>
              <p>Here are given some our projects below.</p>
            </div>
            <section className="portfolio style-1">
              <div className="content">
                <div className="row mix-container">
                  {cards.map((card) => (
                    <div className="col-lg-4 mix security consultation">
                      <div className="text-center portfolio-card mb-30 scalmad">
                        <div className="img">
                          <LazyLoadImage
                            src={card.image}
                            alt=""
                            style={{ objectFit: "contain" }}
                          />
                        </div>
                        <div className="info">
                          <h5>
                            <a href={card.link}>{card.name}</a>
                          </h5>
                          <small className="d-block color-main text-uppercase">
                            {card.type1}
                          </small>
                          <div className="text">{card.description}</div>
                          <div className="tags">
                            <a href="#" className="me-1">
                              {card.tags}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Project;
