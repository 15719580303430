import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";
const Project = (props) => {
  const cards = [
    {
      name: "Delivery app Design 1",
      type1: "App design",
      image: "https://aaratech.s3.ap-south-1.amazonaws.com/delivery-01.webp",
      link: "",
      description:
        "Find here the best delivery app solutions to delivery the products or items.",
      tags1: "Delivery app",
    },
    {
      name: "Delivery app Design 2",
      type1: "App design",
      description:
        "Find here the best delivery app solutions to delivery the products or items.",
      image: 'https://aaratech.s3.ap-south-1.amazonaws.com/delivery-02.webp',
      link: "",
      tags1: "Delivery app",
    },
    {
      name: "Delivery app Design 3",
      type1: "App design",
      description:
        "Find here the best delivery app solutions to delivery the products or items.",
      image: 'https://aaratech.s3.ap-south-1.amazonaws.com/operator-1.webp',
      link: "",
      tags1: "Delivery app",
    },
  ];
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <Helmet>
        <title>{props.title}</title>
        <meta name="description" content={props.description} />
        <meta name="keywords" content={props.keywords} />
      </Helmet>
      <main className="portfolio-page style-1">
        <section
          className="portfolio-projects section-padding style-1 bg-white"
          id="MixItUpFCF1BF"
        >
          <div className="container">
            <div className="section-head text-center style-4 mb-40">
              <small className="title_small">Delivery App</small>
              <h2 className="mb-20">
                <span>Top Picks For You</span>{" "}
              </h2>
              <p>We are providing best Delivery App Development service.</p>
            </div>
          </div>
        </section>
        <section className="about section-padding style-4">
          <div className="content frs-content" id="about" data-scroll-index="2">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-10 mb-lg-0">
                    <LazyLoadImage src="https://aaratech.s3.ap-south-1.amazonaws.com/delivery-1.webp" alt="" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">Delivery Software</small>
                      <h2>
                        Essentials <span> delivered</span> to your{" "}
                        <span>doorstep</span>
                      </h2>
                      <br />
                    </div>
                    <p>
                      Aara Technologies developed a Delivery application which
                      is help you deliver all essential things at your home
                      without interactive with any person. Now a days maximum
                      people are using online platform for shopping. In this
                      COVID-19 situation 90% people prefer shopping through the
                      online portal,online website bcz they don’t want to
                      interact with any one.
                    </p>
                    <div className="d-flex align-items-center mt-50">
                      <a
                        href="/Contact"
                        rel="noreferrer"
                        className="btn rounded-pill bg-blue4 text-white fw-bold  me-4"
                      >
                        <small>Get Qoute Now</small>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
          <div className="content sec-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-6 order-2 order-lg-0">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">app development</small>
                      <h2 className="mb-40">
                        <span>Delivery App</span>
                      </h2>
                    </div>
                    <p className="text mb-20">
                      An order is typically made either through the restaurant
                      or grocer’s website or mobile app or also through the food
                      ordering company. A delivery app is helping people to
                      order there necessary goods from one place and they’ll
                      receive their product at home without interacting with
                      people.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 order-0 order-lg-2">
                  <div className="img  mb-lg-0 ">
                    <LazyLoadImage src="https://aaratech.s3.ap-south-1.amazonaws.com/delivery-app.webp" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png' alt="" className="bubble2" />
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage src="https://aaratech.s3.ap-south-1.amazonaws.com/fast-delivery.webp" alt="" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">Delivery App</small>
                      <h2 className="mb-30">
                        To Get <span>Fastest Delivery </span>
                      </h2>
                    </div>
                    <p>
                      If you haven't used this service yet, you need to give it
                      a try. This delivery service simply lets you set orders
                      for weeks, days or months depending on your demand. Or you
                      can just place the order the night before and get it
                      delivered the next day as early. Be it groceries,
                      vegetables, fruits and milk, everything gets delivered. In
                      case you have set up a repeat order for a week or a month
                      and need to discontinue in between, you can opt for the
                      holiday option provided by the portal. You can resume the
                      service as and when required.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
          <div className="content sec-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-6 order-2 order-lg-0">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">Download</small>
                      <h2 className="mb-40">All This From The Convenience Of Your Phone. Download The Aara Delivery Mobile App.
                      </h2>
                      <LazyLoadImage src="https://aaratech.s3.ap-south-1.amazonaws.com/google-play.webp" alt="" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 order-0 order-lg-2">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage src="https://aaratech.s3.ap-south-1.amazonaws.com/d-app.webp" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png' alt="" className="bubble2" />
          </div>
        </section>
        <section
          className="portfolio-projects section-padding style-1 bg-white"
        >
          <div className="container">
            <div className="section-head text-center style-4 mb-40">
              <small className="title_small">Projects</small>
              <h2 className="mb-20">
                <span>Our Projects</span>{" "}
              </h2>
              <p>Here are given some our projects.</p>
            </div>
            <section className="portfolio style-1">
              <div className="content">
                <div className="row mix-container">
                  {cards.map((card) => (
                    <div className="col-lg-4 mix security consultation">
                      <div className="portfolio-card mb-30 scalmad text-center">
                        <div className="img">
                          <LazyLoadImage src={card.image} alt="" style={{ objectFit: 'contain' }} />
                        </div>
                        <div className="info">
                          <h5>
                            <a href={card.link}>{card.name}</a>
                          </h5>
                          <small className="d-block color-main text-uppercase">
                            {card.type1}
                          </small>
                          <div className="text">{card.description}</div>
                          <div className="tags">
                            <a href="#" className="me-1">
                              {card.tags1}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Project;
