import React, { useEffect } from "react";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";
const Project = (props) => {
  const cards = [
    {
      name: "Product Inventory mangement Software",
      type1: "Software development",
      image: 'https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/product-inventory-management.jpg',
      link: "",
      description:
        "Product Inventory management Software is the part of cloud based erp software. ",
      tags: "Cloud based software",
    },
    {
      name: "Product human resource management App",
      type1: "Software DEVELOPMENT",
      description:
        "Product human resource management App is the part of cloud based hrm software.",
      image: 'https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/product-human-resource-management.jpg',
      link: "",
      tags: "Cloud based software",
    },
    {
      name: "Product multi level marketing software App",
      type1: "Software DEVELOPEMNT",
      description:
        "Product multi level marketing App is the part of cloud based mlm software",
      image: 'https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/product-multi-level-marketing.jpg',
      link: "",
      tags: "Cloud based software",
    },
  ];
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <Helmet>
        <title>{props.title}</title>
        <meta name="description" content={props.description} />
        <meta name="keywords" content={props.keywords} />
        <link rel="canonical" href="https://aaratechnologies.com/crm-development" />

      </Helmet>
      <main className="portfolio-page style-1">
        <section
          className="bg-white portfolio-projects section-padding style-1"
          id="MixItUpFCF1BF"
        >
          <div className="container">
            <div className="mb-40 text-center section-head style-4">
              <small className="title_small">Custom CRM Development</small>
              <h2 className="mb-20">
                <span>Custom CRM Development</span>
              </h2>
              <p>
                We are providing best Custom CRM Software Development service.
              </p>
            </div>
          </div>
        </section>

        <section className="team section-padding style-6">
          <div className="content1">
            <div className="container">
              <div className="text-center section-head style-4 mb-60">
                <small className="title_small">Software</small>
                <h2 className="mb-20">
                  Custom <span> CRM Software Development </span>
                </h2>
                <p>
                  Aara Technologies is the best custom CRM development company
                  in Noida, Lucknow, and the entire of India, we are offering
                  open-source as well as cloud-based CRM and CRM applications
                  also for all types of business. As every business is different
                  from another, one CRM software for all businesses does not
                  suit at all so we are designing and developing CRM for all
                  businesses according to the requirements. For this reason, we
                  are also offering custom CRM development solutions as well. We
                  are having a very solid track record in building very complex
                  and highly scalable business applications like Helpdesk
                  Software and SaaS-based apps.
                </p>
                <LazyLoadImage
                  src="https://aaratech.s3.ap-south-1.amazonaws.com/amc.webp"
                  className="imgmad mt-30" alt=""
                />
              </div>
            </div>
          </div>
        </section>

        <section className="about section-padding style-4">
          <div className="content frs-content" id="about" data-scroll-index="2">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-4">
                  <div className="mb-10 img mb-lg-0">
                    <LazyLoadImage src="https://aaratech.s3.ap-south-1.amazonaws.com/Business-Automation-Wheel-1.png" alt="" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">CRM Development</small>
                      <h2>
                        <span>Automates Business </span> Processes
                      </h2>
                      <br />
                    </div>
                    <p>
                      CRM software is an integral part of any sales. CRM (
                      Customer Relationship Management) software is developed
                      for managing all information like inventory, Organizing
                      leads, and taking your customer services to the next step.
                      We have extensive experience in the integration,
                      implementation, and custom CRM development for small,
                      medium, and large enterprises. Our highly customizable and
                      feature-rich CRM software is developed on leading
                      platforms including Salesforce, Odoo development, OroCRM,
                      and more.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
          <div className="content sec-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="order-2 col-lg-6 order-lg-0">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">CRM Development</small>
                      <h2 className="mb-40">
                        <span>Sales CRM</span>
                      </h2>
                    </div>
                    <p className="mb-20 text">
                      Salesforce CRM software suite does not need any
                      introduction it can be understood by the name. They are
                      the market leaders by multiple folds to any of their
                      peers. Aara Technologies is proud to be Salesforce ISV
                      (Independent Software Vendor) Partner. We offer full
                      Salesforce setup and custom integrations for our customers
                      We have a very solid and well-experienced Salesforce team
                      and have been recognized both by the core Salesforce team
                      as well as Salesforce community.
                    </p>
                  </div>
                </div>
                <div className="col-lg-5 order-0 order-lg-2">
                  <div className="img mb-lg-0 ">
                    <LazyLoadImage src="https://aaratech.s3.ap-south-1.amazonaws.com/cloud.webp" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png' alt="" className="bubble2" />
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage src="https://aaratech.s3.ap-south-1.amazonaws.com/magento-ui-theme.webp" alt="" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">CRM Development</small>
                      <h2 className="mb-30">
                        <span>CRM </span> Built-Into <span>ECommerce </span>
                      </h2>
                    </div>
                    <p>
                      This is developed for online store owners, who are not
                      looking for any third-party CRM Software integrations and
                      want to manage customer data within your platform they are
                      worried about security. You can opt for our specialized
                      CRM solutions that are built into eCommerce frameworks.
                      Enabling online store owners to manage leads, track
                      opportunities, manage their Inventory, create users, and
                      manage customer interactions from the back office.
                    </p>
                    <h5 className="fw bold">This comes with several advantages as well:</h5><br />
                    <ul>
                      <li className="d-flex align-items-center">
                        <ArrowRightIcon />
                        <p>Mobility App Consulting: View Mobility from a New Perspective</p>
                      </li>
                      <li className="d-flex align-items-center">
                        <ArrowRightIcon />
                        <p>Mobile Application Development and Maintenance</p>
                      </li>
                      <li className="d-flex align-items-center">
                        <ArrowRightIcon />
                        <p>
                          User Experience: Design and Testing Laboratories
                        </p>
                      </li>
                      <li className="d-flex align-items-center">
                        <ArrowRightIcon />
                        <p>
                          Automated Mobile Application Testing and Performance Analysis
                        </p>
                      </li>
                      <li className="d-flex align-items-center ">
                        <ArrowRightIcon />
                        <p>
                          Mobile Application Security
                        </p>
                      </li>
                      <li className="d-flex align-items-center">
                        <ArrowRightIcon />
                        <p>
                          Integration with Mobile Advertisement Network
                        </p>
                      </li>
                      <li className="d-flex align-items-center ">
                        <ArrowRightIcon />
                        <p>
                          Mobile Analytics
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
        </section>
        <section
          className="bg-white portfolio-projects section-padding style-1"
        >
          <div className="container">
            <div className="mb-40 text-center section-head style-4">
              <small className="title_small">Custom CRM Development</small>
              <h2 className="mb-20">
                Our  <span>Projects</span>
              </h2>
              <p>
                Here some projects are given below.
              </p>
            </div>
            <section className="portfolio style-1">
              <div className="content">
                <div className="row mix-container">
                  {cards.map((card) => (
                    <div className="col-lg-4 mix security consultation">
                      <div className="text-center portfolio-card mb-30 scalmad">
                        <div className="img">
                          <LazyLoadImage src={card.image} alt="" style={{ objectFit: "contain" }} />
                        </div>
                        <div className="info">
                          <h5>
                            <a href={card.link}>{card.name}</a>
                          </h5>
                          <small className="d-block color-main text-uppercase">
                            {card.type1}
                          </small>
                          <div className="text">{card.description}</div>
                          <div className="tags">
                            <a href="#" className="me-1">
                              {card.tags}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Project;
